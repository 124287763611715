import { MagnifyingGlassIcon, PlusCircleIcon } from '@heroicons/react/24/outline'
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'

import { ESpacer } from 'assets/theme/variables/spacers'
import { DEFAULT_DEBOUNCE_DELAY, DEFAULT_LIMIT } from 'common/constants/services'
import { useBooleanFlag } from 'common/hooks/boolean'
import { useMutationNotifications } from 'common/hooks/notifications'
import { BlockTitle, Button, Col, Content, Input, Row, Space, Spin, confirmDelete } from 'components'
// import { StorageWidget } from 'features/data/ui/StorageWidget'
import { useMainLayoutContext } from 'features/layouts/blocks/MainLayout/hooks'
import { ProjectEntityModal } from 'features/projects/blocks/ProjectEntityModal'
import { ProjectsList } from 'features/projects/blocks/ProjectsList'
import { ProjectsEmpty } from 'features/projects/ui/ProjectsEmpty'
import { projectsApi } from 'services/projects'
import { IProject } from 'services/projects/types'

import styles from './styles.module.scss'

const { useGetProjectsQuery, useDeleteProjectMutation } = projectsApi

export default function Projects() {
	const { t } = useTranslation('projects')
	useMainLayoutContext({
		showMenu: false,
	})

	const [editModalOpen, setEditModalOpen] = useBooleanFlag()
	const [projectToEdit, setProjectToEdit] = useState<IProject>()
	const [searchValue, setSearch] = useState('')
	const [search] = useDebounce(searchValue, DEFAULT_DEBOUNCE_DELAY)

	const [deleteProject] = useMutationNotifications(useDeleteProjectMutation, {
		errorText: t('An error occurred while deleting a project'),
		successText: t('Project deleted successfully'),
	})

	const { data, isLoading, isFetching } = useGetProjectsQuery({
		search: search || undefined,
		skip: 0,
		limit: DEFAULT_LIMIT,
	})

	const loading = useMemo(() => isLoading || isFetching, [isLoading, isFetching])

	const onOpenEditProjectModalCallback = useCallback((item?: IProject) => {
		setEditModalOpen(true)
		if (item) {
			setProjectToEdit(item)
		}
	}, [setEditModalOpen])

	const onCloseEditProjectModalCallback = useCallback(() => {
		setEditModalOpen(false)
		setProjectToEdit(undefined)
	}, [setEditModalOpen])

	const onSearchCallback = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		setSearch(e.target.value)
	}, [])

	const onCreateProjectCallback = useCallback(() => {
		onOpenEditProjectModalCallback()
	}, [onOpenEditProjectModalCallback])

	const onDeleteProjectCallback = useCallback((item: IProject) => {
		confirmDelete({
			title: t('Are you sure you want to delete the project?', { name: item.name }),
			onOk: async () => {
				await deleteProject({ id: item.id })
			},
		})
	}, [
		t,
		deleteProject,
	])

	return (
		<Content
			isFullWidth
			className={styles.root}
		>
			<Row className={styles.instruments}>
				<Col flex={1}>
					<BlockTitle
						indent
						title={t('My projects')}
					/>
					<Space size={ESpacer.SPACER7}>
						<Button
							onClick={onCreateProjectCallback}
							type='primary'
							icon={<PlusCircleIcon width={20} />}
						>
							{t('Create project')}
						</Button>
						<Input
							value={searchValue}
							onChange={onSearchCallback}
							prefix={<MagnifyingGlassIcon width={20} />}
							placeholder={t('Find project')}
						/>
					</Space>
				</Col>
				{/* TODO Пока скрываем */}
				{/*<Col>*/}
				{/*	<StorageWidget value={60} limit={100} />*/}
				{/*</Col>*/}
			</Row>
			<div className={styles.content}>
				<Spin
					isFullParent
					spinning={loading}
				>
					{!data?.length && !loading
						? <ProjectsEmpty onCreate={onCreateProjectCallback} />
						: (
							<ProjectsList
								loading={loading}
								items={data}
								onEdit={onOpenEditProjectModalCallback}
								onDelete={onDeleteProjectCallback}
							/>
						)
					}
				</Spin>
			</div>
			<ProjectEntityModal
				open={editModalOpen}
				item={projectToEdit}
				onCancel={onCloseEditProjectModalCallback}
			/>
		</Content>
	)
}
