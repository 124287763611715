import React, { useMemo } from 'react'
import { Outlet, useParams } from 'react-router-dom'

import { useMainLayoutContext } from 'features/layouts/blocks/MainLayout/hooks'
import { IProjectPageContext } from 'features/projects/store/types'

export default function ProjectPage() {
	useMainLayoutContext()

	const { id } = useParams()

	const context = useMemo<IProjectPageContext>(() => ({
		projectId: id,
	}), [id])

	return (
		<Outlet context={context} />
	)
}
