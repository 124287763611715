import React, { useMemo } from 'react'

import { Text } from 'components'

import styles from './styles.module.scss'
import { IInfoDescriptionFieldValueProps } from './types'

export function InfoDescriptionFieldValue(props: IInfoDescriptionFieldValueProps) {
	const { value, formatViewValue } = props

	const viewValue = useMemo(() => {
		if (formatViewValue) {
			return formatViewValue(value)
		}

		return value ?? '-'
	}, [value, formatViewValue])

	return (
		<Text className={styles.fieldValue}>
			{viewValue}
		</Text>
	)
}
