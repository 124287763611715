import classNames from 'classnames'
import React, { useContext } from 'react'

import { Space } from 'components'
import { BaseLayerSwitcher } from 'features/map/blocks/Instruments/BaseLayerSwitcher'
import { InfoBox } from 'features/map/blocks/Instruments/InfoBox'
import { LayerSwitcher } from 'features/map/blocks/Instruments/LayerSwitcher'
import { MapModePicker } from 'features/map/blocks/Instruments/MapModePicker'
import { Measure } from 'features/map/blocks/Instruments/Measure'
import { PositionDisplay } from 'features/map/blocks/Instruments/PositionDisplay'
import { ZoomControl } from 'features/map/blocks/Instruments/ZoomControl'
import { MapContext } from 'features/map/context'

import styles from './styles.module.scss'

export function Instruments() {

	const { map } = useContext(MapContext)

	return (
		<div className={styles.root}>
			<InfoBox />
			<div className={
				classNames(styles.instrumentPanel, styles.top, styles.left)
			}>
				{
					map && (
						<LayerSwitcher />
					)
				}
			</div>
			<Space
				direction='horizontal'
				className={
					classNames(styles.instrumentPanel, styles.top, styles.right)
				}
			>
				<ZoomControl />
				<MapModePicker />
				<Measure />
			</Space>
			<Space
				direction='horizontal'
				className={
					classNames(styles.instrumentPanel, styles.bottom, styles.right)
				}
			>
				<PositionDisplay />
				<BaseLayerSwitcher />
			</Space>
			<div className={
				classNames(styles.instrumentPanel, styles.bottom, styles.left)
			}></div>
		</div>
	)
}
