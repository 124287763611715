import React, { JSX } from 'react'

import { Paper, Space } from 'components/index'
import {
	ITableContainerProps
} from 'components/Table/types'
import { TableActionPanel } from 'components/Table/ui/TableActionPanel'

export function TableContainer<FilterContent>(props: ITableContainerProps<FilterContent>): JSX.Element {
	const {
		children,
		...params
	} = props
	return (
		<Space isFullWidth direction='vertical'>
			<TableActionPanel<FilterContent> {...params} />
			<Paper>
				{children}
			</Paper>
		</Space>
	)
}
