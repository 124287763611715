import { CloseOutlined } from '@ant-design/icons'
import React, { useCallback, useEffect, useMemo } from 'react'

import { ESpacer } from 'assets/theme/variables/spacers'
import { useBooleanFlag } from 'common/hooks/boolean'
import { Button, Col, Popover, Row } from 'components/index'
import { IPopoverDialogProps } from 'components/PopoverDialog/types'


import styles from './styles.module.scss'

export function PopoverDialog(props: IPopoverDialogProps) {
	const { title,  trigger = 'click', onOpenChange, ...rest  } = props
	const [open, setOpen] = useBooleanFlag(false)

	const onCloseCallback = useCallback(() => {
		setOpen(false)
	}, [setOpen])

	const titleContent = useMemo(() => (
		<Row justify='space-between' className={styles.title} gutter={ESpacer.SPACER3}>
			<Col>
				{title}
			</Col>
			<Col className={styles.closeButton}>
				<Button
					size='small'
					type='text'
					icon={<CloseOutlined />}
					onClick={onCloseCallback}
				/>
			</Col>
		</Row>
	), [onCloseCallback, title])

	useEffect(() => {
		onOpenChange?.(open)
	}, [onOpenChange, open])

	return (
		<Popover open={open} onOpenChange={setOpen} title={titleContent} trigger={trigger} {...rest} />
	)
}
