import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { normalizeObject } from 'common/utils/normalization'
import { EFormTypes, IFormScheme } from 'components/FormGenerator/types'
import { IClassificationStepFormValues } from 'features/handlers/blocks/HandlerClassificationStep/types'
import { HandlerStepBase } from 'features/handlers/blocks/HandlerStepBase'
import { HANDLER_STEPS_ORDER } from 'features/handlers/constants'
import { useHandlerContext } from 'features/handlers/hooks'
import { EHandlerOtClassificationModels, EHandlerSteps } from 'services/handlers/types'
import { EHandlerPointsClasses } from 'services/handlers/types'

// import { AOI } from './AOI'
import { CLASSIFICATION_STEP_INITIAL_VALUES } from './constants'
import { OTClassification } from './OTClassification'
import { PointsClass } from './PointsClass'

const MOCK_VALUE = {
	pointsClass: [
		{ class: EHandlerPointsClasses.Created, value: 10,  locked: false },
		{ class: EHandlerPointsClasses.NotClassified, value: 20,  locked: false },
		{ class: EHandlerPointsClasses.Ground, value: 5,  locked: false },
		{ class: EHandlerPointsClasses.TallVegetation, value: 12,  locked: false },
		{ class: EHandlerPointsClasses.Building, value: 0,  locked: false },
		{ class: EHandlerPointsClasses.LowerPoint, value: 10,  locked: false },
		{ class: EHandlerPointsClasses.HigherPoint, value: 33,  locked: false },
	],
}
const FORM_NAME = 'handlerClassificationStep'

export function HandlerClassificationStep() {
	const { t } = useTranslation('handlers')
	const { onFinish, handlerData } = useHandlerContext()

	const values = useMemo(() => ({
		...MOCK_VALUE,
		...CLASSIFICATION_STEP_INITIAL_VALUES,
		...handlerData?.config?.classification,
	}), [handlerData])

	const scheme = useMemo<IFormScheme[]>(() => [
		{
			name: 'pointsClass',
			required: true,
			type: EFormTypes.Custom,
			component: <PointsClass />,
		},
		// TODO Пока убираем блок с AOI
		// {
		// 	name: 'aoi',
		// 	required: true,
		// 	type: EFormTypes.Custom,
		// 	component: <AOI />,
		// },
		{
			name: '',
			type: EFormTypes.Custom,
			component: <OTClassification />,
		}
	], [])

	const onFinishCallback = useCallback((values: IClassificationStepFormValues) => {
		// const blockedClasses = values.pointsClass.reduce<number[]>((acc, item) => {
		// 	if (item.locked) {
		// 		return [...acc, item.class]
		// 	}
		//
		// 	return acc
		// }, [])

		onFinish?.({
			classification: normalizeObject({
				classificationModel: EHandlerOtClassificationModels.MorphologicalFilter,
				...CLASSIFICATION_STEP_INITIAL_VALUES,
				...values,
			}),
		})
	}, [onFinish])

	return (
		<HandlerStepBase<IClassificationStepFormValues>
			stepTitle={t(`stepsTitles.${EHandlerSteps.Classification}`)}
			hint={t(`hints.${EHandlerSteps.Classification}`)}
			step={HANDLER_STEPS_ORDER.indexOf(EHandlerSteps.Classification) + 1}
			scheme={scheme}
			formName={FORM_NAME}
			onFinish={onFinishCallback}
			values={values}
		/>
	)
}
