import classNames from 'classnames'
import React, { ReactNode } from 'react'

import { AntdInputNumber } from 'components/index'

import styles from './styles.module.scss'
import { IInputNumberProps } from './types'

export function InputNumber(props: IInputNumberProps) {
	const { isFullWidth, className, children, size = 'small', placeholder = '0', ...rest } = props

	return (
		<AntdInputNumber
			{...rest}
			placeholder={placeholder}
			size={size}
			className={classNames(className, {
				[styles.fullWidth]: isFullWidth,
			})}
		>
			{children as ReactNode}
		</AntdInputNumber>
	)
}
