import React from 'react'
import { Route, Routes } from 'react-router-dom'
import MapWidget from 'widgets/Map'

import { EProjectResources, ROUTES } from 'app/router/constants'
import AI from 'pages/AI'
import Design from 'pages/Design'
import Handler from 'pages/Handler'
import NotFound from 'pages/NotFound'
import ProjectPage from 'pages/Project'
import Projects from 'pages/Projects'
import Storage from 'pages/Storage'

export const AppRoutes = () => {
	return (
		<>
			<Routes>
				<Route
					element={<Projects />}
					path={ROUTES.Root}
				/>
				<Route
					element={<Projects />}
					path={ROUTES.Projects}
				/>
				<Route
					element={<ProjectPage />}
					path={ROUTES.Project}
				>
					<Route path={EProjectResources.Map} element={<MapWidget />} />
					<Route path={EProjectResources.Handlers} element={<AI />} />
				</Route>
				<Route
					element={<Storage />}
					path={ROUTES.Storage}
				/>
				<Route
					element={<Handler />}
					path={ROUTES.HandlerPage}
				/>
				<Route
					element={<Design />}
					path='design'
				/>
				<Route
					element={<NotFound />}
					path='/*'
				/>
			</Routes>
		</>
	)
}
