import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAsync } from 'react-use'

import { ESpacer } from 'assets/theme/variables/spacers'
import { useBooleanFlag } from 'common/hooks/boolean'
import { normalizeNumber } from 'common/utils/normalization'
import { Button, Col, Popover, Row, Spin, Text } from 'components'
import { HandlerStepBlock } from 'features/handlers/blocks/HandlerStepBlock'

import { SRS_PHOTO_PARAMETERS } from './constants'
import styles from './styles.module.scss'
import { INormalizedLasHeaders, ISrsProps } from './types'

export function Srs(props: ISrsProps) {
	const { getHeaders } = props
	const { t } = useTranslation(['labels', 'handlers'], { nsMode: 'fallback' })
	const [opened, setOpened] = useBooleanFlag()

	const headersState = useAsync<() => Promise<INormalizedLasHeaders>>(async () => {
		if (opened) {
			const data = await getHeaders()

			return {
				...data,
				area: data?.area ? normalizeNumber(data?.area) : undefined,
				density: data?.density ? normalizeNumber(data?.density) : undefined,
				heightLimits: data?.heightLimits?.join('-')
			}
		}

		return {} as INormalizedLasHeaders
	}, [opened, getHeaders])

	return (
		<HandlerStepBlock>
			<Popover
				content={(
					<div className={styles.parametersContent}>
						<Spin spinning={headersState.loading}>
							{SRS_PHOTO_PARAMETERS
								.filter((param) => Boolean(headersState?.value?.[param]))
								.map((param) => (
									<Row key={param} gutter={ESpacer.SPACER3} wrap={false}>
										<Col xs={12}>
											<Text bold>{t(`srsPhotoParameters.${param}.label`)}</Text>
										</Col>
										<Col xs={12}>
											<Text typographyType='secondary'>{t(`srsPhotoParameters.${param}.value`, { value: headersState?.value?.[param] })}</Text>
										</Col>
									</Row>
								))}
						</Spin>
					</div>
				)}
				trigger='click'
				arrow={false}
				placement='bottomRight'
				onOpenChange={setOpened}
			>
				<Button
					disabled={false}
					icon={opened ? <ChevronDownIcon width={20} /> : <ChevronRightIcon width={20} />}
					type='secondary'
				>
					{t('Shooting material parameters')}
				</Button>
			</Popover>
		</HandlerStepBlock>
	)
}
