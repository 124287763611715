import { XMarkIcon } from '@heroicons/react/24/outline'
import { DocumentIcon, FolderIcon } from '@heroicons/react/24/solid'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ESpacer } from 'assets/theme/variables/spacers'
import { Button, Col, ProgressBar, Row, Text } from 'components'
import styles from 'features/data/blocks/UploadListGlobal/styles.module.scss'
import { EBucketConnectorUploadFileStatus, TBucketFile } from 'features/data/libs/BucketConnector/types'

import { IFileListProps } from './types'

export function FileList(props: IFileListProps) {
	const { files, onRemove } = props
	const { t } = useTranslation('storage')

	return (
		<div>
			{files?.map((file) => (
				<Row
					wrap={false}
					key={file.key ?? file.name}
					align='middle'
					gutter={ESpacer.SPACER3}
					className={styles.item}
				>
					<Col>
						{
							(file as TBucketFile)?.isFile
								? <DocumentIcon className={styles.fileIcon} width={32} />
								: <FolderIcon className={styles.fileIcon} width={32} />
						}
					</Col>
					<Col flex={1}>
						<Text
							type='body14'
							ellipsis
							title={file.key ?? file.name}
						>
							{file.name}
						</Text>
					</Col>
					{file.status && (
						<Col>
							{[EBucketConnectorUploadFileStatus.Uploading, EBucketConnectorUploadFileStatus.Pending].includes(file.status) && typeof file.progress === 'number'
								? (
									<ProgressBar progress={file.progress} width={130} />
								) : (
									<Text
										className={styles.status}
										type='body12'
										typographyType={file.status === EBucketConnectorUploadFileStatus.Success ? 'success' : 'danger'}
									>
										{t(`uploadStatus.${file.status}`)}
									</Text>
								)}
						</Col>
					)}
					<Col>
						{file?.closeUpload && file?.status === EBucketConnectorUploadFileStatus.Uploading && (
							<Button
								className={styles.cancelButton}
								title={t('Cancel upload')}
								type='icon'
								onClick={file.closeUpload}
							>
								<XMarkIcon width={14} />
							</Button>
						)}
						{onRemove && file.key && (
							<Col>
								<Button
									className={styles.cancelButton}
									title={t('Cancel upload')}
									type='icon'
									onClick={() => onRemove(file.key as string)}
								>
									<XMarkIcon width={14} />
								</Button>
							</Col>
						)}
					</Col>
				</Row>
			))}
		</div>
	)
}
