import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Spin, message } from 'components'
import { Map } from 'features/map/blocks/Map'
import { mapsApi } from 'services/maps'

import styles from './styles.module.scss'

const { useGetMapQuery } = mapsApi

export default function MapWidget() {
	const { id } = useParams()
	const mapQuery = useGetMapQuery(Number(id))

	useEffect(() => {
		if (mapQuery.isError) {
			message.error('Не удалось получить данные по карте')
		}
	}, [mapQuery.isError])


	if (mapQuery.isLoading) {
		return <Spin size='large' className={styles.spin} spinning />
	}


	return (
		<Map map={mapQuery.data} />
	)
}
