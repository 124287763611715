import React from 'react'

import { FileListDrawerGlobal } from 'features/data/blocks/FileListDrawerGlobal'
import { UploadListGlobal } from 'features/data/blocks/UploadListGlobal'

export function GlobalModals() {
	return (
		<>
			<FileListDrawerGlobal />
			<UploadListGlobal />
		</>
	)
}
