import { CallbackProperty, Cartesian3, Entity } from 'cesium'

import { i18n } from 'app/localization'
import { LABEL_PROPS } from 'features/map/constants'
import { EFeatureTypes } from 'features/map/types'
import { getEntityCoordinatesByGeometry, getFeatureByType, getPositionsByGeometry } from 'features/map/utils'

export const getLeftRightPositionsByGeometry = (geometry: Entity, type: EFeatureTypes): [Cartesian3, Cartesian3, Cartesian3, Cartesian3] => {
	const positions = getPositionsByGeometry(geometry, type)
	const first = positions[0]
	const temp = positions[positions.length - 1]
	const right = positions[positions.length - 2]
	const left = positions[positions.length - 3]
	return [left, right, temp, first]
}

export const getLeftRightLabelData = (left: Cartesian3, right: Cartesian3): [position: Cartesian3, size: number] => {
	const coordinates = new Cartesian3()
	const distance = Cartesian3.distance(left, right)
	Cartesian3.midpoint(left, right, coordinates)
	return [
		new Cartesian3(coordinates.x, coordinates.y,coordinates.z),
		distance
	]
}

export const formattingLabel = (size: number) => size > 1000 ? i18n.t('distance.kilometers', { distance: (size / 1000).toFixed(2) }) : i18n.t('distance.meters', { distance: size.toFixed(2) })

export const getLabelByPosition = (position: Cartesian3, label: string) => ({
	label: {
		...LABEL_PROPS,
		text: label,
	},
	position: position,
})

export const copyEntity = (entity: Entity, type: EFeatureTypes): object => {
	const positions = getEntityCoordinatesByGeometry(entity, type)
	return getFeatureByType(type, positions as unknown as CallbackProperty)
}
