import React, { useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { ROUTES } from 'app/router/constants'
import { ESpacer } from 'assets/theme/variables/spacers'
import { BackToButton, Col, Content, Row, Space, Text } from 'components'
import { StorageBreadcrumbs } from 'features/data/blocks/StorageBreadcrumbs'
import { StorageFileList } from 'features/data/blocks/StorageFileList'
import { StorageUploadButton } from 'features/data/blocks/StorageUploadButton'
import { useDataFileList } from 'features/data/store/hooks'
// import { StorageWidget } from 'features/data/ui/StorageWidget'
import { useMainLayoutContext } from 'features/layouts/blocks/MainLayout/hooks'

import styles from './styles.module.scss'

export default function Storage() {
	const { t } = useTranslation('storage')
	const { setHeaderContent } = useMainLayoutContext({
		showMenu: false,
	})

	useLayoutEffect(() => {
		setHeaderContent(<Text type='header3'>{t('Storage')}</Text>)
	}, [setHeaderContent, t])

	const { fileList, path, setPath, addToPath } = useDataFileList()

	return (
		<Content isFullWidth isFullHeight>
			<Space
				isFullWidth
				direction='vertical'
				size='large'
				className={styles.space}
			>
				<Row>
					<Col flex={1}>
						<Space isFullWidth direction='vertical'>
							<BackToButton pageName={t('Back to projects')} to={ROUTES.Projects} />
							<Space
								className={styles.actions}
								isFullWidth
								size={ESpacer.SPACER11}
								align='center'
								wrap={false}
							>
								<StorageUploadButton path={path} />
								<StorageBreadcrumbs
									fullPath={path}
									onNewPath={setPath}
								/>
							</Space>
						</Space>
					</Col>
					<Col>
						{/*<StorageWidget value={60} limit={100} />*/}
					</Col>
				</Row>
				<StorageFileList
					isEdit
					fileList={fileList}
					path={path}
					onOpenDirectory={addToPath}
				/>
			</Space>
		</Content>
	)
}
