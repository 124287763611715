export const safePush = <T>(array: T[] | undefined, ...items: T[]) => {
	let values = array

	if (Array.isArray(values)) {
		values.push(...items)
	} else {
		values = [...items]
	}

	return values
}

export const getUniqueValues = <T>(array: T[], key: keyof T) => array.reduce<T[]>((reducer, item) => {
	const value = item[key]

	if (reducer.every((reducerItem) => reducerItem[key] !== value)) {
		reducer.push(item)
	}

	return reducer
}, [])

export const transformToArrayIfNeedIt = <T>(value: T | T[]) => (Array.isArray(value) ? value : [value])
