import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { formattingLabels2Options } from 'common/utils/formatting'
import { FormGenerator } from 'components'
import { EFormTypes, IFormScheme } from 'components/FormGenerator/types'
import { HandlerStepBlock } from 'features/handlers/blocks/HandlerStepBlock'
import {
	HANDLER_CLASSIFICATION_DEFAULT_PARAMS_LABELS,
	HANDLER_OT_CLASSIFICATION_MODELS_LABELS,
	HANDLER_SETTING_MODES_LABELS
} from 'features/handlers/constants'
import {
	EHandlerOtClassificationModels,
	EHandlerSettingModes,
	IHandleClassification
} from 'services/handlers/types'

import styles from './styles.module.scss'

const shouldShowConfig = (values: IHandleClassification) => values.settingsMode === EHandlerSettingModes.Manual
const shouldShowParams = (values: IHandleClassification) => shouldShowConfig(values)
	&& Boolean(values.classificationModel)
	&& values.classificationModel !== EHandlerOtClassificationModels.NeuralNetworkModel
const shouldShowFabricSimulation = (values: IHandleClassification) => shouldShowParams(values)
	&& (values as IHandleClassification).classificationModel === EHandlerOtClassificationModels.FabricSimulation
const shouldShowMorphologicalFilter = (values: IHandleClassification) => shouldShowParams(values)
	&& values.classificationModel === EHandlerOtClassificationModels.MorphologicalFilter
const shouldShowIfNotNeuralNetworkModel = (values: IHandleClassification) => shouldShowFabricSimulation(values) || shouldShowMorphologicalFilter(values)
const shouldShowDependencies = ['settingsMode', 'classificationModel']

export function OTClassification() {
	const { t } = useTranslation('handlers')

	const scheme = useMemo<IFormScheme[]>(() => [
		{
			name: 'settingsMode',
			type: EFormTypes.Radio,
			required: true,
			inputProps: {
				className: styles.otClassificationRadio,
				options: formattingLabels2Options(HANDLER_SETTING_MODES_LABELS),
			}
		},
		{
			name: 'classificationModel',
			type: EFormTypes.Select,
			label: t('OT classification model'),
			required: true,
			shouldShow: shouldShowConfig,
			dependencies: shouldShowDependencies,
			inputProps: {
				size: 'small',
				placeholder: t('Choose OT classification model'),
				options: formattingLabels2Options(HANDLER_OT_CLASSIFICATION_MODELS_LABELS),
			},
		},
		{
			name: 'defaultParams',
			type: EFormTypes.Select,
			label: t('A set of default classification options'),
			required: true,
			shouldShow: shouldShowIfNotNeuralNetworkModel,
			dependencies: shouldShowDependencies,
			inputProps: {
				size: 'small',
				placeholder: t('Select a set of classification options'),
				options: formattingLabels2Options(HANDLER_CLASSIFICATION_DEFAULT_PARAMS_LABELS),
			},
		},
		{
			name: 'spatialResolution',
			isVertical: true,
			type: EFormTypes.Number,
			label: <span>{t('Spatial resolution')}</span>,
			helpText: t('hints.spatialResolution'),
			required: true,
			shouldShow: shouldShowFabricSimulation,
			dependencies: shouldShowDependencies,
			inputProps: {
				size: 'small',
			},
		},
		{
			name: 'iterationNum',
			isVertical: true,
			type: EFormTypes.Number,
			label: <span>{t('Number of iterations')}</span>,
			helpText: t('hints.iterationNum'),
			required: true,
			shouldShow: shouldShowFabricSimulation,
			dependencies: shouldShowDependencies,
			inputProps: {
				size: 'small',
			},
		},
		{
			name: 'window',
			isVertical: true,
			type: EFormTypes.Number,
			label: <span>{t('Window size')}</span>,
			helpText: t('hints.window'),
			required: true,
			shouldShow: shouldShowMorphologicalFilter,
			dependencies: shouldShowDependencies,
			inputProps: {
				size: 'small',
			},
		},
		{
			name: 'slope',
			isVertical: true,
			type: EFormTypes.Number,
			label: <span>{t('Threshold slope')}</span>,
			helpText: t('hints.slope'),
			required: true,
			shouldShow: shouldShowMorphologicalFilter,
			dependencies: shouldShowDependencies,
			inputProps: {
				size: 'small',
			},
		},
		{
			name: 'limitHeight',
			isVertical: true,
			type: EFormTypes.Number,
			label: <span>{t('Threshold height')}</span>,
			helpText: t('hints.limitHeight'),
			required: true,
			shouldShow: shouldShowIfNotNeuralNetworkModel,
			dependencies: shouldShowDependencies,
			inputProps: {
				size: 'small',
			},
		}
	], [t])

	return (
		<HandlerStepBlock title={t('OT classification')}>
			<FormGenerator scheme={scheme} />
		</HandlerStepBlock>
	)
}
