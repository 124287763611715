import { WebMapServiceImageryProvider } from 'cesium'
import React, { useContext } from 'react'
import {
	GeoJsonDataSource,
	ImageryLayer,
} from 'resium'

import { ILayerProps } from 'features/map/blocks/Layer/types'
import { MapContext } from 'features/map/context'
import { useLayerState } from 'features/map/store/hooks'
import { WFSLayer } from 'features/map/ui/WFSLayer'
import { layerToStyle } from 'features/map/utils'
import { ELayerTypes } from 'services/maps/types'
export function Layer({ layer, isHidden }: ILayerProps) {
	const { mapId } = useContext(MapContext)
	const { type, id, layers } = layer
	const { visible } = useLayerState({ mapId: mapId!, layerId: id })

	switch (type) {
	case ELayerTypes.WMS: {
		const provider = new WebMapServiceImageryProvider({
			parameters: {
				...layer.params,
				transparent: true,
				format: 'image/png',
			},
			// @ts-ignore
			layers: layer.params.layers,
			url: layer.url,
		})
		return (
			<ImageryLayer
				show={visible && !isHidden}
				imageryProvider={provider}
			/>
		)
	}
	case ELayerTypes.Group: {
		return (
			<>
				{layers?.map((item) => (
					<Layer key={item.id} layer={item} isHidden={isHidden} />
				))}
			</>
		)
	}
	case ELayerTypes.GeoJSON: {
		return (
		// todo доделать парсер стилей для geojson
			<GeoJsonDataSource
				{...layerToStyle(layer)}
				data={layer.url}
			/>
		)
	}
	case ELayerTypes.WFS: {
		return <WFSLayer layer={layer} />
	}
	default: {
		console.log('не обработанный тип', type, layer)
		return null
	}
	}
}
