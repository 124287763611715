import {
	ArrowDownTrayIcon,
	ArrowPathIcon,
	ChevronDoubleRightIcon,
	PencilSquareIcon,
	PlayIcon,
	TrashIcon,
} from '@heroicons/react/24/outline'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ESpacer } from 'assets/theme/variables/spacers'
import {
	Button,
	// Icon,
	Row,
	Space
} from 'components'
import { EHandlerActionTypes } from 'services/handlers/types'

import styles from './styles.module.scss'
import { IHandlerStepFooterProps } from './types'

export function HandlerStepFooters(props: IHandlerStepFooterProps) {
	const { t } = useTranslation('handlers')
	const { type, formName, actions, btnSize, btnType } = props

	const content = useMemo(() => {
		switch (type) {
		case EHandlerActionTypes.Create:
		case EHandlerActionTypes.Change:
		case EHandlerActionTypes.NextStepChange:
			return (
				<Space
					isFullWidth
					size={ESpacer.SPACER6}
					className={styles.fullWidthSpace}
				>
					<Button
						block
						htmlType='reset'
						form={formName}
						size={btnSize}
						type={btnType}
						onClick={actions?.onCancel}
					>
						{t('Cancel')}
					</Button>
					<Button
						block
						form={formName}
						htmlType='submit'
						size={btnSize}
						type={btnType ?? 'primary'}
					>
						{t(type === 'create' ? 'Create' : 'Save')}
					</Button>
				</Space>
			)

		case EHandlerActionTypes.NextStep:
		case EHandlerActionTypes.Done:
			return (
				<Space size={ESpacer.SPACER6}>
					<Button
						danger
						icon={<TrashIcon width={24} />}
						title={t('Remove')}
						onClick={actions?.onDelete}
						size={btnSize}
						type={btnType}
					/>
					<Button
						icon={<ArrowDownTrayIcon width={24} />}
						title={t('Download')}
						onClick={actions?.onDownload}
						size={btnSize}
						type={btnType}
					/>
					{/*<Button*/}
					{/*	icon={<Icon size={24} icon='send-to-map-2' />}*/}
					{/*	title={t('Send to map')}*/}
					{/*	onClick={actions?.onSendToMap}*/}
					{/*	size={btnSize}*/}
					{/*	type={btnType}*/}
					{/*/>*/}
					<Button
						icon={<ArrowPathIcon width={20} />}
						title={t('Restart')}
						onClick={actions?.onStart}
						size={btnSize}
						type={btnType ?? 'primary'}
					/>
					{type === EHandlerActionTypes.NextStep && (
						<>
							<Button
								icon={<PencilSquareIcon width={24} />}
								title={t('Change')}
								onClick={actions?.onChange}
								size={btnSize}
								type={btnType}
							/>
							<Button
								icon={<ChevronDoubleRightIcon width={20} />}
								onClick={actions?.onNextStep}
								title={t('Next step')}
								size={btnSize}
								type={btnType ?? 'primary'}
							/>
						</>
					)}
				</Space>
			)

		case EHandlerActionTypes.Restart:
		case EHandlerActionTypes.Start:
			return (
				<Space size={ESpacer.SPACER6}>
					<Button
						danger
						icon={<TrashIcon width={24} />}
						title={t('Remove')}
						onClick={actions?.onDelete}
						size={btnSize}
						type={btnType}
					/>
					<Button
						icon={<PencilSquareIcon width={24} />}
						title={t('Change')}
						onClick={actions?.onChange}
						size={btnSize}
						type={btnType}
					/>
					<Button
						icon={type === 'start' ? <PlayIcon width={20} /> : <ArrowPathIcon width={20} />}
						onClick={actions?.onStart}
						title={t(type ==='start'? 'Start' : 'Restart')}
						size={btnSize}
						type={btnType ?? 'primary'}
					>
						{btnType !== 'icon' ? t(type === 'start' ? 'Start' : 'Restart') : null}
					</Button>
				</Space>
			)

		case EHandlerActionTypes.Stop:
			return (
				<Space size={ESpacer.SPACER6}>
					<Button
						danger
						icon={<TrashIcon width={24} />}
						title={t('Remove')}
						onClick={actions?.onDelete}
						size={btnSize}
						type={btnType}
					/>
					{/* TODO Эти функции пока убираем */}
					{/*<Button*/}
					{/*	icon={<Square2StackIcon width={24} />}*/}
					{/*	title={t('Duplicate')}*/}
					{/*	onClick={actions?.onDuplicate}*/}
					{/*/>*/}
					{/*<Button*/}
					{/*	type='primary'*/}
					{/*	icon={<StopIcon width={20} />}*/}
					{/*	onClick={actions?.onStop}*/}
					{/*>*/}
					{/*	{t('Stop')}*/}
					{/*</Button>*/}
				</Space>
			)

		default:
			return null
		}
	}, [
		type,
		formName,
		t,
		actions,
		btnSize,
		btnType,
	])

	return (
		<Row
			className={styles.root}
			justify='end'
		>
			{content}
		</Row>
	)
}
