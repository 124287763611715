import { Col, Row } from 'antd'
import React from 'react'

import styles from './styles.module.scss'
import { IShortContentProps } from './types'

export default function ShortContent({ children }: IShortContentProps) {
	return (
		<Row
			justify='center'
			className={styles.root}
		>
			<Col
				xxl={6}
				xl={6}
				lg={8}
				md={12}
				sm={18}
				xs={24}
			>
				{children}
			</Col>
		</Row>
	)
}
