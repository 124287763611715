export enum ELayoutsModalEntities {
  FileList = 'fileList',
  UploadList = 'uploadList',
}

export interface IModalState {
  open?: boolean
  data?: unknown
  clearDataAfterClose?: boolean
}

export type TLayoutsModals = Record<ELayoutsModalEntities, IModalState | undefined>

export interface IModalActionCommonPayload {
  entity: ELayoutsModalEntities
}

export interface IModalOpenActionPayload extends IModalActionCommonPayload {
  data?: unknown
  clearDataAfterClose?: boolean
}

export interface IModalSetDataActionPayload extends IModalActionCommonPayload {
  data?: unknown
}

export type TModalClearDataActionPayload = IModalActionCommonPayload

export type TModalOpenActionPayload = IModalActionCommonPayload

export interface ILayoutsStore {
  modals: TLayoutsModals
}
