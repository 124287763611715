/* eslint-disable no-mixed-spaces-and-tabs */
import { QuestionCircleOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import React, { ReactNode, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { SIMPLE_DATE_FORMAT } from 'common/constants/date'
import { validateByRegexp } from 'common/utils/rules'
import {
	Checkbox,
	DatePicker,
	Divider,
	Form,
	InfoDescription,
	Input,
	InputMask,
	InputNumber,
	Password,
	Radio,
	RadioGroupProps,
	RangePicker,
	Row,
	Select,
	SelectProps,
	Space,
	Switch,
	TextArea,
	TextAreaProps,
	Tooltip,
	Typography,
} from 'components'
import { ICheckboxProps } from 'components/Checkbox/types'
import { IDatePickerProps } from 'components/DatePicker/types'
import { IInputProps} from 'components/Input/types'
import { IInputMaskProps } from 'components/InputMask/types'
import { IInputNumberProps } from 'components/InputNumber/types'
import { IRangePickerProps } from 'components/RangePicker/types'

import formGeneratorStyles from './styles.module.scss'
import { EFormTypes, IFormGeneratorProps } from './types'

const shouldUpdate = (dependencies: string[]) => (
	prevValues: { [key: string]: string },
	currentValues: { [key: string]: string }
) => dependencies?.some((field) => prevValues?.[field] !== currentValues?.[field])

export const FormGenerator = memo(function FormGeneratorComponent(props: IFormGeneratorProps) {
	const { t } = useTranslation('[errors, actions]')
	const { scheme = [], viewable, className, disabled } = props

	return (
		<div className={className}>
			{scheme.map(
				({
					name,
					helpText,
					label,
					required,
					type = EFormTypes.Default,
					rules = [],
					dependencies,
					shouldShow,
					inputProps: propsInputProps,
					regexp,
					formatViewValue,
					component,
				  className,
					 isVertical,
					...other
				}) => {
					const fieldRules = [...rules]

					const inputProps = {
						disabled,
						...propsInputProps || {},
					}

					if (regexp) {
						fieldRules.push(validateByRegexp(regexp))
					}

					const key = Array.isArray(name) ? name.join() : name

					const props = {
						key,
						name,
						label,
						disabled,
						...other,
					}

					const formProps = {
						...props,
						tooltip: helpText || undefined,
						validateFirst: true,
						className: classNames({
							[formGeneratorStyles.viewableInput]:
                viewable?.isEdit && type !== EFormTypes.InfoDescription,
							[formGeneratorStyles.noInline]:
                viewable?.isEdit && type !== EFormTypes.InfoDescription && viewable?.noInline,
							[formGeneratorStyles.vertical]: isVertical,
						}, className),
						rules: [
							{
								required,
								message: t('validation.Field is required'),
							},
							...fieldRules,
						],
					}

					let content: ReactNode =
            viewable && type !== EFormTypes.Error ? (
            	<Form.Item
            		{...formProps}
            		className={formGeneratorStyles.hideMarginBottom}
            		label={undefined}
            	>
            		<InfoDescription.FormField
            			{...props}
            			formatViewValue={formatViewValue}
            		/>
            	</Form.Item>
            ) : null

					if (!viewable || viewable.isEdit) {
						switch (type) {
						case EFormTypes.InfoDescription: {
							break
						}

						case EFormTypes.Divider: {
							content = <Divider {...props} />
							break
						}

						case EFormTypes.Title: {
							content = (
								<Row
									key={key}
									wrap={false}
								>
									{required && (
										<Typography.Text
											className={formGeneratorStyles.requiredMark}
											type='danger'
										>
                        *
										</Typography.Text>
									)}
									<Typography.Title
										{...props}
										className={formGeneratorStyles.title}
										level={4}
									>
										{label}
									</Typography.Title>
									{helpText && <QuestionCircleOutlined />}
								</Row>
							)
							break
						}

						case EFormTypes.Description: {
							content = (
								<Typography.Text
									type='secondary'
									{...props}
								>
									{label}
								</Typography.Text>
							)
							break
						}

						case EFormTypes.Custom: {
							if (component) {
								content = (
									<Form.Item {...formProps}>
										{typeof component === 'function'? component() : component}
									</Form.Item>
								)
							}

							break
						}

						case EFormTypes.Error: {
							content = (
								<Form.Item
									{...formProps}
									className={formGeneratorStyles.noInput}
								>
									<span />
								</Form.Item>
							)

							break
						}

						case EFormTypes.Date: {
							content = (
								<Form.Item {...formProps}>
									<DatePicker
										{...(inputProps as IDatePickerProps)}
										format={SIMPLE_DATE_FORMAT}
									/>
								</Form.Item>
							)
							break
						}

						case EFormTypes.DateRangePicker: {
							content = (
								<Form.Item {...props}>
									<RangePicker
										allowClear
										format={SIMPLE_DATE_FORMAT}
										{...(inputProps as IRangePickerProps)}
									/>
								</Form.Item>
							)
							break
						}

						case EFormTypes.Textarea: {
							content = (
								<Form.Item {...formProps}>
									<TextArea {...(inputProps as TextAreaProps)} />
								</Form.Item>
							)
							break
						}

						case EFormTypes.Password: {
							content = (
								<Form.Item {...formProps}>
									<Password {...(inputProps as IInputProps)} />
								</Form.Item>
							)
							break
						}

						case EFormTypes.Number: {
							content = (
								<Form.Item {...formProps}>
									<InputNumber
										isFullWidth
										{...(inputProps as IInputNumberProps)}
									/>
								</Form.Item>
							)
							break
						}

						case EFormTypes.Mask: {
							content = (
								<Form.Item {...formProps}>
									<InputMask
										role='input'
										{...(inputProps as IInputMaskProps)}
									/>
								</Form.Item>
							)
							break
						}

						case EFormTypes.Checker: {
							const { label, ...other } = formProps

							content = (
								<Form.Item
									{...other}
									valuePropName='checked'
								>
									<Checkbox {...(inputProps as ICheckboxProps)}>
										{label}
										{helpText && (
											<Tooltip title={helpText}>
												<QuestionCircleOutlined className={formGeneratorStyles.helpTooltip} />
											</Tooltip>
										)}
									</Checkbox>
								</Form.Item>
							)
							break
						}

						case EFormTypes.Boolean: {
							content = (
								<Form.Item {...formProps}>
									<Radio.Group>
										<Space size={40}>
											<Radio value>{t('Yes')}</Radio>
											<Radio value={false}>{t('No')}</Radio>
										</Space>
									</Radio.Group>
								</Form.Item>
							)
							break
						}

						case EFormTypes.Switch: {
							content = (
								<Form.Item
									{...formProps}
									valuePropName='checked'
								>
									<Switch />
								</Form.Item>
							)
							break
						}

						case EFormTypes.Select: {
							content = (
								<Form.Item {...formProps}>
									<Select
										allowClear
										placeholder={t('Choose option')}
										{...(inputProps as SelectProps)}
									/>
								</Form.Item>
							)
							break
						}

						case EFormTypes.Radio: {
							content = (
								<Form.Item {...formProps}>
									<Radio.Group {...(inputProps as RadioGroupProps)} />
								</Form.Item>
							)
							break
						}

						default: {
							content = (
								<Form.Item {...formProps}>
									<Input
										role='input'
										{...(inputProps as IInputProps)}
									/>
								</Form.Item>
							)
						}
						}
					}

					if (dependencies && shouldShow) {
						return (
							<Form.Item
								key={formProps.key}
								noStyle
								shouldUpdate={shouldUpdate(dependencies)}
							>
								{({ getFieldsValue }) => {
									if (shouldShow(getFieldsValue())) {
										return content
									}

									return null
								}}
							</Form.Item>
						)
					}

					return content
				}
			)}
		</div>
	)
})
