import { ArrowUpTrayIcon } from '@heroicons/react/24/outline'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

import { ROUTES_ENTITIES } from 'app/router/constants'
import { useBooleanFlag } from 'common/hooks/boolean'
import { useForm } from 'common/hooks/form'
import { useMutationNotifications } from 'common/hooks/notifications'
import { Button, Form, FormGenerator, Modal, Row, Space, Text, confirmDelete } from 'components'
import { EFormTypes, IFormScheme } from 'components/FormGenerator/types'
import { useFileListDrawer } from 'features/data/blocks/FileListDrawerGlobal/hooks'
import { IBucketFileList, TBucketFile } from 'features/data/libs/BucketConnector/types'
import { FileList } from 'features/data/ui/FileList'
import { getFiles } from 'features/data/utils'
// import { HANDLER_STEPS_LABELS } from 'features/handlers/constants'
import { useProject } from 'features/projects/store/hooks'
import { handlersApi } from 'services/handlers'
import { EHandlerSteps, IHandler } from 'services/handlers/types'

// import { EHandlerSteps } from 'services/handlers/types'
import { HANDLER_DATA_RULES } from './constants'
import styles from './styles.module.scss'
import { ICreateHandlerFormValues, ICreateHandlerModalProps } from './types'

const { useCreateHandlerMutation } = handlersApi

const FORM_NAME = 'handler'

export function CreateHandlerModal(props: ICreateHandlerModalProps) {
	const { open, onCancel } = props
	const { t } = useTranslation(['handlers', 'storage'], { nsMode: 'fallback' })
	const { id: projectId } = useParams()
	const [isFormTouched, setIsFormTouched] = useBooleanFlag()
	const navigate = useNavigate()
	const { data: project } = useProject(projectId)

	const [createHandler, createHandlerState] = useMutationNotifications(useCreateHandlerMutation, {
		errorText: t('An error occurred while creating the handler'),
		successText: t('New handler successfully created'),
	})

	const [form] = useForm(createHandlerState)

	const onSelectFilesCallback = useCallback((files: (TBucketFile | IBucketFileList)[]) => {
		form.setFieldValue('files', files)
	}, [form])

	const { openFileList } = useFileListDrawer(onSelectFilesCallback, {
		rootPath: project?.name ? [project?.name] : [],
	})
	const files = Form.useWatch('files', form) as (TBucketFile | IBucketFileList)[]
	const step = EHandlerSteps.Preprocessing
	// const step = Form.useWatch('step', form) as EHandlerSteps

	useEffect(() => {
		if (!open) {
			form.resetFields()
			setIsFormTouched(false)
		}
	}, [open, form, setIsFormTouched])

	const onRemoveFilesCallback = useCallback((fileKey: string) => {
		form.setFieldValue('files', files.filter((file) => file.key !== fileKey))
	}, [form, files])

	const onCancelCallback = useCallback(() => {
		if (isFormTouched) {
			confirmDelete({
				title: t('The created handler will be deleted, continue?'),
				onOk: onCancel,
				okText: t('actions:Continue'),
			})
		} else {
			onCancel()
		}
	}, [isFormTouched, onCancel, t])

	const scheme = useMemo<IFormScheme[]>(() => [
		{
			label: t('Handler title'),
			name: 'title',
			type: EFormTypes.Textarea,
			required: true,
			inputProps: {
				placeholder: t('Enter handler title'),
			},
			rules: [
				{
					type: 'string',
					max: 100,
				}
			]
		},
		// TODO Пока убираем выбор шага
		// {
		// 	name: 'step',
		// 	type: EFormTypes.Select,
		// 	label: t('Handler type'),
		// 	required: true,
		// 	inputProps: {
		// 		size: 'small',
		// 		placeholder: t('Choose handler type'),
		// 		options: formattingLabels2Options(HANDLER_STEPS_LABELS)
		// 	}
		// },
		{
			name: 'files',
			type: EFormTypes.Custom,
			label: t('Choose data for handler'),
			required: true,
			component: () => {
				const rulesData = HANDLER_DATA_RULES[step]

				return (
					<Space direction='vertical'>
						{rulesData && (
							<Space
								direction='vertical'
								className={styles.disclaimer}
							>
								<div>
									{rulesData?.nextStepHeader && (
										<Text type='body12' bold>{rulesData.nextStepHeader}</Text>
									)}
									{rulesData?.nextStepRules && (
										<ul className={styles.ul}>
											{rulesData.nextStepRules.map((rule) => (
												<li key={rule}>
													<Text type='body12'>{rule}</Text>
												</li>
											))}
										</ul>
									)}
								</div>
								<div>
									{rulesData?.standaloneHeader && (
										<Text type='body12' bold>{rulesData.standaloneHeader}</Text>
									)}
									{rulesData?.standaloneRules && (
										<ul className={styles.ul}>
											{rulesData.standaloneRules.map((rule) => (
												<li key={rule}>
													<Text type='body12'>{rule}</Text>
												</li>
											))}
										</ul>
									)}
								</div>
							</Space>
						)}
						{files?.length && (
							<FileList
								files={files}
								onRemove={onRemoveFilesCallback}
							/>
						)}
						<Button
							size='small'
							type='tertiary'
							onClick={openFileList}
							icon={<ArrowUpTrayIcon width={20}/>}
						>
							{t('Upload data')}
						</Button>
					</Space>
				)
			}
		}
	], [
		step,
		openFileList,
		t,
		files,
		onRemoveFilesCallback,
	])

	const onFinishCallback = useCallback(async (values: ICreateHandlerFormValues) => {
		const result = await createHandler({
			step: EHandlerSteps.Preprocessing, // todo Убрать после открытия выбора шага
			files: getFiles(values?.files)?.map((file) => (file.key)) as string[],
			projectId,
			title: values?.title,
			config: {},
		})

		if (!('error' in result)) {
			navigate(ROUTES_ENTITIES.getHandlerPage((result.data as IHandler)?.id))
		}
	}, [createHandler, projectId, navigate])

	const onResetCallback = useCallback(() => {
		onCancelCallback()
	}, [onCancelCallback])

	const onValuesChangedCallback = useCallback(() => {
		setIsFormTouched(true)
	}, [setIsFormTouched])

	return (
		<Modal
			centered
			destroyOnClose
			open={open}
			onCancel={onCancelCallback}
			title={t('Entering primary handler data')}
			width={550}
			footer={(
				<Row justify='end'>
					<Space>
						<Button
							onClick={onResetCallback}
							type='secondary'
						>
							{t('Cancel')}
						</Button>
						<Button
							htmlType='submit'
							form={FORM_NAME}
							type='primary'
						>
							{t('Create')}
						</Button>
					</Space>
				</Row>
			)}
		>
			<Form
				form={form}
				layout='vertical'
				name={FORM_NAME}
				onFinish={onFinishCallback}
				onValuesChange={onValuesChangedCallback}
			>
				<FormGenerator scheme={scheme} />
			</Form>
		</Modal>
	)
}
