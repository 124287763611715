export const setStorageValue = (key: string, value: string) => localStorage.setItem(key, value)

export const getStorageValue = <T>(key: string, normalize?: (value: string) => T): T | undefined => {
	const value = localStorage.getItem(key)

	if (!normalize || value === undefined) {
		return value as T
	}

	try {
		return normalize(value as string) as T
	} catch (e) {
		console.error(e as string)
	}
}

export const clearStorageValue = (key: string) => localStorage.removeItem(key)
