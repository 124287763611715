import { App, notification as antdNotification } from 'antd'
import type { MessageInstance } from 'antd/es/message/interface'
import type { ModalStaticFunctions } from 'antd/es/modal/confirm'
import { ModalFuncProps } from 'antd/es/modal/interface'
import type { NotificationInstance } from 'antd/es/notification/interface'

import { i18n } from 'app/localization'

const { t } = i18n
let message: MessageInstance
let notification: NotificationInstance
let modal: Omit<ModalStaticFunctions, 'warn'>
const { useNotification } = antdNotification

export default function AntStaticFunctions() {
	const staticFunction = App.useApp()

	message = staticFunction.message
	modal = staticFunction.modal
	notification = staticFunction.notification
	notification = staticFunction.notification
	return null
}

export { message, notification, modal, useNotification }

export const confirmDelete = (props: Partial<ModalFuncProps>) => {
	modal.confirm({
		okText: t('actions:Remove'),
		okType: 'primary',
		className: 'error',
		icon: null,
		cancelText: t('actions:Cancel'),
		centered: true,
		width: 500,
		closable: true,
		...props,
	})
}
