import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { transformToArrayIfNeedIt } from 'common/utils/array'
import { FormGenerator } from 'components'
import { EFormTypes, IFormScheme } from 'components/FormGenerator/types'
import { HandlerStepBlock } from 'features/handlers/blocks/HandlerStepBlock'
import { EHandlerSettingModes, IHandleHeights } from 'services/handlers/types'

import styles from './styles.module.scss'

const getShowConfig = (flagName: 'slopeFlag' | 'expositionFlag' | 'itpFlag') =>
	(values: IHandleHeights) => values.settingsMode === EHandlerSettingModes.Manual && values?.[flagName]
const getDependencies = (dependencies: string | string[]) => [...transformToArrayIfNeedIt(dependencies), 'settingsMode']

export function RasterLayers() {
	const { t } = useTranslation('handlers')

	const scheme = useMemo<IFormScheme[]>(() => [
		{
			name: 'slopeFlag',
			type: EFormTypes.Checker,
			label: <span>{t('Slope inclination')}</span>,
			helpText: t('hints.slopeFlag'),
			inputProps: {
				size: 'small',
			},
		},
		{
			name: 'slopeResolution',
			isVertical: true,
			type: EFormTypes.Number,
			className: styles.nestedItem,
			label: <span>{t('Resolution (m/px)')}</span>,
			required: true,
			shouldShow: getShowConfig('slopeFlag'),
			dependencies: getDependencies('slopeFlag'),
			inputProps: {
				size: 'small',
			},
		},
		{
			name: 'expositionFlag',
			type: EFormTypes.Checker,
			label: <span>{t('Exposure (azimuth, degrees)')}</span>,
			helpText: t('hints.expositionFlag'),
			inputProps: {
				size: 'small',
			},
		},
		{
			name: 'expositionResolution',
			isVertical: true,
			type: EFormTypes.Number,
			className: styles.nestedItem,
			label: <span>{t('Resolution (m/px)')}</span>,
			required: true,
			shouldShow: getShowConfig('expositionFlag'),
			dependencies: getDependencies('expositionFlag'),
			inputProps: {
				size: 'small',
			},
		},
		{
			name: 'itpFlag',
			type: EFormTypes.Checker,
			label: <span>{t('Topographic position index (SD)')}</span>,
			helpText: t('hints.itpFlag'),
			inputProps: {
				size: 'small',
			},
		},
		{
			name: 'itpResolution',
			isVertical: true,
			type: EFormTypes.Number,
			className: styles.nestedItem,
			label: <span>{t('Resolution (m/px)')}</span>,
			required: true,
			shouldShow: getShowConfig('itpFlag'),
			dependencies: getDependencies('itpFlag'),
			inputProps: {
				size: 'small',
			},
		},
	], [t])

	return (
		<HandlerStepBlock title={t('Additional raster layers')}>
			<FormGenerator scheme={scheme} />
		</HandlerStepBlock>
	)
}
