import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { BlockTitle, Divider, FormLayout, Row, Space, Text } from 'components'
import { useHandlerContext } from 'features/handlers/hooks'
import { HandlerEventStatusTag } from 'features/handlers/ui/HandlerEventStatusTag'
import { HandlerStepFooters } from 'features/handlers/ui/HandlerStepFooter'

import styles from './styles.module.scss'
import { IHandlerStepBase } from './types'

export function HandlerStepBase<FormTypes = unknown>(props: IHandlerStepBase<FormTypes>) {
	const { t } = useTranslation('handlers')
	const { footerProps: contextFooterProps, handlerData, isEdit, form } = useHandlerContext()
	const { step, stepTitle, hint, footer: propsFooter, footerProps: propsFooterProps, status: propsStatus, ...layoutProps } = props
	const status = useMemo(() => (handlerData?.status || propsStatus), [handlerData?.status, propsStatus])

	const footerProps = useMemo(() => ({
		...contextFooterProps || {},
		...propsFooterProps || {},
	}), [contextFooterProps, propsFooterProps])

	const footer = useMemo(() => footerProps
		? (
			<HandlerStepFooters
				formName={layoutProps.formName}
				{...footerProps}
			/>
		)
		: (propsFooter || null),
	[
		propsFooter,
		footerProps,
		layoutProps.formName,
	])

	return (
		<div className={styles.root}>
			<div className={styles.header}>
				<BlockTitle
					title={handlerData?.title ?? t('Adding handler')}
					type='body18'
					bold
				/>
				<Divider className={styles.divider} />
			</div>
			<Space
				isFullWidth
				direction='vertical'
				className={styles.content}
			>
				<Text
					type='body14'
					typographyType='secondary'
				>
					{t('step-description', { step, max: 5 })}
				</Text>
				<BlockTitle
					title={stepTitle}
					hint={hint}
					type='body18'
					bold
				>
					{status && <HandlerEventStatusTag status={status} />}
				</BlockTitle>
				<FormLayout disabled={!isEdit} form={form} {...layoutProps} />
			</Space>
			<Row className={styles.footer}>
				{footer}
			</Row>
		</div>
	)
}
