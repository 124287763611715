import { configureStore } from '@reduxjs/toolkit'

import { isProduction } from 'common/constants/client'
import { dataSlice } from 'features/data/store'
import { handlersSlice } from 'features/handlers/store'
import { layoutsSlice } from 'features/layouts/store'
import { mapSlice } from 'features/map/store'
import { api } from 'services'

const REDUCERS = {
	[api.reducerPath]: api.reducer,
	[mapSlice.name]: mapSlice.reducer,
	[dataSlice.name]: dataSlice.reducer,
	[layoutsSlice.name]: layoutsSlice.reducer,
	[handlersSlice.name]: handlersSlice.reducer,
}

const MIDDLEWARES = [
	api.middleware
]

export const store = configureStore({
	reducer: REDUCERS,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(MIDDLEWARES),
	devTools: isProduction,
	enhancers: [],
})
