import { EHandlerSettingModes } from 'services/handlers/types'

export const CLASSIFICATION_STEP_INITIAL_VALUES = {
	settingsMode: EHandlerSettingModes.Auto,
	spatialResolution: 1,
	iterationNum: 500,
	limitHeight: 0.5,
	window: 16,
	slope: 10,
}
