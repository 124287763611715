import qs from 'query-string'
import React, {  useState } from 'react'
import { useAsync } from 'react-use'
import { GeoJsonDataSource } from 'resium'

import { IWFSLayerProps } from 'features/map/ui/WFSLayer/types'
import { layerToStyle } from 'features/map/utils'

export function WFSLayer({ layer }: IWFSLayerProps) {
	const [state, setState] = useState()

	useAsync(async () => {
		const data = await fetch(`${layer.url}?${
			qs.stringify({
				SERVICE: 'WFS',
				VERSION: '2.0.0',
				REQUEST: 'GetFeature',
				outputFormat: 'application/json',
				...layer.params,
			})
		}`).then(res => res.json())

		setState(data)
	}, [])


	if (!state) {
		return null
	}

	return (
		<GeoJsonDataSource
			{...layerToStyle(layer)}
			data={state}
		/>
	)
}
