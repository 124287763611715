import classNames from 'classnames'
import React from 'react'

import styles from './styles.module.scss'
import { IPaperProps } from './types'

export function Paper(props: IPaperProps) {
	const { children, className, isFullHeight } = props

	return (
		<div className={classNames(styles.root, className, {
			[styles.fullHeight]: isFullHeight,
		})}>
			{children}
		</div>
	)
}
