import classNames from 'classnames'
import React from 'react'

import { AntdCheckbox } from 'components/index'

import styles from './styles.module.scss'
import { ICheckboxProps } from './types'

export function Checkbox(props: ICheckboxProps) {
	const { bordered, className, ...checkboxProps } = props

	return (
		<AntdCheckbox
			{...checkboxProps}
			className={classNames(className, {
				[styles.bordered]: bordered,
			})}
		/>
	)
}
