import { Cartesian3, Entity } from 'cesium'
import { ReactNode } from 'react'

import { IMap } from 'services/maps/types'

export enum EFeatureTypes {
  Point = 'point',
  Polyline = 'polyline',
  Polygon =  'polygon'
}

export interface IMapContext {
  mapId?: number,
  map?: IMap
}

export type IMapBaseLayerNames = 'osm' | 'esri' | 'empty' | 'opentopomap'
export interface IBaseLayerObject {
  key: IMapBaseLayerNames
  title: string,
  image: string | ReactNode,
}

export interface ICreateFeatureReturn {
  activeType: EFeatureTypes | null;
  activate: (type: EFeatureTypes) => void
  deactivate: () => void
}

export interface ICreateFeatureOptions {
  onCreate?: (geometry: Entity, type: EFeatureTypes) => void
  onChange?: (geometry: Entity, type: EFeatureTypes, position: Cartesian3) => void
  onMove?: (geometry: Entity, type: EFeatureTypes, position: Cartesian3) => void
}
