export const PRODUCTION = 'production'
export const DEVELOPMENT = 'development'

export const isProduction = process.env.NODE_ENV === PRODUCTION
export const isDevelopment = process.env.NODE_ENV === DEVELOPMENT

export const ROOT_CONTAINER_NAME = 'root'
export const BODY_CONTAINER_NAME = 'body'
export const PAGE_CONTAINER_NAME = 'html'
export const CONTENT_CONTAINER_NAME = 'content'

export const DEFAULT_DEBOUNCE_DELAY = 500
export const SECOND_PANEL_SIZE = 380

export const DEFAULT_POOLING_INTERVAL = 1000 * 60 * 7

export const DEFAULT_TABLE_PARAMS = {
	limit: 5,
	skip: 0,
}
