import { ChevronUpIcon, XMarkIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import React, { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch } from 'app/store/hooks'
import { stopEventPropagation } from 'common/utils/event'
import { Collapse, RcFile, Text, Upload } from 'components'
import { BucketConnector } from 'features/data/libs/BucketConnector'
import {
	EBucketConnectorUploadFileStatus,
	TBucketUploadProgressCallback
} from 'features/data/libs/BucketConnector/types'
import { updateDataSlice } from 'features/data/store/actions'
import { FileList } from 'features/data/ui/FileList'
import { useModalState } from 'features/layouts/store/hooks'
import { ELayoutsModalEntities } from 'features/layouts/store/types'

import styles from './styles.module.scss'
import { IUploadListGlobalData, TUploadFileWithProgress } from './types'

export function UploadListGlobal() {
	const { t } = useTranslation('storage')
	const { data, setData } = useModalState<IUploadListGlobalData>(ELayoutsModalEntities.UploadList)
	const { uploadType, isUploadRequested, path } = data
	const [fileMap, setFileMap] = useState<{ [key: string]: TUploadFileWithProgress }>({})
	const uploadTriggerRef = useRef<HTMLButtonElement>(null)
	const dispatch = useAppDispatch()

	useEffect(() => {
		if (isUploadRequested) {
			uploadTriggerRef.current?.click()
			setData({
				isUploadRequested: false,
				uploadType,
				path,
			})
		}
	}, [isUploadRequested, path, setData, uploadType])

	const onUpdateFileProgressCallback = useCallback<TBucketUploadProgressCallback>(({ file, ...rest }) => {
		setFileMap((files) => {
			if (!files[file.uid]) {
				return files
			}

			return ({
				...files,
				[file.uid]: {
					...files[file.uid],
					...rest,
				}
			})
		})
	}, [])

	const onBeforeUploadCallback = useCallback((file: RcFile) => {
		const { name, uid, size, type, lastModified, webkitRelativePath } = file

		setFileMap((files) => ({
			...files,
			[file.uid]: {
				name: webkitRelativePath || name,
				uid,
				size,
				type,
				lastModified,
				progress: 0,
			} as TUploadFileWithProgress
		}))

		void BucketConnector?.uploadToBucket(file, path || '', onUpdateFileProgressCallback)

		setData({
			isUploadRequested: false,
			uploadType: undefined,
			path: undefined,
		})

		return false
	}, [onUpdateFileProgressCallback, setFileMap, path, setData])

	const { uploadingCount, finishedCount } = useMemo(() => Object.values(fileMap).reduce((acc, file) => {
		if ([EBucketConnectorUploadFileStatus.Uploading, EBucketConnectorUploadFileStatus.Pending].includes(file.status)) {
			acc.uploadingCount++
		} else {
			acc.finishedCount++
		}

		return acc
	}, {
		uploadingCount: 0,
		finishedCount: 0,
	}), [fileMap])

	useEffect(() => {
		if (uploadingCount === 0 && Boolean(finishedCount)) {
			dispatch(updateDataSlice())
		}
	}, [uploadingCount, finishedCount, dispatch])

	const headerTitle = useMemo(() => {
		if (!uploadingCount && !finishedCount) {
			return t('Uploading files')
		}

		return t(uploadingCount ? 'uploading-list-label' : 'uploaded-list-label', { count: uploadingCount || finishedCount })
	}, [uploadingCount, finishedCount, t])

	const onDestroyPanel = useCallback((e: SyntheticEvent) => {
		stopEventPropagation(e)
		setFileMap({})
	},[setFileMap])

	const items = useMemo(() => ([
		{
			key: 'list',
			label: (
				<Text
					type='body18'
					bold
					ellipsis
				>
					{headerTitle}
				</Text>
			),
			extra: (
				<XMarkIcon
					width={24}
					title={t('Close')}
					onClick={onDestroyPanel}
				/>
			),
			children: <FileList files={Object.values(fileMap)} />,
		}
	]), [
		t,
		fileMap,
		headerTitle,
		onDestroyPanel,
	])

	const expandIconCallback = useCallback(({ isActive }: { isActive?: boolean }) => (
		<ChevronUpIcon
			width={24}
			title={t(isActive ? 'Collapse' : 'Expand')}
			className={classNames({
				[styles.activePanelIcon]: isActive,
			})}
		/>
	), [t])

	return (
		<>
			{Boolean(Object.values(fileMap)?.length) && (
				<Collapse
					className={styles.root}
					bordered={false}
					expandIcon={expandIconCallback}
					expandIconPosition='end'
					defaultActiveKey='list'
					items={items}
				/>
			)}
			<Upload
				multiple
				beforeUpload={onBeforeUploadCallback}
				directory={uploadType === 'directory'}
				showUploadList={false}
				className={styles.upload}
			>
				<button
					ref={uploadTriggerRef}
					className={styles.uploadTrigger}
				/>
			</Upload>
		</>
	)
}
