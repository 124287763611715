import { S3Client } from '@aws-sdk/client-s3'
import { S3RequestPresigner } from '@aws-sdk/s3-request-presigner'
import { createRequest } from '@aws-sdk/util-create-request'
import { Command } from '@smithy/smithy-client'
import { MetadataBearer } from '@smithy/types'
import { HttpRequest as IHttpRequest } from '@smithy/types/dist-types/http'
import qs from 'qs'

import { deepObjectCopy } from 'common/utils/object'
import {
	EBucketConnectorUploadFileStatus,
	IBucketFileList,
	TBucketFile,
	TBucketUploadSimpleProgressCallback
} from 'features/data/libs/BucketConnector/types'

export async function trackUploadProgress(request: IHttpRequest, progressCallback?: TBucketUploadSimpleProgressCallback) {
	return new Promise<XMLHttpRequest>((resolve, reject) => {
		const xhr = new XMLHttpRequest()
		const url = `${request.protocol}//${request.headers.host}${request.path}?${qs.stringify(request.query)}`
		let progress = 0

		progressCallback?.({
			progress,
			closeUpload: () => xhr.abort(),
			status: EBucketConnectorUploadFileStatus.Pending,
		})

		xhr.upload.addEventListener('progress', (event) => {
			if (event.lengthComputable && progressCallback) {
				progress = Math.round((event.loaded * 100) / event.total)

				progressCallback({
					progress,
					closeUpload: () => xhr.abort(),
					status: EBucketConnectorUploadFileStatus.Uploading,
				})
			}
		})

		xhr.open(request.method, url, true)

		Object.entries(request.headers).forEach(([key, value]) => {
			if (!['host', 'content-length'].includes(key)) {
				xhr.setRequestHeader(key, value as string)
			}
		})

		xhr.onload = () => {
			if (xhr.status === 200) {
				progressCallback?.({
					progress,
					status: EBucketConnectorUploadFileStatus.Success,
				})

				resolve(xhr)
			} else {
				progressCallback?.({
					progress,
					status: EBucketConnectorUploadFileStatus.Failed,
				})

				reject(new Error('Upload failed'))
			}
		}

		xhr.onabort = () => {
			progressCallback?.({
				progress,
				status: EBucketConnectorUploadFileStatus.Cancelled,
			})

			reject(new Error('Cancelled by user'))
		}

		xhr.onerror = () => {
			progressCallback?.({
				progress,
				status: EBucketConnectorUploadFileStatus.Failed,
			})

			reject(new Error('Network error'))
		}

		xhr.send(request.body)
	})
}

export async function createRequestPresigned<CommandInput extends Record<never, unknown>, CommandOutput extends MetadataBearer = MetadataBearer>(
	s3Client: S3Client,
	command: Command<CommandInput, CommandOutput, unknown, Record<never, unknown>, MetadataBearer>,
) {
	const request = await createRequest<Record<never, unknown>, CommandInput, CommandOutput>(s3Client, command)
	const signer = new S3RequestPresigner({ ...s3Client.config, })

	return await signer.presign(request)
}

export const getFileNameByKey = (key: string) => key.split('/').pop()

export const fillFileListObject = (fileList: IBucketFileList): IBucketFileList => {
	const fillDirectories = (oldDirectory: IBucketFileList, key: string, path = ''): IBucketFileList => {
		const directory = deepObjectCopy<IBucketFileList>(oldDirectory)
		const folderKey = path ? `${path}${key}/` : `${key}/`
		let size = 0

		Object.entries(oldDirectory).forEach(([innerKey, value]) => {
			if (value) {
				if ('eTag' in (value as TBucketFile)) {
					size += (value as TBucketFile)?.size || 0
				} else {
					const childDirectory = fillDirectories(value as IBucketFileList, innerKey, folderKey)

					size += childDirectory?.size || 0
					directory[innerKey] = childDirectory
				}
			}
		})

		return {
			content: {
				...directory
			},
			name: key,
			key: folderKey,
			isFile: false,
			size,
		}
	}

	return {
		content: Object.entries(fileList).reduce<IBucketFileList>((acc, [key, value]) => {
			if (value) {
				return {
					...acc,
					[key]: 'eTag' in (value as TBucketFile) ? value : fillDirectories(value as IBucketFileList, key)
				}
			}

			return acc
		}, {} as IBucketFileList)
	}
}

export const getFolderValueByPath = (propObject: IBucketFileList, path: string[]): IBucketFileList | undefined => {
	const rawObject = deepObjectCopy<IBucketFileList>(propObject)

	// @ts-ignore
	return path.reduce((childObject, key) => childObject?.content?.[key] as IBucketFileList, rawObject)
}
