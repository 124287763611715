import map from 'map-obj'
import toCamelCase from 'to-camel-case'

/**
 * Преобразует строку по маске
 * @param str - исходная строка
 * @param mask - формата 99 99 999
 * @returns строка по маске
 */
export const maskString = (str: string, mask: string): string => {
	if (!str) {
		return str
	}

	let i = 0

	return mask.replace(/9/g, () => str[i++] || '')
}

export function toCamelcaseKeys(data: Record<string, unknown>) {
	return map(data, (key, val) => [toCamelCase(key), val], {
		deep: true,
	})
}
