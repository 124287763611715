import {
	OpenStreetMapImageryProvider,
	UrlTemplateImageryProvider,
} from 'cesium'
import React, { useMemo } from 'react'
import { ImageryLayer } from 'resium'

import { useBaseLayer } from 'features/map/store/hooks'
export function BaseLayer() {
	const baseLayer = useBaseLayer()

	const provider = useMemo(() => {
		switch (baseLayer) {
		case 'osm':
			return (
				new OpenStreetMapImageryProvider({
					url : 'https://tile.openstreetmap.org/'
				})
			)
		case 'esri':
			return new UrlTemplateImageryProvider({
				maximumLevel: 17,
				url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
				credit: 'Tiles © Esri — Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
			})
		case 'opentopomap':
			return new OpenStreetMapImageryProvider({
				url : 'https://{s}.tile.opentopomap.org/'
			})
		default: {
			return null
		}
		}
	}, [baseLayer])

	// todo empty map
	return (
		provider && (
			<ImageryLayer imageryProvider={provider} />
		)
	)
}
