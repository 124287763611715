import { CSSProperties } from 'react'

export interface IMap {
    title: string
    description: string
    preview: string
    zoom: number
    center: {
        x: number
        y: number
    }
    id: number
    layers: IMapLayer[]
}

export type IChangeMapRequestParams = IMap

export enum ELayerTypes {
  'Group' = 'Group',
  'WFS' = 'WFS',
  'WMS' = 'WMS',
  'GeoJSON' = 'GeoJSON',
}

export interface ILegend {
  title: string,
  img?: string,
  style?: CSSProperties
}

interface IMapLayerBase {
  id: number
  title: string
  layers?: IMapLayer[]
}

interface IGeoLayer extends IMapLayerBase {
  legend?: ILegend[],
  url: string,
}

export interface IWmsLayer extends IGeoLayer {
  type: ELayerTypes.WMS,
  params: object,
}

export interface IWfsLayer extends IGeoLayer {
  type: ELayerTypes.WFS,
  params: object,
  style: {
    strokeWidth?: number,
    strokeColor?: string,
    fillColor?: string,
  },
}

export interface IGeoJsonLayer extends IGeoLayer {
  type: ELayerTypes.GeoJSON,
  style: {
    strokeWidth?: number,
    strokeColor?: string,
    fillColor?: string,
  },
}

export interface IGroupLayer extends IMapLayerBase {
  type: ELayerTypes.Group
}

export type IMapLayer = IWmsLayer | IGroupLayer | IGeoJsonLayer | IWfsLayer

export interface IChangeMapLayerRequestParams {
    mapId: number
    layerId: number
    title: string
    description: string
    legend: string
    path: string
}
