import axios from 'axios'

export const getImageContent = async (src: string) => {
	const response = await axios.get(src, {
		responseType: 'blob',
	})

	return await new Promise<ArrayBuffer | string>((resolve, reject) => {
		const reader = new FileReader()

		reader.onloadend = () => resolve(reader.result as string | ArrayBuffer | PromiseLike<string | ArrayBuffer>)
		reader.onerror = reject
		reader.readAsDataURL(response.data)
	})
}
