import React, { useEffect, useMemo } from 'react'
import { useOutletContext } from 'react-router-dom'

import { Spin, Text } from 'components'
import { IMainLayoutContext } from 'features/layouts/blocks/MainLayout/types'
import { projectsApi } from 'services/projects'

import { IProjectPageContext } from './types'

const { useGetProjectQuery } = projectsApi

export const useProjectPageContext = () => {
	const context = useOutletContext<IProjectPageContext>()

	return useMemo(() => context || {}, [context])
}

export const useProject = (projectId: string | undefined) => useGetProjectQuery({
	id: projectId as string,
}, {
	skip: !projectId,
})

export const useSetProjectHeader = (projectId: string | undefined, setHeaderContent: IMainLayoutContext['setHeaderContent']) => {
	const { data, isFetching } = useProject(projectId)

	useEffect(() => {
		if (data?.name || isFetching) {
			setHeaderContent(
				<Spin spinning={isFetching}>
					<Text type='header3'>{data?.name}</Text>
				</Spin>
			)
		}
	}, [setHeaderContent, data?.name, isFetching])
}
