import classNames from 'classnames'
import React from 'react'

import { ESpacer } from 'assets/theme/variables/spacers'
import { Hint, Paper, Space, Text } from 'components'

import styles from './styles.module.scss'
import { IHandlerStepBlockProps } from './types'

export function HandlerStepBlock(props: IHandlerStepBlockProps) {
	const { title, children, className, hint } = props

	return (
		<Paper className={classNames(styles.root, className)}>
			<Space className={styles.space} direction='vertical' size={ESpacer.SPACER6}>
				{title && (
					<Hint hint={hint} size='small'>
						<Text
							type='body14'
							title={title}
							typographyType='secondary'
							className={styles.title}
						>
							{title}
						</Text>
					</Hint>
				)}
				<div>
					{children}
				</div>
			</Space>
		</Paper>
	)
}
