import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { formattingLabels2Options } from 'common/utils/formatting'
import { FormGenerator } from 'components'
import { EFormTypes, IFormScheme } from 'components/FormGenerator/types'
import { HandlerStepBlock } from 'features/handlers/blocks/HandlerStepBlock'
import {
	HANDLER_SEGMENTATION_ALGORITHM_LABELS,
	HANDLER_SEGMENTATION_SEASONS_LABELS,
	HANDLER_SEGMENTATION_SOURCES_LABELS,
	HANDLER_SETTING_MODES_LABELS
} from 'features/handlers/constants'
import { EHandlerSegmentationAlgorithms, EHandlerSettingModes, IHandleLasSegmentation } from 'services/handlers/types'

import styles from './styles.module.scss'

export function Settings() {
	const { t } = useTranslation('handlers')

	const scheme = useMemo<IFormScheme[]>(() => [
		{
			name: 'settingsMode',
			type: EFormTypes.Radio,
			required: true,
			inputProps: {
				className: styles.settingsRadio,
				options: formattingLabels2Options(HANDLER_SETTING_MODES_LABELS),
			}
		},
		{
			name: 'segmentationAlgorithm',
			label: t('Segmentation algorithm'),
			type: EFormTypes.Select,
			inputProps: {
				allowClear: false,
				options: formattingLabels2Options(HANDLER_SEGMENTATION_ALGORITHM_LABELS),
			},
			shouldShow: (values: IHandleLasSegmentation) => values.settingsMode === EHandlerSettingModes.Manual,
			dependencies: ['settingsMode'],
		},
	], [t])

	const bottomScheme = useMemo<IFormScheme[]>(() => [
		{
			name: 'season',
			label: t('Shooting format'),
			type: EFormTypes.Select,
			inputProps: {
				allowClear: false,
				options: formattingLabels2Options(HANDLER_SEGMENTATION_SEASONS_LABELS),
			}
		},
		{
			name: 'source',
			label: t('Shooting method'),
			type: EFormTypes.Select,
			inputProps: {
				allowClear: false,
				options: formattingLabels2Options(HANDLER_SEGMENTATION_SOURCES_LABELS),
			},
			shouldShow: (values: IHandleLasSegmentation) => values.segmentationAlgorithm === EHandlerSegmentationAlgorithms.Li2012,
			dependencies: ['segmentationAlgorithm'],
		},
	], [t])

	return (
		<HandlerStepBlock title={t('Settings')}>
			<FormGenerator scheme={scheme} />
			<FormGenerator className={styles.shootingRow} scheme={bottomScheme} />
		</HandlerStepBlock>
	)
}
