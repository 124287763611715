import { useCallback, useEffect, useMemo } from 'react'

import {
	IFileListDrawerGlobalData,
	IFileListDrawerGlobalOptions
} from 'features/data/blocks/FileListDrawerGlobal/types'
import { TBucketFile } from 'features/data/libs/BucketConnector/types'
import { useModalState } from 'features/layouts/store/hooks'
import { ELayoutsModalEntities } from 'features/layouts/store/types'

export const useFileListDrawer = (
	onSelectFilesCallback: (files: TBucketFile[]) => void,
	rawOptions?: IFileListDrawerGlobalOptions
) => {
	const { open, data, clearData } = useModalState<IFileListDrawerGlobalData>(ELayoutsModalEntities.FileList)
	const { isMultiply = true, rootPath } = rawOptions || {}

	const options = useMemo<IFileListDrawerGlobalOptions>(() => ({
		isMultiply,
		rootPath,
	}), [isMultiply, rootPath])

	useEffect(() => {
		if (data?.files) {
			onSelectFilesCallback(data?.files)
			clearData()
		}
	}, [data?.files, clearData, onSelectFilesCallback])

	const onOpenCallback = useCallback(() => {
		open(options)
	}, [open, options])

	return {
		openFileList: onOpenCallback,
	}
}
