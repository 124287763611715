import {
	AbortMultipartUploadCommand,
	CompleteMultipartUploadCommand,
	CopyObjectCommand,
	CreateMultipartUploadCommand,
	DeleteObjectCommand,
	DeleteObjectsCommand,
	GetObjectCommand,
	HeadObjectCommand,
	ListObjectsV2Command,
	PutObjectCommand,
	PutObjectCommandInput,
	PutObjectCommandOutput,
	S3Client,
	UploadPartCommand,
	UploadPartCommandInput,
	UploadPartCommandOutput,
} from '@aws-sdk/client-s3'
import JSZip from 'jszip'

import { i18n } from 'app/localization'
import { downloadFileByBlob } from 'common/utils/file'
import { getObjectValueByPath, setObjectValueByPath } from 'common/utils/object'
import { RcFile, message } from 'components'
import { transformResponse } from 'services/utils'

import { HIDDEN_FILE_NAME, MAX_SIMPLE_UPLOAD_SIZE, PARTS_COUNT } from './constants'
import {
	EBucketConnectorUploadFileStatus,
	IBucketConnectorOptions, IBucketCopyFileResult, IBucketCreateFolderResult,
	IBucketData,
	IBucketDownloadFileResult,
	IBucketDownloadFolderResult, IBucketFileList,
	IBucketGetFileResult,
	IBucketObject, IBucketRenameFileResult, IBucketRenameFolderResult,
	IBucketSimpleResult,
	IBucketUploadFileResult,
	IBucketUploadSimpleProgress,
	IBucketUserData,
	TBucketUploadProgressCallback,
	TBucketUploadSimpleProgressCallback,
} from './types'
import { createRequestPresigned, fillFileListObject, getFolderValueByPath, trackUploadProgress } from './utils'

class BucketConnectorClient {
	s3Client: S3Client

	bucketData: IBucketData = {
		rawFiles: [],
		filesList: {},
	}

	bucketName: string
	rootFolder = ''
	isConnected = false

	constructor(options: IBucketConnectorOptions) {
		this.s3Client = new S3Client({
			region: options.region ?? 'ru-central1',
			credentials: {
				accessKeyId: options.accessKey,
				secretAccessKey: options.secretAccess,
			},
			forcePathStyle: true,
			tls: false,
			endpoint: options.endpoint,
		})

		this.bucketName = options.bucket
	}

	updateBucketContents = async (): Promise<IBucketData> => {
		this.bucketData = await this.getBucketObjectsList(this.bucketName, this.rootFolder)

		return this.bucketData
	}

	getBucketFiles = async (bucketName: string, prefix?: string): Promise<IBucketObject[]> => {
		const command = new ListObjectsV2Command({
			Bucket: bucketName,
			Prefix: prefix,
		})

		const rawFiles: IBucketObject[] = []

		try {
			let isTruncated = true

			while (isTruncated) {
				const { Contents, IsTruncated, NextContinuationToken } = await this.s3Client.send(command)

				if (Contents) {
					rawFiles.push(transformResponse(Contents))
				}

				isTruncated = Boolean(IsTruncated)

				command.input.ContinuationToken = NextContinuationToken
			}

			this.isConnected = true
		} catch (err) {
			console.error('Error in connection with Bucket', err)
			this.isConnected = false

			void message.error(i18n.t('errors:notFound.Failed to connect to the storage'))
		}

		return rawFiles
	}

	private getBucketObjectsList = async (bucketName: string, prefix?: string): Promise<IBucketData> => {
		const rawFiles = await this.getBucketFiles(bucketName, prefix)

		const files = rawFiles
			.flat()
			.map((file) => ({
				...file,
				lastModified: typeof file.lastModified === 'string' ? file.lastModified : file.lastModified?.toISOString()
			}))

		return {
			rawFiles: files,
			filesList: fillFileListObject(files.reduce<IBucketFileList>((acc, file) => {
				if (file?.key) {
					const filePath = file.key.split('/')
					const fileName = filePath.pop()
					const fileObj = {
						[fileName as string]: {
							...file,
							name: fileName,
							isFile: true,
						},
					}

					if (filePath?.length) {
						const valuesByPath = getObjectValueByPath(acc, filePath) || {}

						return setObjectValueByPath(acc, filePath, {
							...valuesByPath,
							...fileObj,
						})
					}

					return {
						...acc,
						...fileObj,
					}
				}

				return acc
			}, {} as IBucketFileList))
		}
	}

	uploadToBucket = async (
		file: RcFile,
		path = '',
		uploadProgressCb?: TBucketUploadProgressCallback
	): Promise<IBucketUploadFileResult> => {
		const key = `${path ? `${path}/` : ''}${file.webkitRelativePath || file.name}`

		const uploadProgress = (data: IBucketUploadSimpleProgress) => {
			uploadProgressCb?.({ ...data, file })
		}

		try {
			if (file.size < MAX_SIMPLE_UPLOAD_SIZE) {
				await this.simpleUpload(key, file, uploadProgress)
			} else {
				await this.multipartUpload(key, file, uploadProgress)
			}

			return {
				isSuccess: true,
				file,
			}
		} catch (error: unknown) {
			console.error(`Error while uploading ${file.name}`, error)

			return {
				isSuccess: false,
				file,
				error,
			}
		}
	}

	private simpleUpload = async (key: string, file: RcFile, uploadProgress?: TBucketUploadSimpleProgressCallback) => {
		const command = new PutObjectCommand({
			Bucket: this.bucketName,
			Key: key,
			Body: file,
		})

		const presigned = await createRequestPresigned<PutObjectCommandInput, PutObjectCommandOutput>(this.s3Client, command)

		return await trackUploadProgress(presigned, uploadProgress)
	}

	private multipartUpload = async (key: string, file: RcFile, uploadProgress?: TBucketUploadSimpleProgressCallback) => {
		const buffer = await file.arrayBuffer()
		let uploadId

		try {
			const multipartUpload = await this.s3Client.send(
				new CreateMultipartUploadCommand({
					Bucket: this.bucketName,
					Key: key,
				})
			)

			uploadId = multipartUpload.UploadId

			const uploadPromises = []
			const partSize = Math.ceil(buffer.byteLength / PARTS_COUNT)
			const completedParts: number[] = []
			const abortCallbacks: (() => void)[] = []
			let progress = 0
			let aborted = false

			const onAbortUpload = () => {
				aborted = true
				abortCallbacks.forEach((cb) => cb())

				uploadProgress?.({
					progress,
					status: EBucketConnectorUploadFileStatus.Cancelled,
				})
			}

			const onCompletePart = (partNumber: number, { progress: partProgress, closeUpload }: IBucketUploadSimpleProgress) => {
				completedParts[partNumber] = partProgress
				progress = Math.round(completedParts.reduce((acc, partPercent) => acc + partPercent) / PARTS_COUNT)

				if (!abortCallbacks[partNumber - 1] && closeUpload) {
					if (aborted) {
						closeUpload()
					} else {
						abortCallbacks[partNumber - 1] = closeUpload
					}
				}

				if (!aborted) {
					uploadProgress?.({
						progress,
						closeUpload: onAbortUpload,
						status: EBucketConnectorUploadFileStatus.Uploading,
					})
				}
			}

			for (let i = 0; i < PARTS_COUNT; i++) {
				if (!aborted) {
					const start = i * partSize
					const end = start + partSize

					const command = new UploadPartCommand({
						Bucket: this.bucketName,
						Key: key,
						UploadId: uploadId,
						// @ts-expect-error ждет Buffer от NodeJs, но для наших целей достаточно ArrayBuffer
						Body: buffer.slice(start, end),
						PartNumber: i + 1,
					})

					const presigned = await createRequestPresigned<UploadPartCommandInput, UploadPartCommandOutput>(this.s3Client, command)

					uploadPromises.push(
						trackUploadProgress(presigned, (progress) => onCompletePart(i + 1, progress))
							.then((xhr: XMLHttpRequest) => {
								return { ETag: xhr.getResponseHeader('Etag') }
							})
							.catch(() => undefined)
					)
				}
			}

			const uploadResults = await Promise.all(uploadPromises as Promise<{ ETag: string }>[])

			if (aborted) {
				throw new Error('Cancelled by user')
			}

			return await this.s3Client.send(
				new CompleteMultipartUploadCommand({
					Bucket: this.bucketName,
					Key: key,
					UploadId: uploadId,
					MultipartUpload: {
						Parts: uploadResults.map(({ ETag }, i) => ({
							ETag,
							PartNumber: i + 1,
						})),
					},
				})
			)
		} catch (err) {
			console.error(err)

			if (uploadId) {
				const abortCommand = new AbortMultipartUploadCommand({
					Bucket: this.bucketName,
					Key: key,
					UploadId: uploadId,
				})

				await this.s3Client.send(abortCommand)
			}
		}
	}

	getObject = async (path: string): Promise<IBucketGetFileResult> => {
		const command = new GetObjectCommand({
			Bucket: this.bucketName,
			Key: path,
		})

		try {
			const response = await this.s3Client.send(command)

			return {
				isSuccess: true,
				path,
				file: await response.Body?.transformToByteArray(),
			}
		} catch (error) {
			console.error(error)

			return {
				isSuccess: false,
				path,
				error,
			}
		}
	}

	downloadObject = async (path: string): Promise<IBucketDownloadFileResult> => {
		try {
			const objectResponse = await this.getObject(path)

			if (objectResponse?.file) {
				const blob = new Blob([objectResponse?.file])
				const fileName = path.split('/').at(-1) as string

				downloadFileByBlob(blob, fileName)

				return {
					isSuccess: true,
					path,
					blob,
				}
			} else {
				throw new Error(`Unable to download file by path ${path}`)
			}
		} catch (error) {
			console.error(error)

			return {
				isSuccess: false,
				path,
				error,
			}
		}
	}

	downloadFolder = async (folder: IBucketFileList): Promise<IBucketDownloadFolderResult> => {
		const files = await this.getBucketFiles(this.bucketName, folder.key)

		try {
			const zip = new JSZip()

			const requests = await Promise.all(
				files
					.flat()
					.filter((file) => Boolean(file.key) && !file.key?.includes(HIDDEN_FILE_NAME))
					.map((file) => this.getObject(file.key as string))
			)

			requests.forEach((response) => {
				if (response.isSuccess && response.file) {
					const blob = new Blob([response?.file])
					const fileName = response.path.replace(folder.key as string, `${folder.name}/`)

					zip.file(fileName, blob)
				}
			})

			const zipFolder = await zip.generateAsync({
				type: 'blob',
			})

			downloadFileByBlob(zipFolder, `${folder.name || 'folder'}.zip`)

			return {
				isSuccess: true,
				folder,
				blob: zipFolder,
			}
		} catch (error) {
			console.error(error)

			return {
				isSuccess: false,
				folder,
				error,
			}
		}
	}

	deleteObject = async (path: string): Promise<IBucketSimpleResult> => {
		const command = new DeleteObjectCommand({
			Bucket: this.bucketName,
			Key: path,
		})

		try {
			await this.s3Client.send(command)

			return {
				isSuccess: true,
				path,
			}
		} catch (error) {
			console.error(error)

			return {
				isSuccess: false,
				path,
				error,
			}
		}
	}

	deleteFolder = async (path: string): Promise<IBucketSimpleResult> => {
		const { rawFiles } = await this.getBucketObjectsList(this.bucketName, path)

		const deleteCommand = new DeleteObjectsCommand({
			Bucket: this.bucketName,
			Delete: {
				Objects: rawFiles.map(({ key }) => ({ Key: key })),
				Quiet: false,
			},
		})

		try {
			await this.s3Client.send(deleteCommand)

			return {
				isSuccess: true,
				path,
			}
		} catch (error) {
			console.error(error)

			return {
				isSuccess: false,
				path,
				error,
			}
		}
	}

	objectExist = async (path: string): Promise<IBucketSimpleResult> => {
		const headCommand = new HeadObjectCommand({
			Bucket: this.bucketName,
			Key: path,
		})

		try {
			await this.s3Client.send(headCommand)

			return {
				isSuccess: true,
				path,
			}
		} catch (error) {
			console.error(error)

			return {
				isSuccess: false,
				path,
				error,
			}
		}
	}

	copyFile = async (path: string, newPath: string): Promise<IBucketCopyFileResult> => {
		const command = new CopyObjectCommand({
			CopySource: `${this.bucketName}/${encodeURI(path)}`,
			Bucket: this.bucketName,
			Key: newPath,
		})

		try {
			await this.s3Client.send(command)

			return {
				isSuccess: true,
				path,
				newPath,
			}
		} catch (error) {
			console.error(error)

			return {
				isSuccess: false,
				path,
				error,
				newPath,
			}
		}
	}

	renameFile = async (path: string, newName: string): Promise<IBucketRenameFileResult> => {
		try {
			const copyResult = await this.copyFile(path, path.replace(path.split('/').at(-1) as string, newName))

			if (copyResult.isSuccess) {
				const deleteResult = await this.deleteObject(path)

				if (deleteResult.isSuccess) {
					return {
						isSuccess: true,
						path,
						newName,
					}
				}
			}

			return {
				isSuccess: false,
				path,
				newName,
			}
		} catch (error) {
			console.error(error)

			return {
				isSuccess: false,
				path,
				error,
				newName,
			}
		}
	}

	renameFolder = async (folder: IBucketFileList, newName: string): Promise<IBucketRenameFolderResult> => {
		const { rawFiles } = await this.getBucketObjectsList(this.bucketName, folder.key)

		try {
			await Promise.allSettled(
				rawFiles
					.map(async (file) => {
						const newFileName = (file.key as string).split('/')
						const folderIndex = newFileName.lastIndexOf(folder.name as string)

						newFileName.splice(folderIndex, 1, newName)

						const copyResult = await this.copyFile(file.key as string, newFileName.join('/'))

						if (copyResult.isSuccess) {
							await this.deleteObject(file.key as string)
						}
					})
			)

			return {
				isSuccess: true,
				folder,
				newName,
			}
		} catch (error) {
			console.error(error)

			return {
				isSuccess: false,
				folder,
				error,
				newName,
			}
		}
	}

	createFolder = async (path: string, name: string): Promise<IBucketCreateFolderResult> => {
		const command = new PutObjectCommand({
			Bucket: this.bucketName,
			Key: `${path ? `${path}/` : ''}${name}/${HIDDEN_FILE_NAME}`,
		})

		try {
			const { filesList } = await this.getBucketObjectsList(this.bucketName, path)
			const parentContent = getFolderValueByPath(filesList, path ? path.split('/') : [])?.content

			// @ts-ignore
			if (parentContent && name in parentContent && !parentContent[name].isFile) {
				return {
					isSuccess: false,
					path,
					error: new Error('Folder already exists'),
				}
			}

			await this.s3Client.send(command)

			return {
				isSuccess: true,
				path,
			}
		} catch (error) {
			console.error(error)

			return {
				isSuccess: false,
				path,
				error,
			}
		}
	}
}

export let BucketConnector: BucketConnectorClient | undefined

export const initBucketApi = (userData: IBucketUserData) => {
	BucketConnector = new BucketConnectorClient({
		...userData,
	})

	return BucketConnector
}
