import { Tag as AntdTag } from 'antd'
import classNames from 'classnames'
import React from 'react'

import styles from './styles.module.scss'
import { ITagProps } from './types'

export function Tag({ width, className, ...props }: ITagProps) {
	return (
		<AntdTag
			style={{
				minWidth: width
			}}
			className={classNames(styles.root, {
				[className ?? '']: !!className,
			})}
			{...props}
		/>
	)
}
