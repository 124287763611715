import { IMainLayoutOptions } from './types'

export const MAIN_LAYOUT_DEFAULT_OPTIONS: IMainLayoutOptions = {
	showHeader: true,
	showMenu: true,
	className: '',
	contentClassName: '',
	menuClassName: '',
	headerClassName: '',
}
