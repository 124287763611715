import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCesium } from 'resium'

import { Divider, IconButton, Text } from 'components'

import styles from './styles.module.scss'

export function ZoomControl() {
	const { t } = useTranslation('map')
	const { camera, scene } = useCesium()

	const getRate = useCallback(() => {
		const { ellipsoid } = scene?.globe ?? {}
		if (camera) {
			const cameraHeight = ellipsoid?.cartesianToCartographic(camera?.position).height || 1
			return cameraHeight / 3
		}
	}, [camera, scene?.globe])

	const [zoomLevel, setZoomLevel] = useState<number>(camera ? Math.round(camera.positionCartographic.height) : 0)
	const increaseZoom = useCallback(() => {
		camera?.moveForward(getRate())
	}, [camera, getRate])

	const decreaseZoom = useCallback(() => {
		camera?.moveBackward(getRate())
	}, [camera, getRate])

	useEffect(() => {
		if (camera) {
			const listener = () => {
				setZoomLevel(Math.round(camera.positionCartographic.height))
			}

			camera.changed.addEventListener(listener)

			return () => {
				camera.changed.removeEventListener(listener)
			}
		}
	}, [camera])

	return (
		<div className={styles.root}>
			<IconButton iconSize={24} icon="plus" onClick={increaseZoom} />
			<Divider type='vertical' className={styles.divider} />
			<Text
				type="body16"
				bold
				className={styles.text}
			>
				{zoomLevel < 0 ? t('distance.meters', { distance: 0 }) : zoomLevel < 1000 ? t('distance.meters', { distance: zoomLevel }) : t('distance.kilometers', { distance: (zoomLevel / 1000).toFixed(0) })}
			</Text>
			<Divider type='vertical' className={styles.divider} />
			<IconButton iconSize={24} icon="minus" onClick={decreaseZoom} />
		</div>
	)
}
