import { createContext } from 'react'

import { IHandlerContext } from './types'

export const HandlerContext = createContext<IHandlerContext>({
	footerProps: undefined,
	onFinish: undefined,
	handlerData: undefined,
	project: undefined,
	isEdit: undefined,
	form: undefined,
})
