import { useCallback, useRef, useState } from 'react'

export const useBooleanFlag = (
	initialState?: boolean
): [boolean, (value?: boolean | unknown) => void] => {
	const refFlag = useRef<boolean>(initialState ?? false)
	const [flag, setFlag] = useState<boolean>(initialState ?? false)

	const onChange = useCallback((value?: boolean | unknown) => {
		if (typeof value === 'boolean') {
			setFlag(value)
		} else {
			setFlag(!refFlag.current)
			refFlag.current = !refFlag.current
		}
	}, [])

	return [flag, onChange]
}
