import classNames from 'classnames'
import React, { useMemo } from 'react'

import * as PALETTE from 'assets/theme/variables/palette'
import { Tag } from 'components'
import { HANDLER_EVENT_STATUS_LABELS } from 'features/handlers/constants'
import styles from 'features/handlers/ui/HandlerEventStatusTag/styles.module.scss'
import { IHandlerEventStatusTagProps } from 'features/handlers/ui/HandlerEventStatusTag/types'
import { EHandlerStatus } from 'services/handlers/types'

export function HandlerEventStatusTag(props: IHandlerEventStatusTagProps) {
	const { status } = props

	const color = useMemo(() => {
		switch (status) {
		case EHandlerStatus.New:
			return PALETTE.JET50
		case EHandlerStatus.Pending:
			return PALETTE.SEAL_BROWN50
		case EHandlerStatus.Paused:
			return PALETTE.SEAL_BROWN50
		case EHandlerStatus.Error:
			return PALETTE.POPPY50
		case EHandlerStatus.Running:
			return PALETTE.PRUSSIAN_BLUE50
		case EHandlerStatus.Done:
			return PALETTE.FOREST_GREEN50
		}
	}, [status])

	return (
		<Tag
			color={color}
			className={classNames(styles.root, styles[status])}
		>
			{HANDLER_EVENT_STATUS_LABELS[status]}
		</Tag>
	)
}
