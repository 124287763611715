import { CloseOutlined } from '@ant-design/icons'
import React, { ForwardedRef, forwardRef } from 'react'

import { AntdInput, InputRef } from 'components/index'

import { IInputProps } from './types'

const CLEAR = {
	clearIcon: <CloseOutlined />,
}

const { Search, Password, Group, TextArea } = AntdInput

export const Input = forwardRef(function InputComponent(props: IInputProps, ref: ForwardedRef<InputRef>) {
	const { allowClear = CLEAR, ...rest } = props

	return (
		<AntdInput
			ref={ref}
			{...rest}
			allowClear={allowClear}
		/>
	)
})

export { Search, Password, Group, TextArea }
