import { Form } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { EFormTypes, IFormScheme } from 'components/FormGenerator/types'
import { HandlerStepBase } from 'features/handlers/blocks/HandlerStepBase'
import { HANDLER_STEPS_ORDER } from 'features/handlers/constants'
import { useHandlerContext } from 'features/handlers/hooks'
import { EHandlerForestZones, EHandlerSteps } from 'services/handlers/types'

import { SEMANTIC_CLASSIFICATION_STEP_INITIAL_VALUES } from './constants'
import { Parameters } from './Parameters'
import { ISemanticClassificationStepFormValues } from './types'

const FORM_NAME = 'handlerSemanticClassificationStep'

export function HandlerSemanticClassificationStep() {
	const { t } = useTranslation('handlers')
	const { onFinish, handlerData } = useHandlerContext()
	const { form } = useHandlerContext()
	const forestZone = Form.useWatch('forestZone', form) as EHandlerForestZones | undefined

	const values = useMemo(() => ({
		...SEMANTIC_CLASSIFICATION_STEP_INITIAL_VALUES,
		...handlerData?.config?.semanticClassification,
	}), [handlerData])

	const onValuesChangeCallback = useCallback((value: Partial<ISemanticClassificationStepFormValues>) => {
		if (value && Object.keys(value).includes('forestZone')) {
			form?.resetFields(['forestRegion'])
		}
	}, [form])

	const scheme = useMemo<IFormScheme[]>(() => [
		{
			name: '',
			type: EFormTypes.Custom,
			component: <Parameters forestZone={forestZone} />,
		},
	], [forestZone])

	const onFinishCallback = useCallback((values: ISemanticClassificationStepFormValues) => {
		onFinish?.({
			semanticClassification: {
				...values,
			},
		})
	}, [onFinish])

	return (
		<HandlerStepBase<ISemanticClassificationStepFormValues>
			stepTitle={t(`stepsTitles.${EHandlerSteps.SemanticClassification}`)}
			hint={t(`hints.${EHandlerSteps.SemanticClassification}`)}
			step={HANDLER_STEPS_ORDER.indexOf(EHandlerSteps.SemanticClassification) + 1}
			scheme={scheme}
			formName={FORM_NAME}
			onFinish={onFinishCallback}
			values={values}
			onValuesChange={onValuesChangeCallback}
		/>
	)
}
