import * as PALETTE from 'assets/theme/variables/palette'

export const TEXT_COLOR = PALETTE.EERIEBLACK
export const PLACEHOLDER_COLOR = PALETTE.BLUE700

export const PRIMARY_COLOR = PALETTE.PINE_GREEN500
export const PRIMARY_COLOR_HOVER = PALETTE.PINE_GREEN300
export const PRIMARY_COLOR_ACTIVE = PALETTE.PINE_GREEN600

export const DISABLED_COLOR = PALETTE.PLATINUM100
export const DISABLED_TEXT_COLOR = PALETTE.PLATINUM500
export const SUCCESS_COLOR = PALETTE.PINE_GREEN500
export const ERROR_COLOR = PALETTE.POPPY500
export const WARNING_COLOR = PALETTE.FULVOUS500

export const BORDER_COLOR = PALETTE.PLATINUM300

export const BUTTON_PRIMARY_COLOR = PALETTE.GRAY650

export const MAIN_BG_COLOR = PALETTE.WHITE
export const PAGE_BG_COLOR = PALETTE.PLATINUM100

// Цвета для классов точек обработчика
export const HANDLER_POINTS_CLASSES_CREATED_COLOR = '#939393'
export const HANDLER_POINTS_CLASSES_NOT_CLASSIFIED_COLOR = '#CFCFCF'
export const HANDLER_POINTS_CLASSES_GROUND_COLOR = '#783F06'
export const HANDLER_POINTS_CLASSES_TALL_VEGETATION_COLOR = '#08FB30'
export const HANDLER_POINTS_CLASSES_BUILDING_COLOR = '#3D85C6'
export const HANDLER_POINTS_CLASSES_LOWER_POINT_COLOR = '#FF2905'
export const HANDLER_POINTS_CLASSES_HIGHER_POINT_COLOR = '#FF2905'
