import { FunnelIcon, PlusCircleIcon } from '@heroicons/react/24/outline'
import React, { JSX, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useBooleanFlag } from 'common/hooks/boolean'
import { useForm } from 'common/hooks/form'
import { normalizeObject } from 'common/utils/normalization'
import { Badge, Button, Col, Divider, Form, FormGenerator, Row, Space } from 'components'
import { PopoverDialog } from 'components/PopoverDialog'
import styles from 'components/Table/styles.module.scss'
import { ITableActionPanelsProps } from 'components/Table/types'
import { IDefaultCollectionRequestParams } from 'services/types'

export function TableActionPanel<FilterContent>(props: ITableActionPanelsProps<FilterContent>): JSX.Element {
	const { t } = useTranslation(['actions', 'labels'])
	const [showForm, setShowForm] = useBooleanFlag()
	const [form] = useForm([])

	const {
		addButtonTitle = t('Add'),
		onAdd,
		showAddButton = true,
		scheme,
		onFiltersChange,
		filters,
	} = props

	const showDot = useMemo(() => {
		const { limit, skip, ...rest } = filters as IDefaultCollectionRequestParams || {}

		return Boolean(Object.keys(normalizeObject(rest)).length)
	}, [filters])

	const onFinishCallback = useCallback((values: FilterContent) => {
		onFiltersChange?.(values)
	}, [onFiltersChange])

	const popupContent = useMemo(() => {
		return (
			<Form
				form={form}
				layout='vertical'
				onFinish={onFinishCallback}
			>
				<FormGenerator scheme={scheme!} />
				<Divider />
				<Space>
					<Button
						htmlType='reset'
						type='secondary'
					>
						{t('Clear')}
					</Button>
					<Button
						htmlType='submit'
						type='primary'
					>
						{t('Apply')}
					</Button>
				</Space>
			</Form>
		)
	}, [form, onFinishCallback, scheme, t])

	return (
		<Row justify="space-between">
			<Col hidden={!showAddButton}>
				<Button
					type="primary"
					onClick={onAdd}
					icon={<PlusCircleIcon width={24} />}
				>
					{addButtonTitle}
				</Button>
			</Col>
			<Col hidden={!scheme}>
				<PopoverDialog
					title={t('Filtering')}
					content={popupContent}
					open={showForm}
					onOpenChange={setShowForm}
					overlayClassName={styles.tableActionPanel}
				>
					<Button
						isActive={showForm}
						type="secondary"
						icon={
							<Badge
								dot={showDot}
								status='warning'
								dotSize={6}
							>
								<FunnelIcon width={24} />
							</Badge>
						}
					/>
				</PopoverDialog>
			</Col>
		</Row>
	)
}
