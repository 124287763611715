import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FormGenerator } from 'components'
import { EFormTypes, IFormScheme } from 'components/FormGenerator/types'
import { HandlerStepBlock } from 'features/handlers/blocks/HandlerStepBlock'

export function Simulation() {
	const { t } = useTranslation('handlers')

	const scheme = useMemo<IFormScheme[]>(() => [
		{
			isVertical: true,
			name: 'lasModellingRadius',
			type: EFormTypes.Number,
			label: <span>{t('Search radius (m)')}</span>,
			required: true,
			inputProps: {
				size: 'small',
			},
		},
		{
			isVertical: true,
			name: 'minHeight',
			type: EFormTypes.Number,
			label: <span>{t('Minimum height (m)')}</span>,
			required: true,
			inputProps: {
				size: 'small',
			},
		},
		{
			isVertical: true,
			name: 'maxDiameter',
			type: EFormTypes.Number,
			label: <span>{t('Maximum crown diameter (m)')}</span>,
			required: true,
			inputProps: {
				size: 'small',
			},
		},
	], [t])

	return (
		<HandlerStepBlock title={t('Simulation (point cloud)')}>
			<FormGenerator scheme={scheme} />
		</HandlerStepBlock>
	)
}
