import { createAsyncThunk } from '@reduxjs/toolkit'

import { BucketConnector, initBucketApi } from 'features/data/libs/BucketConnector'
import { IBucketUserData } from 'features/data/libs/BucketConnector/types'

export const initDataSlice = createAsyncThunk(
	'data/init',
	async (userData: IBucketUserData) => {
		const BucketConnector = initBucketApi(userData)

		return await BucketConnector.updateBucketContents()
	}
)

export const updateDataSlice = createAsyncThunk(
	'data/update',
	async () => {
		return BucketConnector?.updateBucketContents()
	}
)
