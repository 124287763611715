import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { formattingLabels2Options } from 'common/utils/formatting'
import { FormGenerator } from 'components'
import { EFormTypes, IFormScheme } from 'components/FormGenerator/types'
import { HandlerStepBlock } from 'features/handlers/blocks/HandlerStepBlock'
import {
	HANDLER_SCALE_LABELS,
	HANDLER_SETTING_MODES_LABELS
} from 'features/handlers/constants'

import styles from './styles.module.scss'

export function Settings() {
	const { t } = useTranslation('handlers')

	const scheme = useMemo<IFormScheme[]>(() => [
		{
			name: 'settingsMode',
			type: EFormTypes.Radio,
			required: true,
			inputProps: {
				className: styles.settingsRadio,
				options: formattingLabels2Options(HANDLER_SETTING_MODES_LABELS),
			}
		},
		{
			name: 'targetScale',
			label: t('Target scale'),
			type: EFormTypes.Select,
			inputProps: {
				allowClear: false,
				options: formattingLabels2Options(HANDLER_SCALE_LABELS),
			}
		}
	], [t])

	return (
		<HandlerStepBlock title={t('Settings')}>
			<FormGenerator scheme={scheme} />
		</HandlerStepBlock>
	)
}
