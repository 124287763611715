import Keycloak from 'keycloak-js'

const TOKEN_MIN_VALIDATY = 60
export const keycloak = new Keycloak({
	url: process.env.REACT_APP_KEYCLOAK_URL,
	realm: process.env.REACT_APP_KEYCLOAK_REALM as string,
	clientId: process.env.REACT_APP_KEYCLOAK_CLIENT as string,
})

export const updateKeycloakToken = async (minValidity= TOKEN_MIN_VALIDATY) => {
	try {
		return await keycloak.updateToken(minValidity)
	} catch (error) {
		keycloak.onAuthRefreshError?.()
		return false
	}
}

keycloak.onReady = () => {
	console.log('Keycloak is ready')
}

keycloak.onAuthError = (error: unknown) => {
	console.log('Keycloak auth error', error)
}

keycloak.onAuthSuccess = () => {
	console.log('Keycloak auth success')
}

keycloak.onAuthRefreshSuccess = () => {
	console.log('Keycloak auth refresh success')
}

keycloak.onAuthLogout = () => {
	console.log('Keycloak auth logout')
}

keycloak.onActionUpdate = (state: unknown) => {
	console.log('Keycloak action update', state)
}

keycloak.onAuthLogout = () => {
	location.reload()
}

keycloak.onTokenExpired = async () => {
	console.log('Keycloak token expired')
	await updateKeycloakToken(-1)
}

keycloak.onAuthRefreshError = () => {
	console.log('Keycloak auth refresh error')
	keycloak.onAuthLogout?.()
}

export const initialization = keycloak.init({
	...keycloak,
	pkceMethod: 'S256',
})

export const login = async () => {
	await initialization

	if (keycloak.authenticated) {
		setInterval(() => {
			void updateKeycloakToken(TOKEN_MIN_VALIDATY)
		}, 0.9 * TOKEN_MIN_VALIDATY * 1000)
		return keycloak
	}

	await keycloak.login()

	return keycloak
}

export const logout = async () => {
	await initialization
	return keycloak.logout()
}

export const isAuthenticated = async () => {
	await initialization
	return keycloak.authenticated
}

export const getAccessToken = () => keycloak.token

export const getUserInfo = async () => {
	await initialization
	return keycloak.loadUserProfile()
}
