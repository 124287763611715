import { InputRef } from 'antd'
import React, { useCallback, useLayoutEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { Input } from 'components'
import { IInputProps } from 'components/Input/types'

import styles from '../styles.module.scss'
import { ITableFieldFilterProps } from '../types'

import { TableFilter } from './TableFilter'

export function TableTextFilter(props: ITableFieldFilterProps<IInputProps>) {
	const { t } = useTranslation('placeholders')
	const { filterProps, getFocusCallback } = props
	const searchInput = useRef<InputRef>(null)

	const onFocusCallback = useCallback(() => {
		setTimeout(() => searchInput?.current?.select())
	}, [])

	useLayoutEffect(() => {
		onFocusCallback()
		getFocusCallback?.(onFocusCallback)
	}, [getFocusCallback, onFocusCallback])

	return (
		<TableFilter {...props}>
			{({ onSearch, onChange, value }) => (
				<Input
					placeholder={t('Enter text')}
					{...filterProps}
					ref={searchInput}
					className={styles.filterInput}
					value={value}
					onChange={onChange}
					onPressEnter={onSearch}
				/>
			)}
		</TableFilter>
	)
}
