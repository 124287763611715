export const WHITE = 'white'
export const BLACK = 'black'

// Grays
export const GRAY50 = '#F9F9F9'
export const GRAY100 = '#E6E7E8'
export const GRAY150 = '#EAEAEA'
export const GRAY200 = '#CDCED1'
export const GRAY300 = '#B4B6BA'
export const GRAY400 = '#9B9DA3'
export const GRAY500 = '#82848B'
export const GRAY600 = '#6A6C74'
export const GRAY650 = '#6D6D6D'
export const GRAY700 = '#51545D'
export const GRAY800 = '#383B46'
export const GRAY900 = '#060A18'

export const PLATINUM100 = '#F5F7F8'
export const PLATINUM200 = '#ECEFF1'
export const PLATINUM300 = '#E2E7EA'
export const PLATINUM400 = '#D9DFE3'
export const PLATINUM500 = '#CFD7DC'
export const PLATINUM600 = '#B1BABF'
export const PLATINUM700 = '#929CA2'
export const PLATINUM800 = '#747F86'
export const PLATINUM900 = '#556169'

export const JET50 = '#F1F0F0'
export const JET800 = '#32302B'

export const DEVYS_GRAY300 = '#8C9193'
export const DEVYS_GRAY500 = '#4F5759'
export const DEVYS_GRAY700 = '#2F3435'

// Blue
export const BLUE50 = '#F9FBFC'
export const BLUE100 = '#F3F6F9'
export const BLUE200 = '#E7EDF4'
export const BLUE300 = '#DCE5EE'
export const BLUE500 = '#C4D3E3'
export const BLUE600 = '#A5B5C7'
export const BLUE700 = '#8698AB'
export const BLUE800 = '#677A8E'

export const AZURE50 = '#EBF2FD'
export const AZURE100 = '#D7E5FB'
export const AZURE200 = '#AECBF8'
export const AZURE300 = '#86B1F4'
export const AZURE400 = '#5D97F1'
export const AZURE500 = '#357DED'

export const PRUSSIAN_BLUE50 = '#D2E4EF'
export const PRUSSIAN_BLUE800 = '#193247'

export const ARGENTINIAN_BLUE500 = '#5EADF6'

// Red
export const POPPY50 = '#FCE8EB'
export const POPPY100 = '#F9D2D7'
export const POPPY200 = '#F3A5AE'
export const POPPY300 = '#ED7786'
export const POPPY400 = '#E74A5D'
export const POPPY500 = '#E11D35'

// Green
export const FOREST_GREEN50 = '#DEF2E2'
export const FOREST_GREEN100 = '#1D3829'
export const PIGMENT_GREEN = '#41A956'

export const PINE_GREEN25 = '#B3FAF2'
export const PINE_GREEN50 = '#A2EDE5'
export const PINE_GREEN100 = '#91E1D8'
export const PINE_GREEN300 = '#4DAEA3'
export const PINE_GREEN500 = '#097C6F'
export const PINE_GREEN600 = '#076359'
export const PINE_GREEN700 = '#054A43'

export const VERDIGRIS25 = '#F9FBFB'
export const VERDIGRIS50 = '#F0F5F6'
export const VERDIGRIS100 = '#E1ECEC'
export const VERDIGRIS300 = '#A5C5C7'
export const VERDIGRIS500 = '#699FA1'
export const VERDIGRIS600 = '#547F81'
export const VERDIGRIS700 = '#3F5F61'

// Brown
export const SEAL_BROWN50 = '#F9DEC9'
export const SEAL_BROWN800 = '#49280E'

export const FULVOUS500 = '#DD8627'
export const EERIEBLACK = '#262626'
