import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useBooleanFlag } from 'common/hooks/boolean'
import { useForm } from 'common/hooks/form'
import { Button, Form, FormGenerator, Modal, Row, Space, message } from 'components'
import { IFormScheme } from 'components/FormGenerator/types'

import { IRenameModalProps } from './types'

const FORM_NAME = 'rename-object'
const STATE = [] as unknown[]

export function RenameModal(props: IRenameModalProps) {
	const { t } = useTranslation('storage')
	const [loading, setLoading] = useBooleanFlag()
	const { open, onCancel, name, onSave } = props
	const values = useMemo(() => ({ name }), [name])

	const [form] = useForm(STATE, {
		values,
	})

	const scheme = useMemo<IFormScheme[]>(() => [
		{
			label: t('Name'),
			name: 'name',
			required: true,
			inputProps: {
				autoFocus: true,
				placeholder: t('Enter text')
			},
		},
	], [t])

	const onFinishCallback = useCallback(async (values: { name: string }) => {
		setLoading(true)

		try {
			const { isSuccess, error } = await onSave(values.name)

			if (isSuccess) {
				onCancel()
			} else {
				void message.error(t((error as Error)?.message || error || 'An error occurred during renaming'))
			}
		} catch (error) {
			void message.error(t((error as Error)?.message || error || 'An error occurred during renaming'))
		} finally {
			setLoading(false)
		}
	}, [onCancel, onSave, setLoading, t])

	return (
		<Modal
			centered
			destroyOnClose
			open={open}
			onCancel={onCancel}
			width={500}
			footer={(
				<Row justify='end'>
					<Space>
						<Button
							htmlType='submit'
							form={FORM_NAME}
							type='primary'
							loading={loading}
						>
							{t('Save')}
						</Button>
					</Space>
				</Row>
			)}
		>
			<Form
				form={form}
				layout='vertical'
				name={FORM_NAME}
				onFinish={onFinishCallback}
			>
				<FormGenerator scheme={scheme} />
			</Form>
		</Modal>
	)
}
