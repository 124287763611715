import { EllipsisVerticalIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { ROUTES_ENTITIES } from 'app/router/constants'
import { ESpacer } from 'assets/theme/variables/spacers'
import { stopEventPropagation } from 'common/utils/event'
import { formattingFullDate } from 'common/utils/formatting'
import { Button, Card, Col, Divider, Dropdown, Row, Space, Spin, Text, Tooltip } from 'components'
import { NotificationsPopover } from 'features/notifications/blocks/NotificationsPopover'

import { DEFAULT_PROJECT_PREVIEW } from './constants'
import styles from './styles.module.scss'
import { IProjectItemProps } from './types'

export function ProjectItem(props: IProjectItemProps) {
	const { t } = useTranslation(['actions', 'labels'])
	const { item, loading, onEdit, onDelete } = props
	const { name, preview, runningHandlers, createdAt, notifications, id } = item
	const navigate = useNavigate()

	const onOpenRenameModalCallback = useCallback(() => {
		onEdit(item)
	}, [onEdit, item])

	const onDeleteCallback = useCallback(() => {
		onDelete(item)
	}, [onDelete, item])

	const onClickProjectCallback = useCallback(() => {
		navigate(ROUTES_ENTITIES.getProjectPage(id))
	}, [navigate, id])

	const menu = useMemo(() => ({
		items: [
			{
				key: 'rename',
				icon: <PencilIcon width={18} />,
				label: <Text>{t('Rename')}</Text>,
				onClick: onOpenRenameModalCallback,
			},
			{
				key: 'delete',
				icon: (
					<Text typographyType='danger'>
						<TrashIcon width={18} />
					</Text>
				),
				label: <Text typographyType='danger'>{t('Remove')}</Text>,
				onClick: onDeleteCallback,
			},
		]
	}), [onOpenRenameModalCallback, onDeleteCallback, t])

	return (
		<Card
			className={styles.root}
			onClick={onClickProjectCallback}
			cover={
				<>
					<img
						className={styles.preview}
						alt='preview'
						src={preview || DEFAULT_PROJECT_PREVIEW}
					/>
					<div onClick={stopEventPropagation}>
						<Dropdown trigger={['click']} menu={menu}>
							<Button
								size='small'
								className={styles.menuButton}
								icon={<EllipsisVerticalIcon width={20} />}
							/>
						</Dropdown>
					</div>
				</>
			}
			loading={loading}
		>
			<div className={styles.title}>
				<Text title={name} bold type='body16'>{name}</Text>
			</div>
			<Divider className={styles.divider} />
			<Row
				align='middle'
				justify='space-between'
				gutter={[ESpacer.SPACER3, ESpacer.SPACER3]}
			>
				<Col>
					<Space direction='vertical' size={ESpacer.SPACER2}>
						<Text
							typographyType='secondary'
							ellipsis
							type='body12'
						>
							{t('Create date')}
						</Text>
						<Text ellipsis className={styles.dateTag} type='body12'>
							{formattingFullDate(createdAt)}
						</Text>
					</Space>
				</Col>
				<Col>
					<Space className={styles.actions} align='center' size={ESpacer.SPACER7}>
						{Boolean(notifications?.length) && <NotificationsPopover notificationsIds={notifications} />}
						{Boolean(runningHandlers) && (
							<Tooltip title={t('Processing')}>
								<div className={classNames(styles.iconBlock)}>
									<Spin className={styles.spin} spinning />
									<Text
										typographyType='secondary'
										ellipsis
										type='body12'
									>
										{runningHandlers}
									</Text>
								</div>
							</Tooltip>
						)}
					</Space>
				</Col>
			</Row>
		</Card>
	)
}
