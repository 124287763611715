import React from 'react'

import { List } from 'components'
import { NotificationItem } from 'features/notifications/blocks/NotificationItem'

import styles from './styles.module.scss'
import { INotificationsListProps } from './types'

export function NotificationsList(props: INotificationsListProps) {
	const { notificationsIds } = props

	return (
		<List
			className={styles.root}
			bordered={false}
			dataSource={notificationsIds}
			renderItem={(item: string) => (
				<List.Item>
					<NotificationItem id={item} />
				</List.Item>
			)}
		/>
	)
}
