import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import MapWidget from 'widgets/Map'

import { ROUTES, ROUTES_ENTITIES } from 'app/router/constants'
import { useAppDispatch } from 'app/store/hooks'
import { useBooleanFlag } from 'common/hooks/boolean'
import { useForm } from 'common/hooks/form'
import { useMutationNotifications } from 'common/hooks/notifications'
import { useParamsByUrl } from 'common/hooks/url'
import { stopEventPropagation } from 'common/utils/event'
import { Col, Content, Row, Spin, confirmDelete } from 'components'
import { HandlerClassificationStep } from 'features/handlers/blocks/HandlerClassificationStep'
import { HandlerHeightsStep } from 'features/handlers/blocks/HandlerHeightsStep'
import { HandlerLasSegmentationStep } from 'features/handlers/blocks/HandlerLasSegmentationStep'
import { HandlerPrimaryStep } from 'features/handlers/blocks/HandlerPrimaryStep'
import { HandlerSemanticClassificationStep } from 'features/handlers/blocks/HandlerSemanticClassificationStep'
import { HANDLER_STEPS_ORDER } from 'features/handlers/constants'
import { HandlerContext } from 'features/handlers/context'
import { handlersSlice } from 'features/handlers/store'
import { useHandlerStoreData } from 'features/handlers/store/hooks'
import { IHandlerStepFooterProps } from 'features/handlers/ui/HandlerStepFooter/types'
import { downloadHandlerData, getHandlerActionType, getHandlerStepNumber } from 'features/handlers/utils'
import { useMainLayoutContext } from 'features/layouts/blocks/MainLayout/hooks'
import { useProject, useSetProjectHeader } from 'features/projects/store/hooks'
import { IHandlerPageUrlParams } from 'pages/Handler/types'
import { handlersApi } from 'services/handlers'
import { EHandlerActionTypes, EHandlerStatus, EHandlerSteps, IHandleConfig, IHandler } from 'services/handlers/types'
import { IUseMutationStateResult } from 'services/types'



import styles from './styles.module.scss'

const {
	useGetHandlerQuery,
	useStartHandlerMutation,
	useUpdateHandlerMutation,
	useDeleteHandlerMutation,
} = handlersApi
const { actions } = handlersSlice

export default function Handler() {
	const { setHeaderContent } = useMainLayoutContext()

	const [pageParams, setPageParams] = useParamsByUrl<IHandlerPageUrlParams>()
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const { id } = useParams()
	const { t } = useTranslation('handlers')
	const { currentStep, primaryData } = useHandlerStoreData()
	const [handlerPageType, setHandlerPageType] = useState<EHandlerActionTypes>(EHandlerActionTypes.Create)
	const isFormEditable = useMemo(() => [
		EHandlerActionTypes.Create,
		EHandlerActionTypes.Change,
		EHandlerActionTypes.NextStepChange
	].includes(handlerPageType), [handlerPageType])
	const [loading, setLoading] = useBooleanFlag()
	const [handlerStatus, setHandlerStatus] = useState<EHandlerStatus>()

	const handlerState = useGetHandlerQuery({ id: id as string }, {
		skip: !id,
		pollingInterval: handlerStatus === EHandlerStatus.Running ? 3000 : undefined,
	})

	const projectId = handlerState?.data?.projectId?.toString() || primaryData?.projectId

	useSetProjectHeader(projectId, setHeaderContent)

	const { data: project } = useProject(projectId)

	const [changeHandler, changeHandlerState] = useMutationNotifications(useUpdateHandlerMutation, {
		errorText: t('An error occurred while changing the handler'),
		successText: t('Handler successfully changed'),
	})

	const [startHandler, startHandlerState] = useMutationNotifications(useStartHandlerMutation, {
		errorText: t('An error occurred while starting the handler'),
		successText: t('Handler successfully started'),
	})

	const [deleteHandler, deleteHandlerState] = useMutationNotifications(useDeleteHandlerMutation, {
		errorText: t('An error occurred while deleting the handler'),
		successText: t('Handler deleted successfully'),
	})

	const handlerStates = useMemo(() =>
		[changeHandlerState, startHandlerState, deleteHandlerState],
	[changeHandlerState, startHandlerState, deleteHandlerState]
	)

	const [form] = useForm(handlerStates)

	const updatePageTypeByHandler = useCallback((argHandler?: IHandler) => {
		if (argHandler || handlerState?.data) {
			setHandlerPageType(getHandlerActionType(argHandler || handlerState?.data))
		}
	}, [handlerState?.data])

	useLayoutEffect(() => {
		if (!id && !currentStep) {
			navigate(ROUTES.Projects)
		}
	}, [id, currentStep, navigate])

	useEffect(() => {
		if (handlerState?.data?.status && handlerState.data.status !== handlerStatus) {
			setHandlerStatus(handlerState.data.status)

			if (handlerStatus === EHandlerStatus.Running) {
				updatePageTypeByHandler()
			}
		}

	}, [handlerState?.data?.status]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (Object.keys(pageParams || {}).length) {
			if (pageParams.pageType) {
				setHandlerPageType(pageParams.pageType)
			}

			if (pageParams.step) {
				dispatch(actions.setStep(pageParams.step))
			}

			setPageParams({})
		}
	}, [pageParams, dispatch, setPageParams])

	useEffect(() => {
		if (!pageParams?.pageType && handlerState?.data?.status && handlerPageType === EHandlerActionTypes.Create) {
			updatePageTypeByHandler()
		}
	}, [handlerState?.data?.status, handlerPageType, pageParams?.pageType, updatePageTypeByHandler])

	useEffect(() => {
		if (!pageParams?.step && !currentStep && handlerState?.data?.step) {
			dispatch(actions.setStep(handlerState.data.step))
		}
	}, [handlerState.data, currentStep, dispatch, pageParams?.step])

	useEffect(() => {
		return () => {
			dispatch(actions.setStep(undefined))
		}
	}, [dispatch])

	useEffect(() => {
		if (handlerPageType === EHandlerActionTypes.SendToMap) {
			// todo отправлять данные на карту
			updatePageTypeByHandler()
		}
	}, [handlerPageType, updatePageTypeByHandler])

	const onStartCallback = useCallback(async (handler?: IHandler) => {
		if (handler || handlerState?.data) {
			const handlerRequest = await startHandler({
				...(handler || handlerState?.data),
			}) as IUseMutationStateResult<IHandler>

			if (handlerRequest.data?.status) {
				setHandlerPageType(EHandlerActionTypes.Stop)
			}
		}
	}, [startHandler, handlerState?.data])

	const footerProps = useMemo<IHandlerStepFooterProps>(() => ({
		type: handlerPageType,
		actions: {
			onCancel: () => {
				if (handlerPageType === EHandlerActionTypes.Change) {
					updatePageTypeByHandler()
				} else if (handlerPageType === EHandlerActionTypes.Create) {
					confirmDelete({
						title: t('The created handler will be deleted, continue?'),
						onOk: () => {
							navigate(primaryData?.projectId ? ROUTES_ENTITIES.getProjectPage(primaryData?.projectId) : ROUTES.Projects)
						},
						okText: t('actions:Continue'),
					})
				}
			},
			onChange: () => setHandlerPageType(EHandlerActionTypes.Change),
			onStart: () => onStartCallback(),
			onDelete: () => {
				confirmDelete({
					title: t('Are you sure you want to delete the handler?', { name: handlerState?.data?.title }),
					onOk: async () => {
						const result = await deleteHandler({
							id: handlerState?.data?.id,
						})

						if (!('error' in result)) {
							navigate(handlerState?.data?.projectId ? ROUTES_ENTITIES.getProjectPage(handlerState.data.projectId.toString()) : ROUTES.Projects)
						}
					},
				})
			},
			onNextStep: () => {
				if (handlerState.data) {
					const currentStep = getHandlerStepNumber(handlerState.data.step)
					const nextStep = HANDLER_STEPS_ORDER[currentStep + 1]

					if (nextStep) {
						setHandlerPageType(EHandlerActionTypes.NextStepChange)
						dispatch(actions.setStep(nextStep))
					}
				}
			},
			onDownload: async (e) => {
				stopEventPropagation(e)

				if (project?.name && handlerState?.data?.title) {
					setLoading(true)

					try {
						await downloadHandlerData(handlerState.data.title, project.name)
					} finally {
						setLoading(false)
					}
				}
			},
		},
	}), [
		handlerPageType,
		handlerState.data,
		t,
		navigate,
		primaryData?.projectId,
		onStartCallback,
		deleteHandler,
		dispatch,
		project?.name,
		setLoading,
		updatePageTypeByHandler,
	])

	const StepComponent = useMemo(() => {
		switch (currentStep) {
		case EHandlerSteps.Preprocessing:
			return <HandlerPrimaryStep />
		case EHandlerSteps.Classification:
			return <HandlerClassificationStep />
		case EHandlerSteps.Heights:
			return <HandlerHeightsStep />
		case EHandlerSteps.LasSegmentation:
			return <HandlerLasSegmentationStep />
		case EHandlerSteps.SemanticClassification:
			return <HandlerSemanticClassificationStep />
		}
	}, [currentStep])

	const onFinishCallback = useCallback(async (values: Partial<IHandleConfig> & { detail?: unknown }) => {
		const { detail, ...handlerConfig } = values

		switch (handlerPageType) {
		case EHandlerActionTypes.NextStepChange:
		case EHandlerActionTypes.Change: {
			if (handlerState.data?.projectId) {
				const handlerRequest = await changeHandler({
					id,
					projectId: handlerState.data.projectId,
					step: currentStep,
					config: {
						...handlerConfig,
					},
					...primaryData,
				}) as IUseMutationStateResult<IHandler>

				if (handlerRequest?.data) {
					if (handlerPageType === EHandlerActionTypes.Change) {
						updatePageTypeByHandler(handlerRequest.data)
					}

					if (handlerPageType === EHandlerActionTypes.NextStepChange) {
						void onStartCallback({
							...handlerRequest?.data,
							projectId: handlerState.data.projectId,
						})
					}
				}
			}
			break
		}
		}
	}, [
		primaryData,
		handlerPageType,
		currentStep,
		changeHandler,
		handlerState?.data,
		id,
		onStartCallback,
		updatePageTypeByHandler,
	])

	const context = useMemo(() => ({
		footerProps,
		onFinish: onFinishCallback,
		handlerData: handlerState?.data,
		project,
		isEdit: isFormEditable,
		form,
	}), [
		project,
		footerProps,
		onFinishCallback,
		handlerState?.data,
		isFormEditable,
		form,
	])

	return (
		<Content
			isFullWidth
			isFullHeight
			className={styles.content}
		>
			<HandlerContext.Provider value={context}>
				<Spin spinning={handlerState.isFetching}>
					<Row wrap={false} className={styles.root}>
						<Col className={styles.dataBlock}>
							<Spin spinning={loading}>
								{StepComponent}
							</Spin>
						</Col>
						<Col flex={1}>
							<MapWidget />
						</Col>
					</Row>
				</Spin>
			</HandlerContext.Provider>
		</Content>
	)
}
