import classNames from 'classnames'
import React from 'react'

import { Button, Icon } from '../index'

import styles from './styles.module.scss'
import { IIconButtonProps } from './types'

export function IconButton(props: IIconButtonProps) {
	const { icon, iconSize = 24, isText, type, ...rest } = props

	return (
		<Button
			{...rest}
			type={isText? 'text' : type}
			className={classNames(styles.root, {
				[styles.textOnly]: isText
			})
			}>
			{typeof icon === 'string' ? <Icon size={iconSize} icon={icon} /> : icon}
		</Button>
	)
}
