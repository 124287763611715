import React from 'react'

import { AntdCol, AntdColProps } from 'components/index'

export function Col(props: AntdColProps & { hidden?: boolean }) {
	const { hidden } = props

	if (hidden) {
		return null
	}

	return <AntdCol {...props} />
}
