import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import { useEffect } from 'react'

import { getErrorText, isCommonServiceError } from 'common/utils/errors'
import { message } from 'components'
import { IUseMutationStateResult, TDefaultMutation } from 'services/types'

export const useMutationNotifications = <
  T extends TDefaultMutation = TDefaultMutation,
  R = unknown
>(
		mutation: Partial<T>,
		options?: {
    errorText?: string
    successText?: string
  }
	): [ReturnType<T>[0], IUseMutationStateResult<R>] => {
	const [trigger, triggerState] = (mutation as unknown as TDefaultMutation)()

	useEffect(() => {
		const { isError, isSuccess, isLoading } = triggerState
		const error = triggerState.error as FetchBaseQueryError

		if (!isLoading) {
			if (
				isError
        && !isCommonServiceError('originalStatus' in error ? error?.originalStatus : error?.status)
			) {
				void message.error(getErrorText(error, options?.errorText))
			}

			if (isSuccess && options?.successText) {
				void message.success(options.successText)
			}
		}
	}, [
		options?.errorText,
		options?.successText,
		triggerState,
	])

	return [trigger, triggerState] as [ReturnType<T>[0], IUseMutationStateResult<R>]
}
