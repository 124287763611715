import { Rule } from 'rc-field-form/lib/interface'

import { i18n } from 'app/localization'
import { formattingCountString } from 'common/utils/formatting'

const { t } = i18n
export interface IValidateMaskInputProps {
  normalizeFunction(value: string): string
  value: string
  message: string
  len?: number
}

export const validateByRegexp = (regexp: string) => {
	const regexpObject = new RegExp(regexp)

	return {
		validator: (_: unknown, value: string) => {
			if (!value || regexpObject.test(value)) {
				return Promise.resolve()
			}

			return Promise.reject(new Error(t('errors:validation:Field format is incorrect')))
		},
	}
}

export const validateMaskInput = (validateObject: IValidateMaskInputProps) => {
	const { len, normalizeFunction, value, message } = validateObject

	if (!value || value.length === 0) {
		return Promise.resolve()
	}

	const item = normalizeFunction(value)

	if (len && item.length && len > item.length) {
		return Promise.reject(message)
	}

	return Promise.resolve()
}

export const getValidateMoreThenNSymbols = (n: number) => (_: undefined, value: string) => {
	const text = value?.trimStart()

	if (!value?.length || text?.length < n) {
		return Promise.reject(
			formattingCountString(n, [
				t('errors:validation:text-lenght_one', { count: n }),
				t('errors:validation:text-lenght_max', { count: n }),
				t('errors:validation:text-lenght_max', { count: n }),
			])
		)
	}

	return Promise.resolve()
}

export const onlyLettersRule = (_: undefined, value: string) => {
	if (!value || /^[a-zA-Zа-яёЁА-Я'`\-\s]+$/g.test(value)) {
		return Promise.resolve()
	}

	return Promise.reject(new Error(t('errors:validation:Only letters and hyphens')))
}

export const onlyLettersAndNumbersRule = (_: undefined, value: string) => {
	if (!value || /^[a-zA-Zа-яёЁА-Я0-9'`\-\s]+$/g.test(value)) {
		return Promise.resolve()
	}

	return Promise.reject(new Error(t('errors:validation:Only letters, hyphens, and numbers')))
}

export const urlRule: Rule = {
	type: 'url',
	message: t('errors:validation:The entered URL is invalid'),
}

export const emailRules: Rule[] = [
	{
		required: true,
		message: t('errors:validation:Enter your email'),
	},
	{
		type: 'email',
		message: t('errors:validation:Invalid email entered'),
	},
]

