import React, { useContext } from 'react'

import { Layer } from 'features/map/blocks/Layer'
import { MapContext } from 'features/map/context'

export function Layers() {
	const { map } = useContext(MapContext)

	return (
		<>
			{map?.layers.map((layer) => (
				<Layer key={layer.id} layer={layer} />
			))}
		</>
	)
}
