import { createSlice } from '@reduxjs/toolkit'

import { initDataSlice, updateDataSlice } from './actions'
import { IDataStore } from './types'

const initialState: IDataStore = {
	fileList: {},
}

export const dataSlice = createSlice({
	name: 'dataSlice',
	initialState: {
		...initialState,
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(initDataSlice.fulfilled, (state, { payload }) => {
				state.fileList = payload.filesList
			})
			.addCase(updateDataSlice.fulfilled, (state, { payload }) => {
				state.fileList = payload?.filesList || initialState.fileList
			})
	}
})
