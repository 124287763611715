import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import { createApi } from '@reduxjs/toolkit/query/react'

import { EServiceTags } from 'services/types'
import { injectTokenHeader } from 'services/utils'

export const api = createApi({
	reducerPath: 'api',
	baseQuery: fetchBaseQuery({
		baseUrl: '/',
		prepareHeaders: injectTokenHeader,
	}),
	tagTypes: [
		EServiceTags.Unauthorized,
		EServiceTags.UnknownError,
		EServiceTags.Projects,
		EServiceTags.Notifications,
		EServiceTags.Handlers,
		EServiceTags.HandlersLasFilesHeaders,
		EServiceTags.Layers,
		EServiceTags.Maps,
	],
	endpoints: () => ({}),
})
