import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FormGenerator } from 'components'
import { EFormTypes, IFormScheme } from 'components/FormGenerator/types'
import { HandlerStepBlock } from 'features/handlers/blocks/HandlerStepBlock'

export function LocalMax() {
	const { t } = useTranslation('handlers')

	const scheme = useMemo<IFormScheme[]>(() => [
		{
			isVertical: true,
			name: 'localMaxWindow',
			type: EFormTypes.Number,
			label: <span>{t('Search window (m)')}</span>,
			required: true,
			inputProps: {
				size: 'small',
			},
		},
		{
			isVertical: true,
			name: 'localMaxMinHeight',
			type: EFormTypes.Number,
			label: <span>{t('Minimum tree height (m)')}</span>,
			required: true,
			inputProps: {
				size: 'small',
			},
		},
		{
			isVertical: true,
			name: 'localMaxAxDiameter',
			type: EFormTypes.Number,
			label: <span>{t('Maximum crown diameter (m)')}</span>,
			required: true,
			inputProps: {
				size: 'small',
			},
		},
	], [t])

	return (
		<HandlerStepBlock title={t('Based on local maxima (height map)')}>
			<FormGenerator scheme={scheme} />
		</HandlerStepBlock>
	)
}
