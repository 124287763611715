import {
	EHandlerSegmentationAlgorithms,
	EHandlerSegmentationSeasons,
	EHandlerSegmentationSources,
	EHandlerSettingModes
} from 'services/handlers/types'

export const LAS_SEGMENTATION_STEP_INITIAL_VALUES = {
	settingsMode: EHandlerSettingModes.Auto,
	segmentationAlgorithm: EHandlerSegmentationAlgorithms.Watershed,
	season: EHandlerSegmentationSeasons.Summer,
	source: EHandlerSegmentationSources.LAS,
	localMaxWindow: 3,
	localMaxMinHeight: 5,
	localMaxAxDiameter: 15,
	lasModellingRadius: 2,
	minHeight: 5,
}
