import { ChecksumAlgorithm, ObjectStorageClass, Owner, RestoreStatus } from '@aws-sdk/client-s3'

import { RcFile } from 'components'

export enum EBucketConnectorUploadFileStatus {
  Pending = 'pending',
  Uploading = 'uploading',
  Cancelled = 'cancelled',
  Failed = 'failed',
  Success = 'success'
}

export interface IBucketUserData {
  secretAccess: string
  accessKey: string
  bucket: string
  endpoint: string
}

export interface IBucketConnectorOptions extends IBucketUserData {
  region?: string
  endpoint: string
  bucket: string
}

export interface IBucketObjectCommon {
  name?: string
  isFile?: boolean
}

export type TBucketFile = IBucketObjectCommon & IBucketObject

export interface IBucketFileList extends IBucketObjectCommon {
  [key: string]: TBucketFile | IBucketFileList | number | boolean | string | undefined
  content?: TBucketFile | IBucketFileList
  key?: string
  size?: number
}

export interface IBucketData {
  rawFiles: IBucketObject[]
  filesList: IBucketFileList
}

export interface IBucketUploadSimpleProgress {
  progress: number,
  closeUpload?(): void
  status: EBucketConnectorUploadFileStatus
}

export interface IBucketUploadProgress extends IBucketUploadSimpleProgress {
  file: RcFile
}

export type TBucketUploadSimpleProgressCallback = (data: IBucketUploadSimpleProgress) => void
export type TBucketUploadProgressCallback = (data: IBucketUploadProgress) => void

export interface IBucketUploadFileResult {
  isSuccess: boolean
  file: RcFile
  error?: unknown
}

export interface IBucketGetFileResult {
  isSuccess: boolean
  path: string
  file?: Uint8Array
  error?: unknown
}

export interface IBucketDownloadFileResult {
  isSuccess: boolean
  path: string
  blob?: Blob
  error?: unknown
}

export interface IBucketDownloadFolderResult {
  isSuccess: boolean
  folder: IBucketFileList
  blob?: Blob
  error?: unknown
}

export interface IBucketSimpleResult {
  isSuccess: boolean
  path: string
  error?: unknown
}

export interface IBucketCopyFileResult {
  isSuccess: boolean
  path: string
  newPath?: string
  error?: unknown
}

export interface IBucketRenameFileResult {
  isSuccess: boolean
  path: string
  newName?: string
  error?: unknown
}

export interface IBucketRenameFolderResult {
  isSuccess: boolean
  folder: IBucketFileList
  newName?: string
  error?: unknown
}

export interface IBucketCreateFolderResult {
  isSuccess: boolean
  path: string
  error?: unknown
}

export interface IBucketObject {
  /**
   * @public
   * <p>The name that you assign to an object. You use the object key to retrieve the
   *          object.</p>
   */
  key?: string;
  /**
   * @public
   * <p>Creation date of the object.</p>
   */
  lastModified?: Date | string;
  /**
   * @public
   * <p>The entity tag is a hash of the object. The ETag reflects changes only to the contents
   *          of an object, not its metadata. The ETag may or may not be an MD5 digest of the object
   *          data. Whether or not it is depends on how the object was created and how it is encrypted as
   *          described below:</p>
   *          <ul>
   *             <li>
   *                <p>Objects created by the PUT Object, POST Object, or Copy operation, or through the
   *                Amazon Web Services Management Console, and are encrypted by SSE-S3 or plaintext, have ETags that
   *                are an MD5 digest of their object data.</p>
   *             </li>
   *             <li>
   *                <p>Objects created by the PUT Object, POST Object, or Copy operation, or through the
   *                Amazon Web Services Management Console, and are encrypted by SSE-C or SSE-KMS, have ETags that are
   *                not an MD5 digest of their object data.</p>
   *             </li>
   *             <li>
   *                <p>If an object is created by either the Multipart Upload or Part Copy operation, the
   *                ETag is not an MD5 digest, regardless of the method of encryption. If an object is
   *                larger than 16 MB, the Amazon Web Services Management Console will upload or copy that object as a
   *                Multipart Upload, and therefore the ETag will not be an MD5 digest.</p>
   *             </li>
   *          </ul>
   */
  eTag?: string;
  /**
   * @public
   * <p>The algorithm that was used to create a checksum of the object.</p>
   */
  checksumAlgorithm?: (ChecksumAlgorithm | string)[];
  /**
   * @public
   * <p>Size in bytes of the object</p>
   */
  size?: number;
  /**
   * @public
   * <p>The class of storage used to store the object.</p>
   */
  storageClass?: ObjectStorageClass | string;
  /**
   * @public
   * <p>The owner of the object</p>
   */
  owner?: Owner;
  /**
   * @public
   * <p>Specifies the restoration status of an object. Objects in certain storage classes must be restored
   *          before they can be retrieved. For more information about these storage classes and how to work with
   *          archived objects, see <a href="https://docs.aws.amazon.com/AmazonS3/latest/userguide/archived-objects.html">
   *             Working with archived objects</a> in the <i>Amazon S3 User Guide</i>.</p>
   */
  restoreStatus?: RestoreStatus;
}
