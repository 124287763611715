import { api } from 'services'
import { cacheByIdArgProperty, providesList } from 'services/rtkQueryCacheUtils'
import { EServiceTags, IDefaultCollection } from 'services/types'
import { transformRequest, transformResponse } from 'services/utils'

import {
	ICreateHandlerParams,
	IDeleteHandlerParams,
	IGetHandlerParams,
	IGetHandlersByProjectParams,
	IGetLasFilesHeadersParams,
	IHandler,
	ILasFilesHeaders,
	IStartHandlerParams,
	IUpdateHandlerParams
} from './types'

export const handlersApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getHandlersByProject: builder.query<IDefaultCollection<IHandler>, IGetHandlersByProjectParams>({
			query: ({ projectId, ...params }) => ({
				url: `/api/v1/handlers/by_project/${projectId}`,
				params: transformRequest(params),
			}),
			transformResponse,
			providesTags: (result: IDefaultCollection<IHandler> | undefined, error) => providesList(EServiceTags.Handlers)(
				result,
				error
			),
		}),
		getHandler: builder.query<IHandler, IGetHandlerParams>({
			query: ({ id }) => ({
				url: `/api/v1/handlers/${id}`,
			}),
			transformResponse,
			providesTags: (result, error, args: IGetHandlerParams) => cacheByIdArgProperty(EServiceTags.Handlers)(result, error, {
				id: args.id,
			}),
		}),
		createHandler: builder.mutation<IHandler, ICreateHandlerParams>({
			query: ({ projectId, ...body }) => ({
				url: '/api/v1/handlers/',
				method: 'POST',
				params: transformRequest({ projectId }),
				body: transformRequest(body),
			}),
			invalidatesTags: [EServiceTags.Handlers],
		}),
		updateHandler: builder.mutation<IHandler, IUpdateHandlerParams>({
			query: ({ id, ...body }) => ({
				url: `/api/v1/handlers/${id}`,
				method: 'PATCH',
				body: transformRequest(body),
			}),
			invalidatesTags: [EServiceTags.Handlers],
		}),
		deleteHandler: builder.mutation<void, IDeleteHandlerParams>({
			query: ({ id }) => ({
				url: `/api/v1/handlers/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: [EServiceTags.Handlers],
		}),
		startHandler: builder.mutation<IHandler, IStartHandlerParams>({
			query: ({ projectId, ...body }) => ({
				url: `/api/v1/handlers/${body.id}/start`,
				method: 'POST',
				params: transformRequest({ projectId }),
				body: transformRequest(body),
			}),
			invalidatesTags: [EServiceTags.Handlers],
		}),
		getLasFilesHeaders: builder.query<ILasFilesHeaders, IGetLasFilesHeadersParams>({
			query: ({ handlerName, projectName }) => ({
				url: `/api/v1/handlers/${projectName}/${handlerName}`,
			}),
			transformResponse,
			providesTags: (result, error, args: IGetLasFilesHeadersParams) => cacheByIdArgProperty(EServiceTags.HandlersLasFilesHeaders)(result, error, {
				id: `${args.handlerName}_${args.projectName}`,
			}),
		}),
	}),
	overrideExisting: false,
})
