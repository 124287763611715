import { useCallback, useMemo, useState } from 'react'

import { DEFAULT_TABLE_PARAMS } from 'common/constants/client'
import { transformToArrayIfNeedIt } from 'common/utils/array'
import { normalizeObject } from 'common/utils/normalization'
import {
	IFiltersContent,
	ISortValue,
	ITableFilters,
	TTableOnChange,
} from 'components/Table/types'
import { limitSkipToPagination, paginationToLimitSkip } from 'components/Table/utils'
import { IDefaultCollectionRequestParams } from 'services/types'

export const useTableFilters = <
    Content extends object
>(): ITableFilters<Content> => {
	const [filters, onFiltersChange]
		= useState<Partial<IFiltersContent<Content>>>({})

	const onTableChange = useCallback<TTableOnChange<Content>>(
		(pagination = {
			pageSize: DEFAULT_TABLE_PARAMS.limit,
			current: 1,
		}, filters, sorter) => {
			let sort: ISortValue[] | undefined = undefined

			// todo sorter after server realization
			// TODO пока что сортировка такая, потому что уже нужна в filelist
			if (Array.isArray(sorter) || sorter.field) {
				sort = transformToArrayIfNeedIt(sorter)
					.map(({ columnKey, order }) => [columnKey, order] as ISortValue)
					.filter((item) => item[0] && item[1])
			}

			const content = normalizeObject({
				...filters,
				sort,
			})

			onFiltersChange(state => ({
				...state,
				...paginationToLimitSkip(pagination),
				...content,
			}))
		},
		[onFiltersChange]
	)

	const onFilterChangeCallback = useCallback((values: Partial<IFiltersContent<Content>>) => {
		onFiltersChange({
			...values,
			limit: DEFAULT_TABLE_PARAMS.limit,
			skip: DEFAULT_TABLE_PARAMS.skip,
		})
	}, [onFiltersChange])

	const onReset = useCallback(() => {
		onFiltersChange({})
	}, [])

	const pagination = useMemo(() => limitSkipToPagination(filters as IDefaultCollectionRequestParams), [filters])

	return {
		filters,
		pagination,
		onFiltersChange: onFilterChangeCallback,
		onTableChange,
		onReset,
	}
}
