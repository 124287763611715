import React, { useMemo } from 'react'

import * as COLORS from 'assets/theme/variables/colors'
import * as PALETTE from 'assets/theme/variables/palette'
import { Progress } from 'components/index'

import styles from './styles.module.scss'
import { IProgressBarProps } from './types'

export function ProgressBar(props: IProgressBarProps) {
	const { progress, type = 'progress', width = 240 } = props

	const size = useMemo<[number, number]>(() => [width, 4], [width])

	const color = useMemo(() => {
		switch (type) {
		case 'success':
			return COLORS.SUCCESS_COLOR

		case 'failure':
			return COLORS.ERROR_COLOR

		default:
			return PALETTE.ARGENTINIAN_BLUE500
		}
	}, [type])

	return (
		<Progress
			percent={progress}
			strokeColor={color}
			trailColor={PALETTE.BLUE200}
			size={size}
			showInfo={false}
			className={styles.progressbar}
		/>
	)
}
