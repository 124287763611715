import React, { useContext } from 'react'

import { LayerSwitcherListItem } from 'features/map/blocks/Instruments/LayerSwitcher/LayerSwitcherListItem'
import { MapContext } from 'features/map/context'

import styles from './styles.module.scss'

export function LayerSwitcherList() {
	const { map } = useContext(MapContext)

	return (
		<div className={styles.listContainer}>
			{map?.layers.map((layer) => (
				<LayerSwitcherListItem key={layer.id} layer={layer} />
			))}
		</div>
	)
}
