import { ChevronDownIcon, ChevronUpIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { TablePaginationConfig } from 'antd'
import classNames from 'classnames'
import React from 'react'

import { ESpacer } from 'assets/theme/variables/spacers'
import { DEFAULT_TABLE_PARAMS } from 'common/constants/client'
import { FilterValue } from 'components'
import { IInputProps } from 'components/Input/types'
import { IDefaultCollectionRequestParams } from 'services/types'

import styles from './styles.module.scss'
import { IFiltersContent, ITableColumn } from './types'
import { TableTextFilter } from './ui/TableTextFilter'

export const paginationToLimitSkip = (pagination: TablePaginationConfig): Partial<IDefaultCollectionRequestParams> => ({
	limit: pagination.pageSize,
	skip: ((pagination.current ?? 1) - 1) * (pagination.pageSize ?? 0),
})
export const limitSkipToPagination = (values: IDefaultCollectionRequestParams): TablePaginationConfig => ({
	current: (values?.skip ?? DEFAULT_TABLE_PARAMS.skip) / (values?.limit ?? DEFAULT_TABLE_PARAMS.limit) + 1,
	pageSize: values?.limit ?? DEFAULT_TABLE_PARAMS.limit,
})

export function configurationFiltersForColumns<RecordType extends object>(
	columns: ITableColumn<RecordType>[],
	rawFilters?: Partial<IFiltersContent<RecordType>>
) {
	const { sort, ...filters } = rawFilters ?? {}

	const sortMap =
		sort?.reduce<{ [key: string | number]: 'ascend' | 'descend' }>(
			(reducer, [key, type]: [string, 'ascend' | 'descend']) => {
				reducer[key] = type
				return reducer
			},
			{}
		) ?? {}

	return columns.reduce<ITableColumn<RecordType>[]>((acc, { className, render, ...column }) => {
		const columnData: ITableColumn<RecordType> = {
			...column,
		}

		if (filters) {
			columnData.filteredValue = Object.entries(filters as { [key: string]: FilterValue }).reduce<FilterValue>(
				(acc: FilterValue, [key, filter]: [string, FilterValue]) => {
					if (key === (column?.key ?? column?.dataIndex)) {
						return filter ? Array.isArray(filter) ? filter : [filter] : []
					}

					return acc
				},
				[]
			)
		}

		columnData.sortOrder = sortMap[(column.key ?? column.dataIndex ?? 0)?.toString()] ?? null

		columnData.sortIcon = ({ sortOrder }) => (
			<span className='ant-table-column-sorter ant-table-column-sorter-full'>
				<span className='ant-table-column-sorter-inner'>
					<ChevronUpIcon
						width={12}
						className={classNames('ant-table-column-sorter-up', { active: sortOrder === 'ascend' })}
					/>
					<ChevronDownIcon
						width={12}
						className={classNames('ant-table-column-sorter-down', { active: sortOrder === 'descend' })}
					/>
				</span>
			</span>
		)

		if (columnData?.filterType) {
			const commonFilterProps = {
				dataIndex: columnData.dataIndex,
				className: column.filterClassName,
				getFocusCallback: (focusCallbackFn: () => void) => {
					columnData.focusInputCallback = focusCallbackFn
				},
			}

			switch (columnData?.filterType) {
			// eslint-disable-next-line no-fallthrough
			default: {
				columnData.filterDropdown = (props) => (
					<TableTextFilter
						{...props}
						{...commonFilterProps}
						filterProps={column.filterProps as IInputProps}
					/>
				)
				columnData.filterIcon = <MagnifyingGlassIcon width={16} />
			}
			}
		}

		const width =
			typeof column?.width === 'number' ? column.width - ESpacer.SPACER5 * 2 : column?.width

		columnData.render = (...args) => (
			<div
				className={classNames(className, {
					[styles.ellipsis]: column.ellipsis,
				})}
				style={{
					maxWidth: width,
					width,
				}}
			>
				{render ? render(...args) : args?.[0]}
			</div>
		)

		return [...acc, columnData]
	}, [])
}

export const getTableColumnKey = <DataSource,>(column: ITableColumn<DataSource>): string | number => column.key
	|| (Array.isArray(column?.dataIndex) ? column.dataIndex.join() : (column?.dataIndex as string))

export const getSortResultWithDirection = (rawResult: number | boolean, direction: 'ascend' | 'descend') => {
	const result = typeof rawResult === 'number'? rawResult : rawResult ? -1 : 1

	return direction === 'ascend'? result : -result
}
