import { Cartesian3, Color, HeightReference, LabelStyle } from 'cesium'

import { getBlueColor } from 'features/map/utils'

export const LABEL_PROPS = {
	fillColor: Color.WHITE,
	outlineColor: Color.BLACK,
	outlineWidth: 2,
	font: '8px',
	style: LabelStyle.FILL_AND_OUTLINE,
}
export const POINT_PROPS = {
	color: getBlueColor(0.25),
	outlineColor: getBlueColor(1),
	outlineWidth: 2,
	pixelSize: 8,
	heightReference: HeightReference.CLAMP_TO_GROUND,
}
export const POLYGONE_PROPS = {
	perPositionHeight: true,
	material: getBlueColor(0.25),
	outline: true,
	outlineColor: getBlueColor(1),
}
export const POLYLINE_PROPS = {
	width: 2,
	material: getBlueColor(1),
}
