import classNames from 'classnames'
import React from 'react'

import { Badge, Button, Space, Text } from 'components/index'

import styles from './styles.module.scss'
import { IMenuButtonProps } from './types'

export function MenuButton(props: IMenuButtonProps) {
	const { icon, children, disabled, isActive, onClick, ghost, withBadge, badgeType = 'warning', ...buttonProps } = props

	return (
		<Space
			direction='vertical'
			size='small'
			className={classNames(styles.root, {
				[styles.disabled]: disabled,
				[styles.active]: isActive,
				[styles.ghost]: ghost,
			})}
			onClick={onClick}
		>
			<Badge
				dot={withBadge}
				status={badgeType}
				dotSize={12}
			>
				<Button
					{...buttonProps}
					className={styles.button}
					icon={icon}
				/>
			</Badge>
			<Text type='body14'>{children}</Text>
		</Space>
	)
}
