import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FormGenerator } from 'components'
import { EFormTypes, IFormScheme } from 'components/FormGenerator/types'
import {
	SEMANTIC_CLASSIFICATION_FOREST_REGIONS,
	SEMANTIC_CLASSIFICATION_FOREST_ZONES,
} from 'features/handlers/blocks/HandlerSemanticClassificationStep/constants'
import { HandlerStepBlock } from 'features/handlers/blocks/HandlerStepBlock'
import { EHandlerForestZones } from 'services/handlers/types'

import { ISemanticClassificationParametersProps } from './types'

export function Parameters(props: ISemanticClassificationParametersProps) {
	const { forestZone } = props
	const { t } = useTranslation('handlers')

	const scheme = useMemo<IFormScheme[]>(() => [
		{
			name: 'forestZone',
			label: t('Forest zone'),
			type: EFormTypes.Select,
			helpText: t('hints.forestZone'),
			inputProps: {
				options: SEMANTIC_CLASSIFICATION_FOREST_ZONES.map((zone) => ({
					label: t(`forestZones:${zone}`),
					value: zone,
				}))
			},
		},
		{
			name: 'forestRegion',
			label: t('Forest region'),
			type: EFormTypes.Select,
			helpText: t('hints.forestRegion'),
			inputProps: {
				options: (SEMANTIC_CLASSIFICATION_FOREST_REGIONS[forestZone as EHandlerForestZones] || []).map((zone) => ({
					label: t(`forestRegions:${zone}`),
					value: zone,
				}))
			},
		},
		{
			name: 'step',
			label: t('Grid pitch, m'),
			type: EFormTypes.Number,
		},
	], [t, forestZone])

	return (
		<HandlerStepBlock title={t('Parameters')}>
			<FormGenerator scheme={scheme} />
		</HandlerStepBlock>
	)
}
