import { JSX, ReactNode } from 'react'

import {
	RadioGroupProps,
	Rule,
	SelectProps,
	TextAreaProps,
	UploadChangeParam,
	UploadFile,
} from 'components'
import { IDatePickerProps } from 'components/DatePicker/types'
import { IInputProps } from 'components/Input/types'
import { IInputMaskProps } from 'components/InputMask/types'
import { IInputNumberProps } from 'components/InputNumber/types'
import { ISwitchProps } from 'components/Switch/types'

export interface IFormGeneratorViewable {
  isEdit?: boolean
  noInline?: boolean
}

export interface IFormGeneratorProps {
  scheme: IFormScheme[]
  viewable?: IFormGeneratorViewable
  className?: string
  disabled?: boolean
}

export enum EFormTypes {
  Default = 'string',
  Custom = 'custom',
  Select = 'select',
  Radio = 'radio',
  String = 'string',
  Number = 'number',
  Textarea = 'textarea',
  Password = 'password',
  Date = 'date',
  Checker = 'checker',
  Switch = 'switch',
  Boolean = 'boolean',
  Mask = 'mask',
  Divider = 'divider',
  Title = 'title',
  Document = 'document',
  Description = 'description',
  InfoDescription = 'infoDescription',
  Error = 'error',
  DateRangePicker = 'dateRangePicker'
}

export interface IFormScheme {
  name: string | (string | number)[]
  label?: string | ReactNode
  helpText?: string
  className?: string
  required?: boolean
  isCanBeFalse?: boolean
  mask?: string
  regexp?: string
  rules?: Rule[]
  type?: EFormTypes
  component?: ReactNode | (() => JSX.Element)
  inputProps?:
  | IDatePickerProps
  | IInputProps
  | IInputMaskProps
  | TextAreaProps
  | SelectProps
  | RadioGroupProps
  | ISwitchProps
  | IInputNumberProps
  dependencies?: string[]
  shouldShow?(values: Record<never, unknown>): boolean
  valuePropName?: string
  getValueFromEvent?(e: UploadChangeParam): UploadFile[]
  formatViewValue?(value: unknown): string | number | ReactNode | undefined
  isVertical?: boolean
}

export type TFormScroll = ScrollOptions & ScrollIntoViewOptions
