import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { Link } from 'react-router-dom'

import { Button } from 'components'

import styles from './styles.module.scss'
import { IBackToButtonProps } from './types'

export function BackToButton(props: IBackToButtonProps) {
	const { to, pageName } = props

	return (
		<Link to={to} className={styles.root}>
			<Button type='tertiary' icon={<ArrowLeftIcon width={20} />}>
				{pageName}
			</Button>
		</Link>	
	)
}
