import { ChevronRightIcon, XMarkIcon } from '@heroicons/react/24/outline'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { ROUTES_ENTITIES } from 'app/router/constants'
import { formattingDateCalendar } from 'common/utils/formatting'
import { Row, Space, Spin, Text } from 'components'
import { HandlerEventStatusTag } from 'features/handlers/ui/HandlerEventStatusTag'
import { notificationsApi } from 'services/notifications'
import { INotification } from 'services/notifications/types'

import styles from './styles.module.scss'
import { INotificationItemProps } from './types'

const { useGetNotificationQuery } = notificationsApi

export function NotificationItem(props: INotificationItemProps) {
	const { id } = props
	const { data, isLoading, isFetching } = useGetNotificationQuery({ id })
	const { title, status, description, createdAt, handlerId } = useMemo(() => data || {} as INotification, [data])

	if (!data && !(isLoading || isFetching)) {
		return null
	}

	return (
		<Link className={styles.root} to={ROUTES_ENTITIES.getHandlerPage(handlerId)}>
			<Spin isFullWidth spinning={isLoading || isFetching}>
				<Space
					isFullWidth
					direction='vertical'
					size='small'
				>
					<Row justify='space-between' align='middle'>
						<HandlerEventStatusTag status={status} />
						<XMarkIcon width={20} className={styles.deleteIcon} />
					</Row>
					<Text type='body12' bold>
						{title}
					</Text>
					<Text type='body12'>
						{description}
					</Text>
					<Row className={styles.bottom} justify='space-between' align='middle'>
						<Text type='body10' typographyType='secondary'>{formattingDateCalendar(createdAt)}</Text>
						<ChevronRightIcon width={20} className={styles.rightIcon} />
					</Row>
				</Space>
			</Spin>
		</Link>
	)
}
