import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import {
	ELayoutsModalEntities,
	ILayoutsStore,
	IModalOpenActionPayload,
	IModalSetDataActionPayload,
	TModalClearDataActionPayload,
	TModalOpenActionPayload,
} from 'features/layouts/store/types'

const initialState: ILayoutsStore = {
	modals: {
		[ELayoutsModalEntities.FileList]: undefined,
		[ELayoutsModalEntities.UploadList]: {
			data: {},
		},
	},
}

export const layoutsSlice = createSlice({
	name: 'layoutsSlice',
	initialState: {
		...initialState,
	},
	reducers: {
		openModal(state: ILayoutsStore, action: PayloadAction<IModalOpenActionPayload>) {
			const { data, entity, clearDataAfterClose } = action.payload

			state.modals[entity] = {
				open: true,
				data,
				clearDataAfterClose,
			}
		},
		setModalData(state: ILayoutsStore, action: PayloadAction<IModalSetDataActionPayload>) {
			const { data, entity } = action.payload

			state.modals[entity] = {
				...state.modals[entity],
				data,
			}
		},
		clearModalData(state: ILayoutsStore, action: PayloadAction<TModalClearDataActionPayload>) {
			const { entity } = action.payload

			state.modals[entity] = {
				data: undefined,
			}
		},
		closeModal(state: ILayoutsStore, action: PayloadAction<TModalOpenActionPayload>) {
			const { entity } = action.payload

			state.modals[entity] = {
				open: false,
				data: state.modals[entity]?.clearDataAfterClose ? undefined : state.modals[entity]?.data,
			}
		},
	},
})
