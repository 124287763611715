import { i18n } from 'app/localization'
import { EHandlerSteps } from 'services/handlers/types'
const { t } = i18n

const NS = 'handlers:dataRequirements'

export const HANDLER_DATA_RULES: {
	[key in EHandlerSteps]: {
		nextStepHeader?: string
		standaloneHeader?: string
		nextStepRules?: string[]
		standaloneRules?: string[]
	}
} = {
	[EHandlerSteps.Preprocessing]: {
		nextStepRules: t(`${NS}.${EHandlerSteps.Preprocessing}.rules`, { returnObjects: true })
	},
	[EHandlerSteps.Classification]: {
		nextStepHeader: t(`${NS}.When creating as next step`, { stepName: t(`handlers:stepsLabels.${EHandlerSteps.Classification}`) }),
		standaloneHeader: t(`${NS}.When creating standalone`, { stepName: t(`handlers:stepsLabels.${EHandlerSteps.Classification}`) }),
		nextStepRules: t(`${NS}.${EHandlerSteps.Preprocessing}.rules`, { returnObjects: true }),
		standaloneRules: t(`${NS}.${EHandlerSteps.Classification}.rules`, { returnObjects: true }),
	},
	[EHandlerSteps.Heights]: {
		nextStepHeader: t(`${NS}.When creating as next step`, { stepName: t(`handlers:stepsLabels.${EHandlerSteps.Heights}`) }),
		standaloneHeader: t(`${NS}.When creating standalone`, { stepName: t(`handlers:stepsLabels.${EHandlerSteps.Heights}`) }),
		nextStepRules: [
			t(`${NS}.nextStepEntry`, { stepEntry: t(`${NS}.${EHandlerSteps.Heights}.stepEntry`) }),
			...t(`${NS}.${EHandlerSteps.Heights}.rules`, { returnObjects: true })
		],
		standaloneRules: [
			...t(`${NS}.${EHandlerSteps.Classification}.rules`, { returnObjects: true }),
			...t(`${NS}.${EHandlerSteps.Heights}.rules`, { returnObjects: true })
		],
	},
	[EHandlerSteps.LasSegmentation]: {
		nextStepHeader: t(`${NS}.When creating as next step`, { stepName: t(`handlers:stepsLabels.${EHandlerSteps.LasSegmentation}`) }),
		standaloneHeader: t(`${NS}.When creating standalone`, { stepName: t(`handlers:stepsLabels.${EHandlerSteps.LasSegmentation}`) }),
		nextStepRules: [
			t(`${NS}.nextStepEntry`, { stepEntry: t(`${NS}.${EHandlerSteps.LasSegmentation}.stepEntry`) }),
			...t(`${NS}.${EHandlerSteps.Heights}.rules`, { returnObjects: true })
		],
		standaloneRules: [
			...t(`${NS}.${EHandlerSteps.Classification}.rules`, { returnObjects: true }),
			...t(`${NS}.${EHandlerSteps.Heights}.rules`, { returnObjects: true }),
			...t(`${NS}.${EHandlerSteps.LasSegmentation}.rules`, { returnObjects: true }),
		],
	},
	[EHandlerSteps.SemanticClassification]: {
		nextStepHeader: t(`${NS}.When creating as next step`, { stepName: t(`handlers:stepsLabels.${EHandlerSteps.SemanticClassification}`) }),
		standaloneHeader: t(`${NS}.When creating standalone`, { stepName: t(`handlers:stepsLabels.${EHandlerSteps.SemanticClassification}`) }),
		nextStepRules: [t(`${NS}.nextStepEntry`, { stepEntry: t(`${NS}.${EHandlerSteps.SemanticClassification}.stepEntry`) })],
		standaloneRules: t(`${NS}.${EHandlerSteps.SemanticClassification}.rules`, { returnObjects: true }),
	}
}
