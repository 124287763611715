import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import utc from 'dayjs/plugin/utc'
import Keycloak from 'keycloak-js'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { getAntdLocale } from 'app/localization'
import { store } from 'app/store/store'
import THEME from 'assets/theme/antd'
import { AntStaticFunctions, App, ConfigProvider, ErrorBoundary } from 'components'
import { CredentialsProvider } from 'features/credentials/blocks/CredentialsProvider'
import { initDataSlice } from 'features/data/store/actions'
import { MainLayout } from 'features/layouts/blocks/MainLayout'
import 'assets/theme/styles/index.scss'

dayjs.extend(utc)
dayjs.extend(calendar)
dayjs.locale('ru')

function Index() {
	const { i18n } = useTranslation()
	const locale = useMemo(() => getAntdLocale(i18n.language), [i18n])

	const onLoginCallback = useCallback((keycloak?: Keycloak) => {
		const {
			S3_SECRET_KEY,
			S3_ACCESS_KEY,
			S3_BUCKET,
			S3_ENDPOINT
		} = keycloak?.tokenParsed ?? {}

		if (S3_SECRET_KEY && S3_ACCESS_KEY && S3_BUCKET && S3_ENDPOINT) {
			store.dispatch(initDataSlice({
				secretAccess: S3_SECRET_KEY,
				accessKey: S3_ACCESS_KEY,
				bucket: S3_BUCKET,
				endpoint: S3_ENDPOINT,
			}))
		}
	}, [])

	return (
		<Provider store={store}>
			<ConfigProvider locale={locale} theme={THEME}>
				<App>
					<BrowserRouter>
						<ErrorBoundary>
							<CredentialsProvider onLogin={onLoginCallback}>
								<MainLayout />
							</CredentialsProvider>
						</ErrorBoundary>
					</BrowserRouter>
					<AntStaticFunctions />
				</App>
			</ConfigProvider>
		</Provider>
	)
}

export default Index
