import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { AntdIcon } from 'components/index'

import { IIconProps } from './types'

export function Icon(props: IIconProps) {
	const { icon, size = 14, ...iconProps } = props
	const [component, setComponent] = useState()

	const setIconComponentCallback = useCallback(async (icon: string) => {
		try {
			const localeFile = (await import(`!!@svgr/webpack?-svgo,+titleProp,+ref!../../assets/icons/${icon}.svg`))

			if (!localeFile?.default) {
				throw new Error(`Icon ${icon} not found`)
			}

			setComponent(localeFile.default)
		} catch (e) {
			console.error('Error in Icon component: ', e)
		}
	}, [])

	useEffect(() => {
		void setIconComponentCallback(icon)
	}, [icon, setIconComponentCallback])

	const style = useMemo(() => ({
		fontSize: size,
	}), [size])

	return (
		<AntdIcon
			{...iconProps}
			component={component}
			style={style}
		/>
	)
}
