import classNames from 'classnames'
import React, { useMemo } from 'react'

import { AntdBreadcrumb, Tooltip } from 'components/index'

import styles from './styles.module.scss'
import { IBreadcrumbItemProps, IBreadcrumbProps } from './types'

export function Breadcrumb(props: IBreadcrumbProps) {
	const { items: propsItems, className, ...rest } = props

	const items = useMemo<IBreadcrumbItemProps[]>(() => {
		const items = propsItems?.map((item) => ({
			...item,
			title: (
				<Tooltip title={item.title}>
					<a href={item.href}>
						{item.title}
					</a>
				</Tooltip>
			),
		}))

		if (items && items?.length >= 5) {
			const explicitItems = items?.filter((item, index) => index > 1 && index < items?.length - 2)

			return [
				items[0],
				items[1],
				{
					title: '...',
					key: 'submenu',
					menu: {
						items: explicitItems.map((item) => ({
							title: item.title,
							key: item.key,
							onClick: item.onClick,
						})),
					},
					dropdownProps: {
						placement: 'top',
						trigger: ['click', 'hover'],
					},
				},
				items[items?.length - 2],
				items[items?.length - 1]
			]
		}

		return items
	}, [propsItems])

	return (
		<AntdBreadcrumb
			{...rest}
			className={classNames(styles.root, className)}
			items={items}
		/>
	)
}

Breadcrumb.Item = AntdBreadcrumb.Item
Breadcrumb.Separator = AntdBreadcrumb.Separator
