const NOT_AUTHORIZED = 401
const TOO_MANY_REQUESTS = 429
const INTERNAL_SERVER_ERROR = 500

export enum EHttpCodes {
	NotAuthorized = NOT_AUTHORIZED,
	TooManyRequests = TOO_MANY_REQUESTS,
	InternalServerError = INTERNAL_SERVER_ERROR,
}

export type TEntries<T> = { [K in keyof T]: [K, T[K]] }[keyof T][]

export enum EServiceErrors {
	FetchError = 'FETCH_ERROR',
}

export const COMMON_SERVICE_ERRORS: (EHttpCodes | EServiceErrors)[] = [
	// EHttpCodes.TooManyRequests,
	// EServiceErrors.FetchError,
	// EHttpCodes.NotAuthorized,
	// EHttpCodes.InternalServerError,
]
