import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { i18n } from 'app/localization'
import { FormGenerator, Text } from 'components'
import { EFormTypes, IFormScheme } from 'components/FormGenerator/types'
import { HandlerStepBlock } from 'features/handlers/blocks/HandlerStepBlock'
import { EHandlerSettingModes, IHandleHeights } from 'services/handlers/types'

import styles from './styles.module.scss'

const getShowConfig = (values: IHandleHeights) => values.settingsMode === EHandlerSettingModes.Manual && values.heightMarksFlag
const dependencies = ['heightMarksFlag', 'settingsMode']

const heightFlag = (name: string, height: number): IFormScheme => ({
	name,
	type: EFormTypes.Checker,
	label: <span>{`${height} ${i18n.t('m')}`}</span>,
	className: styles.heightFlag,
	inputProps: {
		size: 'small',
		bordered: true,
	},
	shouldShow: getShowConfig,
	dependencies,
})

export function VectorLayers() {
	const { t } = useTranslation('handlers')

	const scheme = useMemo<IFormScheme[]>(() => [
		{
			name: 'heightMarksFlag',
			type: EFormTypes.Checker,
			label: <span>{t('Elevation marks')}</span>,
			inputProps: {
				size: 'small',
			},
		},
		{
			name: 'heightMarksNum',
			isVertical: true,
			type: EFormTypes.Number,
			className: styles.nestedItem,
			label: <span>{t('Number of marks on dm map')}</span>,
			required: true,
			shouldShow: getShowConfig,
			dependencies,
			inputProps: {
				size: 'small',
			},
		},
	], [t])

	const heightFlagsLabelScheme = useMemo<IFormScheme[]>(() => [
		{
			name: '',
			type: EFormTypes.Custom,
			component: (
				<Text bold>{t('Horizontals')}</Text>
			),
			shouldShow: getShowConfig,
			dependencies,
		}
	], [t])

	const heightFlagsScheme = useMemo<IFormScheme[]>(() => [
		heightFlag('height05Flag', 0.5),
		heightFlag('height1Flag', 1),
		heightFlag('height2Flag', 2),
		heightFlag('height5Flag', 5),
		heightFlag('height10Flag', 10),
	], [])

	return (
		<HandlerStepBlock title={t('Additional vector layers')}>
			<FormGenerator scheme={scheme} />
			<div className={styles.nestedItem}>
				<FormGenerator className={styles.heightFlagLabel} scheme={heightFlagsLabelScheme} />
				<FormGenerator className={styles.heightFlagRow} scheme={heightFlagsScheme} />
			</div>
		</HandlerStepBlock>
	)
}
