import { ArrowDownTrayIcon, PencilSquareIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { DocumentIcon, FolderIcon } from '@heroicons/react/24/solid'
import dayjs from 'dayjs'
import React, { Key, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppDispatch } from 'app/store/hooks'
import { useBooleanFlag } from 'common/hooks/boolean'
import { formattingDate, formattingFileSize } from 'common/utils/formatting'
import { Button, Divider, Paper, Popconfirm, Row, Spin, Table, Text, message } from 'components'
import { useTableFilters } from 'components/Table/hooks'
import { ITableColumn } from 'components/Table/types'
import { getSortResultWithDirection } from 'components/Table/utils'
import { BucketConnector } from 'features/data/libs/BucketConnector'
import { IBucketFileList, IBucketSimpleResult, TBucketFile } from 'features/data/libs/BucketConnector/types'
import { getFolderValueByPath } from 'features/data/libs/BucketConnector/utils'
import { updateDataSlice } from 'features/data/store/actions'
import { RenameModal } from 'features/data/ui/RenameModal'
import { sortFileListByTypes } from 'features/data/utils'

import styles from './styles.module.scss'
import { IStorageFileListProps } from './types'

export function StorageFileList(props: IStorageFileListProps) {
	const { t } = useTranslation(['storage', 'labels'])
	const dispatch = useAppDispatch()
	const [loading, setLoading] = useBooleanFlag()
	const { fileList, path, onOpenDirectory, isEdit, onSelectFiles, selectedFiles, isMultipleSelect } = props
	const { filters, onTableChange } = useTableFilters<TBucketFile | IBucketFileList>()
	const [isOpenRenameModal, setIsOpenRenameModal] = useBooleanFlag()
	const [selectedObject, setSelectedObject] = useState<TBucketFile | IBucketFileList | null>(null)
	const tableRef = useRef<HTMLDivElement>(null)

	const files = useMemo<(TBucketFile | IBucketFileList)[]>(() => {
		setLoading(true)

		let files = []

		try {
			const fileListObject = getFolderValueByPath(fileList, path)?.content

			files = sortFileListByTypes(Object.values(fileListObject || {}) as (TBucketFile | IBucketFileList)[])
		} finally {
			setLoading(false)
		}

		return files
	}, [setLoading, fileList, path])

	const filteredFiles = useMemo(() => {
		const { sort, name } = filters
		let filteredList = [...files]

		if (name?.length) {
			filteredList = filteredList.filter((file) => file.name?.toLowerCase().includes(name[0].toLowerCase()))
		}

		if (sort?.length) {
			const [field, direction] = sort[0]

			switch (field) {
			case 'name':
				filteredList = filteredList.sort((a, b) => {
					return getSortResultWithDirection(a.name?.localeCompare(b.name || '') || 0, direction)
				})
				break
			case 'lastModified':
				filteredList = filteredList.sort((a, b) => {
					return getSortResultWithDirection(dayjs(a.lastModified as string).isBefore(dayjs(b.lastModified as string)), direction)
				})
				break
			case'size':
				filteredList = filteredList.sort((a, b) => {
					return getSortResultWithDirection((a?.size as number) < (b?.size as number), direction)
				})
				break
			default:
				break
			}
		}

		return sortFileListByTypes(filteredList)
	}, [files, filters])

	const onUpdateCallback = useCallback(async () => {
		setLoading(true)

		try {
			await dispatch(updateDataSlice())
		} catch (e) {
			void message.error(t('An error occurred while updating the list of files'))
		} finally {
			setLoading(false)
		}
	}, [dispatch, setLoading, t])

	const onDownloadCallback = useCallback((row: TBucketFile | IBucketFileList) => async () => {
		setLoading(true)

		const result = row.isFile
			? await BucketConnector?.downloadObject(row.key as string)
			: await BucketConnector?.downloadFolder(row as IBucketFileList)

		if (!result?.isSuccess) {
			void message.error(t('An error occurred during download'))
		}

		setLoading(false)
	}, [setLoading, t])

	const onDeleteCallback = useCallback((row: TBucketFile | IBucketFileList) => async () => {
		setLoading(true)

		let result

		if (row.isFile) {
			result = await BucketConnector?.deleteObject((row as TBucketFile).key as string)
		} else {
			result = await BucketConnector?.deleteFolder(`${path.join('/')}/${row.name}`)
		}

		if (result?.isSuccess) {
			void onUpdateCallback()
		} else {
			void message.error(t('An error occurred during deletion'))

			setLoading(false)
		}
	}, [setLoading, path, onUpdateCallback, t])

	const onOpenRenameModalCallback = useCallback((object?: TBucketFile | IBucketFileList) => {
		setSelectedObject(object ?? null)
		setIsOpenRenameModal(true)
	}, [setIsOpenRenameModal])

	const onCloseRenameModalCallback = useCallback(() => {
		setIsOpenRenameModal(false)
		setSelectedObject(null)
	}, [setIsOpenRenameModal])

	const onRenameCallback = useCallback(async (name: string) => {
		let result: Partial<IBucketSimpleResult> | undefined

		if (selectedObject) {
			result = selectedObject.isFile
				? await BucketConnector?.renameFile(selectedObject.key as string, name)
				: await BucketConnector?.renameFolder(selectedObject as IBucketFileList, name)
		} else {
			result = await BucketConnector?.createFolder(path.join('/'), name)
		}

		if (result?.isSuccess) {
			void onUpdateCallback()
		}

		return result || { isSuccess: false }
	}, [selectedObject, onUpdateCallback, path])

	const columns = useMemo<ITableColumn<TBucketFile | IBucketFileList>[]>(() => {
		const items: ITableColumn<TBucketFile | IBucketFileList>[] = [
			{
				key: 'isFile',
				dataIndex: 'isFile',
				title: ' ',
				width: 50,
				render: (isFile: boolean) => isFile
					? <DocumentIcon className={styles.icon} width={20}/>
					: <FolderIcon className={styles.icon} width={20}/>
			},
			{
				key: 'name',
				dataIndex: 'name',
				sorter: true,
				title: t('Name'),
				filterType: 'text',
				ellipsis: true,
			},
			{
				key: 'size',
				dataIndex: 'size',
				title: t('Size'),
				width: 110,
				sorter: true,
				render: (size: number) => (
					<Row justify='end'>
						{formattingFileSize(size)}
					</Row>
				),
			},
			{
				key: 'lastModified',
				dataIndex: 'lastModified',
				title: t('Date of change'),
				width: 175,
				sorter: true,
				render: (date: string) => (
					<Row justify='center'>
						{formattingDate(date)}
					</Row>
				),
			},
		]

		if (isEdit) {
			items.push({
				key: 'actions',
				title: t('Management'),
				width: 150,
				render: (row) => (
					<Row
						justify='end'
						align='middle'
						className={styles.actions}
					>
						<Button
							type='icon'
							title={t('Rename')}
							onClick={() => { onOpenRenameModalCallback(row) }}
							icon={
								<PencilSquareIcon width={20}/>
							}
						/>
						<Divider type='vertical'/>
						{(row.isFile || Boolean(row.size)) && (
							<>
								<Button
									type='icon'
									title={t('Download')}
									onClick={onDownloadCallback(row)}
									icon={
										<ArrowDownTrayIcon width={20}/>
									}
								/>
								<Divider type='vertical'/>
							</>
						)}
						<Popconfirm
							title={t('Are you sure you want to delete the object?')}
							onConfirm={onDeleteCallback(row)}
						>
							<Button
								danger
								type='icon'
								title={t('Remove')}
								icon={
									<TrashIcon width={20}/>
								}
							/>
						</Popconfirm>
					</Row>
				)
			})
		}

		return items
	}, [onDownloadCallback, onDeleteCallback, isEdit, t, onOpenRenameModalCallback])

	const onDoubleClickCallback = useCallback((row: TBucketFile | IBucketFileList) => {
		if (!row.isFile) {
			onOpenDirectory(row.name as string)

			tableRef.current?.getElementsByClassName('ant-table-container')?.[0]?.scroll({
				top: 0,
				behavior:'smooth'
			})
		}
	}, [onOpenDirectory])

	const onRow = useCallback((row: TBucketFile | IBucketFileList) => ({
		onDoubleClick: () => onDoubleClickCallback(row),
	}), [onDoubleClickCallback])

	const rowClassName = useCallback((row: TBucketFile | IBucketFileList) => row.isFile ? '' : styles.folderRow, [])
	const rowKey = useCallback((row: TBucketFile | IBucketFileList) => row.key as string, [])
	const footer = useCallback(() => (
		<Row align='middle' justify='space-between' wrap={false}>
			<Text>{`${t('total-footer', { count: filteredFiles.length })}`}</Text>
			<Button
				icon={<PlusIcon width={16} />}
				type='link'
				onClick={() => { onOpenRenameModalCallback() }}
			>
				{t('Create folder')}
			</Button>
		</Row>
	), [filteredFiles, t, onOpenRenameModalCallback])

	const onSelectFilesCallback = useCallback((selectedFilesKey: Key[], selectedFiles: TBucketFile[]) => {
		onSelectFiles?.(selectedFiles)
	}, [onSelectFiles])

	const rowSelection = useMemo(() =>
		onSelectFiles
			? ({
				selectedRowKeys: selectedFiles?.map((file) => file.key as Key),
				onChange: onSelectFilesCallback,
				type: isMultipleSelect ? 'checkbox' as const : 'radio' as const,
			})
			: undefined, [
		onSelectFilesCallback,
		selectedFiles,
		onSelectFiles,
		isMultipleSelect,
	])

	return (
		<>
			<Spin spinning={loading}>
				<Paper isFullHeight>
					<Table<TBucketFile | IBucketFileList>
						ref={tableRef}
						sticky
						rowSelection={rowSelection}
						filters={filters}
						className={styles.table}
						onChange={onTableChange}
						rowKey={rowKey}
						dataSource={filteredFiles}
						columns={columns}
						pagination={false}
						onRow={onRow}
						rowClassName={rowClassName}
						footer={footer}
					/>
				</Paper>
			</Spin>
			{isOpenRenameModal && (
				<RenameModal
					open={isOpenRenameModal}
					name={selectedObject?.name as string}
					onSave={onRenameCallback}
					onCancel={onCloseRenameModalCallback}
				/>
			)}
		</>
	)
}
