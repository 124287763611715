import { IState } from 'app/store/types'
import { DEFAULT_LAYER_STATE } from 'features/map/store/constants'
import { ILayerState, IMapMode } from 'features/map/store/types'
import { getLayerKey } from 'features/map/store/utils'
import { IMapBaseLayerNames } from 'features/map/types'

export const getBaseLayer = (state: IState): IMapBaseLayerNames => state.mapSlice?.baseLayer
export const getMode = (state: IState): IMapMode => state.mapSlice?.mode

export const getLayerState = (state: IState, layerData: {mapId: number, layerId: number}): ILayerState => state.mapSlice?.layers[getLayerKey(layerData)] ?? DEFAULT_LAYER_STATE
