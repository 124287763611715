import { HIDDEN_FILE_NAME } from 'features/data/libs/BucketConnector/constants'
import { IBucketFileList, TBucketFile } from 'features/data/libs/BucketConnector/types'

export const sortFileListByTypes = (files: (TBucketFile | IBucketFileList)[]) => {
	return files
		.filter((value) => value && typeof value === 'object' && value.name !== HIDDEN_FILE_NAME)
		.sort((fileA, fileB): number => {
			if (!fileA.isFile && fileB.isFile) {
				return -1
			}

			if (fileA.isFile && !fileB.isFile) {
				return 1
			}

			return 0
		})
}

export const getFolderFiles = (folder: IBucketFileList): TBucketFile[] => {
	if (!folder?.content)
		return []

	return Object.values(folder.content).reduce((acc, object) => {
		if ((object as TBucketFile)?.isFile) {
			return [...acc, object]
		}

		if ((object as IBucketFileList)?.content){
			return [...acc,...getFolderFiles(object as IBucketFileList)]
		}

		return acc
	}, [])
}

export const getFiles = (objects: (TBucketFile | IBucketFileList)[] = []): TBucketFile[] => {
	return objects.reduce((acc, object) => ([
		...acc,
		(object as TBucketFile)?.isFile
			? object as TBucketFile
			: getFolderFiles(object as IBucketFileList)
	].flat()), [] as TBucketFile[])
}
