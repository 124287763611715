import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Checkbox, CheckboxChangeEvent, Divider, Icon, InputNumber, Radio, RadioChangeEvent, Row, Space, Text } from 'components'
import { HandlerStepBlock } from 'features/handlers/blocks/HandlerStepBlock'

import styles from './styles.module.scss'
import { IFiltrationProps } from './types'

const isCheckedValue = (name: string, event: RadioChangeEvent) => {
	if (event.target.value === name) {
		return event.target.checked
	}

	return false
}

export function Filtration(props: IFiltrationProps) {
	const { t } = useTranslation('handlers')
	const { value, onChange } = props

	const onChangeModeCallback = useCallback((event: RadioChangeEvent) => {
		onChange?.({
			...value,
			autoSetting: isCheckedValue('autoSetting', event),
			manualSetting: isCheckedValue('manualSetting', event),
		})
	}, [onChange, value])

	const onChangeGenerateCallback = useCallback((event: CheckboxChangeEvent) => {
		onChange?.({
			...value,
			generateReviewVls: event.target.checked as boolean
		})
	}, [onChange, value])

	const onChangeManualModeCallback = useCallback((event: CheckboxChangeEvent) => {
		onChange?.({
			...value,
			absHeightFiltration: isCheckedValue('absHeightFiltration', event),
			stdFiltration: isCheckedValue('stdFiltration', event),
			percentFiltration: isCheckedValue('percentFiltration', event),
			kMeansFiltration: isCheckedValue('kMeansFiltration', event),
		})
	}, [onChange, value])

	const onChangeInputCallback = useCallback((name: string) => (inputValue: number | string | null) => {
		onChange?.({
			...value,
			[name]: inputValue,
		})
	}, [onChange, value])

	const settingsMode = useMemo(() => value?.manualSetting
		? 'manualSetting'
		: value?.autoSetting
			? 'autoSetting'
			: undefined, [value?.autoSetting, value?.manualSetting])

	const manualMode = useMemo(() => {
		switch (true) {
		case value?.absHeightFiltration:
			return 'absHeightFiltration'
		case value?.stdFiltration:
			return 'stdFiltration'
		case value?.percentFiltration:
			return 'percentFiltration'
		case value?.kMeansFiltration:
			return 'kMeansFiltration'
		default:
			return undefined
		}
	}, [value])

	return (
		<HandlerStepBlock
			title={t('TLO cloud filtering')}
			hint={t('hints.tloCloudFiltering')}
		>
			<Space direction='vertical'>
				<Radio.Group onChange={onChangeModeCallback} value={settingsMode}>
					<Space direction="vertical">
						<Radio checked={value?.autoSetting} value='autoSetting'>
							{t('Automatic setting')}
						</Radio>
						<Radio checked={value?.manualSetting} value='manualSetting'>
							{t('Manual setting')}
						</Radio>
					</Space>
				</Radio.Group>
				{value?.manualSetting && (
					<Radio.Group onChange={onChangeManualModeCallback} value={manualMode}>
						<Space className={styles.shift} direction="vertical">
							<Radio checked={value?.absHeightFiltration} value='absHeightFiltration'>
								{t('By absolute altitude range')}
							</Radio>
							{value?.absHeightFiltration && (
								<Space className={styles.shift} split={<Icon icon='arrow-right' size={16} />}>
									<InputNumber value={value?.minAbsHeight} onChange={onChangeInputCallback('minAbsHeight')} />
									<InputNumber value={value?.maxAbsHeight} onChange={onChangeInputCallback('maxAbsHeight')} />
								</Space>
							)}
							<Radio checked={value?.stdFiltration} value='stdFiltration'>
								{t('By on standard deviations of relative heights')}
							</Radio>
							{value?.stdFiltration && (
								<div className={styles.shift}>
									<InputNumber
										placeholder='sd'
										value={value?.std}
										onChange={onChangeInputCallback('std')}
									/>
								</div>
							)}
							<Radio checked={value?.percentFiltration} value='percentFiltration'>
								{t('By percentiles of relative heights')}
							</Radio>
							{value?.percentFiltration && (
								<Space className={styles.shift} split={<Icon icon='arrow-right' size={16} />}>
									<InputNumber value={value?.minPercent} onChange={onChangeInputCallback('minPercent')} />
									<InputNumber value={value?.maxPercent} onChange={onChangeInputCallback('maxPercent')} />
								</Space>
							)}
							<Radio checked={value?.kMeansFiltration} value='kMeansFiltration'>
								{t('By outliers (k-nearest neighbors)')}
							</Radio>
							{value?.kMeansFiltration && (
								<Space className={styles.shift} split={<Divider type='vertical' />}>
									<InputNumber
										placeholder={t('Points')}
										value={value?.pointNum}
										onChange={onChangeInputCallback('pointNum')}
									/>
									<InputNumber
										placeholder='sd'
										value={value?.std}
										onChange={onChangeInputCallback('std')}
									/>
								</Space>
							)}
						</Space>
					</Radio.Group>
				)}
				<Checkbox onChange={onChangeGenerateCallback} checked={value?.generateReviewVls}>
					<Row>
						<Text>{t('Generate VLS overview images')}</Text>
					</Row>
					<Row>
						<Text typographyType='secondary'>({t('TLO cloud density M 1:100,000')})</Text>
					</Row>
				</Checkbox>
			</Space>
		</HandlerStepBlock>
	)
}
