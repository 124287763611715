import React from 'react'
import { createRoot } from 'react-dom/client'

import Index from 'app'
import { ROOT_CONTAINER_NAME } from 'common/constants/client'
import 'assets/theme/styles/index.scss'

const container = document.getElementById(ROOT_CONTAINER_NAME)!
const root = createRoot(container)

root.render(<Index />)
