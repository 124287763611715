import React, { useCallback } from 'react'

import { IconButton } from 'components'
import { useMutableMapMode } from 'features/map/store/hooks'

export function MapModePicker() {
	const [mode, setMode] = useMutableMapMode()
	const onClickCallback = useCallback(() => setMode(mode === '2d'? '3d' : '2d'), [mode, setMode])
	return (
		<>
			<IconButton onClick={onClickCallback} icon={mode === '2d' ? '2d' : '3d'} />
		</>
	)
}
