import { LoadingOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import React from 'react'

import { AntdSpin } from 'components/index'

import styles from './styles.module.scss'
import { ILoadingProps } from './types'

export function Spin({
	children,
	spinning,
	className,
	wrapperClassName,
	alternative,
	isFullWidth,
	isFullParent,
	...props
}: ILoadingProps) {
	return (
		<AntdSpin
			className={classNames(
				styles.root,
				{
					[styles.fullWidth]: isFullWidth,
					[styles.fullParent]: isFullParent,
				},
				className
			)}
			wrapperClassName={classNames(styles.wrapper, wrapperClassName)}
			spinning={spinning}
			indicator={<LoadingOutlined className={styles.indicator} />}
			{...props}
		>
			{(alternative !== undefined) && spinning ? alternative : children}
		</AntdSpin>
	)
}
