import React from 'react'

import { AntdDatePicker } from 'components/index'

import { IDatePickerProps } from './types'

export function DatePicker(props: IDatePickerProps) {
	const { showToday = false, ...rest } = props

	return (
		<AntdDatePicker
			{...rest}
			showToday={showToday}
		/>
	)
}
