import React from 'react'

import { AntdRangePicker } from 'components/index'

import { IRangePickerProps } from './types'

const PLACEHOLDER = ['От', 'До'] as [string, string]

export function RangePicker(props: IRangePickerProps) {
	const { placeholder = PLACEHOLDER, ...rest } = props

	return (
		<AntdRangePicker
			{...rest}
			placeholder={placeholder}
		/>
	)
}
