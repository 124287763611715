import { useDispatch, useSelector } from 'react-redux'

export const useMutableSelector = <
  Selector extends (...args: any[]) => any,
  Action extends (...args: any[]) => void
>(
		selector: Selector,
		action: Action
	): [value: ReturnType<Selector>, trigger: (...args: Parameters<Action>) => void] => {
	const dispatch = useDispatch()
	const value = useSelector(selector) as ReturnType<Selector>
	const trigger = (...result: Parameters<Action>) => dispatch<any>(action(...result))

	return [value, trigger]
}
