import * as BORDERS from 'assets/theme/variables/borders'
import * as COLORS from 'assets/theme/variables/colors'
import * as PALETTE from 'assets/theme/variables/palette'
import { ESpacer } from 'assets/theme/variables/spacers'

const THEME = {
	token: {
		colorText: COLORS.TEXT_COLOR,
		borderRadius: BORDERS.BORDER_RADIUS,
		colorBorder: COLORS.BORDER_COLOR,
		colorTextPlaceholder: COLORS.PLACEHOLDER_COLOR,
		fontFamily: '\'Montserrat\', -apple-system, BlinkMacSystemFont, \'Segoe UI\', sans-serif',
		colorPrimary: COLORS.PRIMARY_COLOR,
		colorPrimaryHover: COLORS.PRIMARY_COLOR_HOVER,
		colorPrimaryActive: COLORS.PRIMARY_COLOR_ACTIVE,
		colorLink: COLORS.PRIMARY_COLOR,
		colorLinkHover: COLORS.PRIMARY_COLOR_HOVER,
		colorLinkActive: COLORS.PRIMARY_COLOR_ACTIVE,
		colorBgLayout: COLORS.PAGE_BG_COLOR,
		colorError: COLORS.ERROR_COLOR,
		colorSuccess: COLORS.SUCCESS_COLOR,
		colorWarning: COLORS.WARNING_COLOR,
		colorBgContainerDisabled: COLORS.DISABLED_COLOR,
		borderColorDisabled: COLORS.DISABLED_COLOR,
		colorTextDisabled: COLORS.DISABLED_TEXT_COLOR,
		colorSplit: COLORS.BORDER_COLOR,
	},
	components: {
		Button: {
			controlHeight: 44,
			controlHeightSM: 36,
			controlHeightLG: 56,
			fontSizeLg: 16,
			fontSize: 14,
			borderRadiusSM: 10,
			colorBorder: 'none',
			colorBgContainer: PALETTE.BLUE100,
			colorText: PALETTE.BLUE800,
			colorBgTextHover: PALETTE.BLUE100,
			colorBgTextActive: PALETTE.BLUE200,
		},
		Dropdown: {
			borderRadiusLG: BORDERS.BORDER_RADIUS,
			controlItemBgHover: PALETTE.VERDIGRIS100,
			borderRadiusSM: 0,
			marginXXS: ESpacer.SPACER1,
		},
		Breadcrumb: {
			colorTextDescription: PALETTE.PLATINUM900,
		},
		Input: {
			borderRadiusLG: BORDERS.BORDER_RADIUS,
			borderRadiusSM: BORDERS.BORDER_RADIUS - 4,
			controlHeight: 44,
			controlHeightLG: 56,
			controlHeightSM: 36,
			colorPrimaryHover: PALETTE.VERDIGRIS600,
			controlOutline: PALETTE.VERDIGRIS300,
			colorError: PALETTE.POPPY300,
		},
		Form: {
			paddingXS: ESpacer.SPACER0,
		},
		Tag: {
			borderRadiusSM: 100,
		},
		DatePicker: {
			controlHeight: 44,
			controlHeightLG: 56,
			controlHeightSM: 36,
		},
		InputNumber: {
			controlHeight: 44,
			controlHeightLG: 56,
			controlHeightSM: 36,
			colorTextDescription: PALETTE.BLUE800,
		},
		Switch: {
			lineHeight: 1.71428571,
			colorTextQuaternary: PALETTE.BLUE300,
			colorTextTertiary: PALETTE.BLUE500,
		},
		Radio: {
			colorBorder: PALETTE.BLUE700,
			colorBgContainerDisabled: COLORS.DISABLED_TEXT_COLOR,
		},
		Checkbox: {
			controlInteractiveSize: 20,
			colorBorder: PALETTE.BLUE700,
			colorBgContainerDisabled: COLORS.DISABLED_TEXT_COLOR,
			colorTextDisabled: PALETTE.WHITE,
		},
		Popover: {
			boxShadowSecondary: '0 10px 20px -4px rgba(0, 0, 0, 0.10), 0px 1px 10px 0px rgba(0, 0, 0, 0.05)',
		},
		Avatar: {
			colorTextPlaceholder: PALETTE.BLUE200,
			colorTextLightSolid: PALETTE.BLUE700
		},
		Typography: {
			colorTextDescription: PALETTE.BLUE700,
		},
		Table: {
			controlItemBgActive: PALETTE.PLATINUM300,
			controlItemBgActiveHover: PALETTE.PLATINUM500,
		},
		Select: {
			controlHeight: 44,
			controlHeightLG: 56,
			controlHeightSM: 36,
		},
	},
}

export default THEME
