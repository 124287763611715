import classNames from 'classnames'
import React from 'react'

import { AntdSpace } from 'components'

import styles from './styles.module.scss'
import { ISpaceProps } from './types'

export function Space({ isFullWidth, className, ...props }: ISpaceProps) {
	return (
		<AntdSpace
			className={classNames({
				[className as string]: Boolean(className),
				[styles.isFullWidth]: isFullWidth,
			})}
			{...props}
		/>
	)
}
