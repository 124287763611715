import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

import { EHttpCodes } from 'common/types/services'
import { EServiceTags } from 'services/types'

export const defaultTags = [EServiceTags.Unauthorized, EServiceTags.UnknownError] as const

type DefaultTags = typeof defaultTags[number]

function concatErrorCache<T, ID>(
	existingCache: CacheList<T, ID>,
	error: FetchBaseQueryError | undefined
): CacheList<T, ID> {
	if (error && 'status' in error && error.status === EHttpCodes.NotAuthorized) {
		return [...existingCache, EServiceTags.Unauthorized]
	}

	return [...existingCache, EServiceTags.UnknownError]
}

/**
 * An individual cache item
 */
export interface ICacheItem<T, ID> {
	type: T
	id: ID
}

/**
 * A list of cache items, including a LIST entity cache
 */
export type CacheList<T, ID> = (ICacheItem<T, 'LIST'> | ICacheItem<T, ID> | DefaultTags)[]

/**
 * Inner function returned by `providesList` to be passed to the `provides` property of a query
 */
type InnerProvidesList<T> = <Results extends { id: unknown }[], Error extends FetchBaseQueryError>(
	results: Results | undefined,
	error: Error | undefined
) => CacheList<T, Results[number]['id']>

/**
 * HOF to create an entity cache to provide a LIST,
 * depending on the results being in a common format.
 *
 * Will not provide individual items without a result.
 * @param type
 * @example
 * ```ts
 * const results = [
 * { id: 1, message: 'foo' },
 * { id: 2, message: 'bar' }
 * ]
 * providesList('Todo')(results)
 * // [
 * // { type: 'Todo', id: 'List'},
 * // { type: 'Todo', id: 1 },
 * // { type: 'Todo', id: 2 },
 * // ]
 * ```
 */
export const providesList = <T extends string>(type: T): InnerProvidesList<T> => (
	results,
	error
) => {
	if (results) {
		return [
			{
				type,
				id: 'LIST',
			},
			...results.map(
				({ id }) => ({
					type,
					id,
				} as const)
			),
		]
	}

	// Received an error, include an error cache item to the cache list
	return concatErrorCache(
		[
			{
				type,
				id: 'LIST',
			},
		],
		error
	)
}

/**
 * HOF to create an entity cache to invalidate a LIST.
 *
 * Invalidates regardless of result.
 * @param type
 * @example
 * ```ts
 * invalidatesList('Todo')()
 * // [{ type: 'Todo', id: 'List' }]
 * ```
 */
export const invalidatesList = <T extends string>(type: T) => (): readonly [
	ICacheItem<T, 'LIST'>
] => [
	{
		type,
		id: 'LIST',
	},
] as const

/**
 * HOF to create an entity cache for a single item using the query argument as the ID.
 * @param type
 * @example
 * ```ts
 * cacheByIdArg('Todo')({ id: 5, message: 'walk the fish' }, undefined, 5)
 * // returns:
 * // [{ type: 'Todo', id: 5 }]
 * ```
 */
export const cacheByIdArg = <T extends string>(type: T) => <
	ID,
	Result = undefined,
	Error = undefined
>(
		_result: Result,
		_error: Error,
		id: ID
	): readonly [ICacheItem<T, ID>] => [
		{
			type,
			id,
		},
	] as const

/**
 * HOF to create an entity cache for a single item using the id property from the query argument as the ID.
 * @param type
 * @example
 * ```ts
 * cacheByIdArgProperty('Todo')(undefined, { id: 5, message: 'sweep up' })
 * // returns:
 * // [{ type: 'Todo', id: 5 }]
 * ```
 */
export const cacheByIdArgProperty = <T extends string>(type: T) => <
	Arg extends { id: unknown },
	Result = undefined,
	Error = undefined
>(
		_result: Result,
		_error: Error,
		arg: Arg
	): readonly [ICacheItem<T, Arg['id']>] | [] => [
		{
			type,
			id: arg.id,
		},
	] as const

/**
 * HOF to invalidate the EServiceTags.Unauthorized type cache item.
 */
export const invalidatesUnauthorized = () => <
	Arg = undefined,
	Result = undefined,
	Error = undefined
>(
		_result: Result,
		_error: Error,
		_arg: Arg
	): [EServiceTags.Unauthorized] => [EServiceTags.Unauthorized]

/**
 * HOF to invalidate the EServiceTags.UnknownError type cache item.
 */
export const invalidatesUnknownErrors = () => <
	Arg = undefined,
	Result = undefined,
	Error = undefined
>(
		_result: Result,
		_error: Error,
		_arg: Arg
	): [EServiceTags.UnknownError] => [EServiceTags.UnknownError]
