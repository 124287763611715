import { CallbackProperty, Cartesian3, Cartographic, Color, EllipsoidGeodesic, Entity, JulianDate } from 'cesium'

import { POINT_PROPS, POLYGONE_PROPS, POLYLINE_PROPS } from 'features/map/constants'
import { EFeatureTypes } from 'features/map/types'
import { IGeoJsonLayer, IWfsLayer } from 'services/maps/types'

export const getBlueColor = (alpha = 1) => new Color(0.0941, 0.564, 1, alpha)

export const getFeatureByType = (
	type: EFeatureTypes,
	positions: CallbackProperty
) => {
	switch (type) {
	case EFeatureTypes.Polygon: {
		return {
			polygon: {
				...POLYGONE_PROPS,
				hierarchy: positions,
			},
		}
	}
	case EFeatureTypes.Polyline: {
		return {
			polyline: {
				...POLYLINE_PROPS,
				positions,
			},
		}
	}
	case EFeatureTypes.Point: {
		return {
			// @ts-ignore
			position: positions.getValue()[0],
			point: POINT_PROPS,
		}
	}
	}
}


/** BlackBox start */

const degreesPerRadian = 180.0 / Math.PI

function getAngle(p1: Cartesian3, p2: Cartesian3, p3: Cartesian3) {
	const bearing21 = getBearing(p2, p1)
	const bearing23 = getBearing(p2, p3)
	let angle = bearing21 - bearing23
	if (angle < 0) {
		angle += 360
	}
	return angle
}

function getBearing(rawFrom: Cartesian3, rawRo: Cartesian3) {
	const from = Cartographic.fromCartesian(rawFrom)
	const to = Cartographic.fromCartesian(rawRo)

	const lat1 = from.latitude
	const lon1 = from.longitude
	const lat2 = to.latitude
	const lon2 = to.longitude
	let angle = -Math.atan2(Math.sin(lon1 - lon2) * Math.cos(lat2), Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon1 - lon2))
	if (angle < 0) {
		angle += Math.PI * 2.0
	}
	angle = angle * degreesPerRadian
	return angle
}

function distance(point1: Cartesian3, point2: Cartesian3) {
	const point1cartographic = Cartographic.fromCartesian(point1)
	const point2cartographic = Cartographic.fromCartesian(point2)
	const geodesic = new EllipsoidGeodesic()
	geodesic.setEndPoints(point1cartographic, point2cartographic)
	return Math.sqrt(Math.pow(geodesic.surfaceDistance, 2) + Math.pow(point2cartographic.height - point1cartographic.height, 2))
}

export const getAreaByPoints = (points: Cartesian3[]) => {
	let res = 0

	for (let i = 0; i < points.length - 2; i++) {
		const j = (i + 1) % points.length
		const k = (i + 2) % points.length
		const totalgetAngle = getAngle(points[i], points[j], points[k])


		const dis_temp1 = distance(points[j], points[0])
		const dis_temp2 = distance(points[k], points[0])
		res += dis_temp1 * dis_temp2 * Math.sin(totalgetAngle) / 2
	}

	return Math.abs(res)
}


/** BlackBox end */


export const getPositionsByGeometry = (geometry: Entity, type: EFeatureTypes): Cartesian3[] => {
	switch (type) {
	case EFeatureTypes.Polyline:
		return geometry?.polyline?.positions?.getValue(new JulianDate())
	case EFeatureTypes.Polygon:
		return geometry.polygon?.hierarchy?.getValue(new JulianDate())?.positions
	default:
		return []
	}
}

export const getEntityCoordinatesByGeometry = (geometry: Entity, type: EFeatureTypes): Cartesian3[] => {
	switch (type) {
	case EFeatureTypes.Polyline:
		return geometry?.polyline?.positions?.getValue(new JulianDate())
	case EFeatureTypes.Polygon:
		return geometry.polygon?.hierarchy?.getValue(new JulianDate())
	default:
		return []
	}
}

export const normalizeColor = (color?: string | string[]): Color | undefined => {
	switch (true) {
	case typeof color === 'string': {
		return Color.fromCssColorString(color!.toString())
	}

	case Array.isArray(color) && color[2]?.startsWith('#'): {
		return Color.fromCssColorString(color![2])
	}
	default: {
		return undefined
	}
	}
}

export const layerToStyle = (layer: IWfsLayer | IGeoJsonLayer) => (
	{
		fill: normalizeColor(layer.style?.fillColor),
		markerColor: normalizeColor(layer.style?.fillColor),
		stroke: normalizeColor(layer.style?.strokeColor),
		strokeWidth: layer?.style?.strokeWidth,
	}
)
