import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/24/solid'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Space, Table, Text } from 'components'
import { HandlerStepBlock } from 'features/handlers/blocks/HandlerStepBlock'
import { HANDLER_POINTS_CLASSES_COLORS, HANDLER_POINTS_CLASSES_LABELS } from 'features/handlers/constants'
import { EHandlerPointsClasses, IHandlerPointClass } from 'services/handlers/types'

import styles from './styles.module.scss'
import { IPointsClassProps } from './types'

export function PointsClass(props: IPointsClassProps) {
	const { t } = useTranslation()
	const { value, onChange } = props
	const data = useMemo(() => value?.sort((a, b) => b.value - a.value) || [], [value])

	const onLockClassCallback = useCallback((classValue: number) => () => {
		onChange?.(
			data.map(item => ({
				...item,
				locked: classValue === item.class ? !item.locked : item.locked,
			}))
		)
	}, [data, onChange])

	const columns = useMemo(() => [
		{
			title: '№',
			dataIndex: 'class',
			key: 'class',
			width: 20,
		},
		{
			title: (
				<span title={t('Fixed class')}>
					<LockOpenIcon className={styles.lockIcon} width={14} />
				</span>
			),
			dataIndex: 'locked',
			key: 'locked',
			width: 20,
			render: (value: boolean, item: IHandlerPointClass) => (
				<Button
					className={styles.button}
					type='icon'
					title={t(value ? 'Unfix' : 'Fix')}
					onClick={onLockClassCallback(item.class)}
				>
					{value ? <LockClosedIcon className={styles.lockIcon} width={14}/> : <LockOpenIcon className={styles.lockIcon} width={14}/>}
				</Button>
			),
		},
		{
			title: t('Class'),
			dataIndex: 'class',
			key: 'classLabel',
			width: 290,
			render: (classValue: EHandlerPointsClasses) => (
				<Space isFullWidth className={styles.space}>
					<div className={styles.classDot} style={{ background: HANDLER_POINTS_CLASSES_COLORS[classValue] }} />
					<Text
						title={HANDLER_POINTS_CLASSES_LABELS[classValue]}
						type='body14'
						ellipsis
					>
						{HANDLER_POINTS_CLASSES_LABELS[classValue]}
					</Text>
				</Space>
			)
		},
		{
			title: t('Points percent'),
			dataIndex: 'value',
			key: 'value',
			width: 100,
			render: (value: number) => <Text type='body14'>{value}%</Text>,
		},
	], [onLockClassCallback, t])

	return (
		<HandlerStepBlock title={t('Points classes')}>
			<Table
				className={styles.classTable}
				columns={columns}
				dataSource={data}
				rowKey='class'
				pagination={false}
			/>
		</HandlerStepBlock>
	)
}
