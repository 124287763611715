import { CloudIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ROUTES } from 'app/router/constants'
import { Button } from 'components'

import styles from './styles.module.scss'

export function StorageIcon() {
	const { t } = useTranslation('storage')
	return (
		<Link to={ROUTES.Storage}>
			<Button
				className={styles.root}
				type='tertiary'
				title={t('Storage')}
				icon={<CloudIcon className={styles.icon} width={32} />}
			/>
		</Link>
	)
}
