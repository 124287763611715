import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useForm } from 'common/hooks/form'
import { useMutationNotifications } from 'common/hooks/notifications'
import { Button, Form, FormGenerator, Modal, Row, Space } from 'components'
import { IFormScheme } from 'components/FormGenerator/types'
import { projectsApi } from 'services/projects'

import { IProjectEntityModalProps } from './types'

const { useCreateProjectMutation, useUpdateProjectMutation } = projectsApi

const FORM_NAME = 'project'

export function ProjectEntityModal(props: IProjectEntityModalProps) {
	const { t } = useTranslation('projects')
	const { open, onCancel, item } = props
	const isEdit = Boolean(item)

	const [createProject, createProjectState] = useMutationNotifications(useCreateProjectMutation, {
		errorText: t('An error occurred during the creation of the project'),
		successText: t('New project successfully created'),
	})

	const [updateProject, updateProjectState] = useMutationNotifications(useUpdateProjectMutation, {
		errorText: t('An error occurred during project modifications'),
		successText: t('Project successfully modified'),
	})

	const formStates = useMemo(() => [createProjectState, updateProjectState], [createProjectState, updateProjectState])

	const loading = useMemo(() => createProjectState.isLoading || updateProjectState.isLoading,
		[createProjectState.isLoading, updateProjectState.isLoading]
	)

	const [form] = useForm(formStates, {
		defaultMessage: t('Failed to save changes, please try again later'),
		values: item,
	})

	useEffect(() => {
		if (!open) {
			form.resetFields()
		}
	}, [open, form])

	const projectName = Form.useWatch('name', form) as string

	const scheme = useMemo<IFormScheme[]>(() => [
		{
			label: t('Project name'),
			name: 'name',
			required: true,
			inputProps: {
				placeholder: t('Project number', { count: 1 })
			},
		},
	], [t])

	const onFinishCallback = useCallback(async (values: { title: string }) => {
		const action = isEdit ? updateProject : createProject
		const actionState = isEdit ? updateProjectState : createProjectState
		const body = isEdit ? { ...values, id: item?.id } : values

		const result = await action(body)

		if (!('error' in result)) {
			onCancel()
			actionState.reset()
		}
	}, [isEdit, updateProject, createProject, updateProjectState, createProjectState, item?.id, onCancel])

	return (
		<Modal
			centered
			destroyOnClose
			open={open}
			onCancel={onCancel}
			title={isEdit ? t('Edit project') : t('New project')}
			width={500}
			footer={(
				<Row justify='end'>
					<Space>
						<Button
							htmlType='submit'
							form={FORM_NAME}
							type='primary'
							disabled={!projectName}
							loading={loading}
						>
							{isEdit ? t('Save') : t('Create')}
						</Button>
					</Space>
				</Row>
			)}
		>
			<Form
				form={form}
				layout='vertical'
				name={FORM_NAME}
				onFinish={onFinishCallback}
			>
				<FormGenerator scheme={scheme} />
			</Form>
		</Modal>
	)
}
