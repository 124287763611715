import { ExclamationCircleOutlined } from '@ant-design/icons'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { ROUTES } from 'app/router/constants'
import { Button, Result } from 'components'
import ShortContent from 'components/ShortContent'
import { useMainLayoutContext } from 'features/layouts/blocks/MainLayout/hooks'

export default function NotFound() {
	const { t } = useTranslation(['errors', 'actions'])
	useMainLayoutContext()

	const navigate = useNavigate()

	const onBackHomeCallback = useCallback(() => {
		void navigate(ROUTES.Root)
	}, [navigate])

	return (
		<ShortContent>
			<Result
				icon={<ExclamationCircleOutlined height={250} />}
				title= {t('notFound.Nothing found')}
				subTitle={t('notFound.It seems this page has been deleted or lost')}
				extra={(
					<Button
						size='large'
						block
						onClick={onBackHomeCallback}
						type='primary'
					>
						{t('Back to the main page')}
					</Button>
				)}
			/>
		</ShortContent>
	)
}
