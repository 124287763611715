import { EHandlerForestRegions, EHandlerForestZones } from 'services/handlers/types'

export const SEMANTIC_CLASSIFICATION_STEP_INITIAL_VALUES = {
	step: 30,
}

export const SEMANTIC_CLASSIFICATION_FOREST_ZONES: EHandlerForestZones[] = [
	EHandlerForestZones.Tundra,
	EHandlerForestZones.Taiga,
	EHandlerForestZones.BroadLeaved,
	EHandlerForestZones.Forest,
	EHandlerForestZones.Steppe,
	EHandlerForestZones.Desert,
	EHandlerForestZones.Caucasus,
	EHandlerForestZones.Siberia,
]

export const SEMANTIC_CLASSIFICATION_FOREST_REGIONS: { [key in EHandlerForestZones]: EHandlerForestRegions[] } = {
	[EHandlerForestZones.Tundra]: [
		EHandlerForestRegions.Urals,
		EHandlerForestRegions.WestSiberia,
		EHandlerForestRegions.MidSiberia,
		EHandlerForestRegions.FarEast,
	],
	[EHandlerForestZones.Taiga]: [
		EHandlerForestRegions.NorthTaiga,
		EHandlerForestRegions.MidTaiga,
		EHandlerForestRegions.SouthTaiga,
		EHandlerForestRegions.NorthUral,
		EHandlerForestRegions.MidUral,
	],
	[EHandlerForestZones.BroadLeaved]: [
		EHandlerForestRegions.ConiferousDeciduous,
		EHandlerForestRegions.PriamurskoPrimorsky,
	],
	[EHandlerForestZones.Forest]: [
		EHandlerForestRegions.EuropeanForest,
		EHandlerForestRegions.SouthUral,
		EHandlerForestRegions.WestSiberian,
		EHandlerForestRegions.CentralSiberian,
	],
	[EHandlerForestZones.Steppe]: [
		EHandlerForestRegions.EuropeanSteppes,
	],
	[EHandlerForestZones.Desert]: [
		EHandlerForestRegions.EuropeanDesert,
	],
	[EHandlerForestZones.Caucasus]: [
		EHandlerForestRegions.NorthCaucasus,
	],
	[EHandlerForestZones.Siberia]: [
		EHandlerForestRegions.AltaiSayanTaiga,
		EHandlerForestRegions.AltaiSayanForest,
		EHandlerForestRegions.BaikalMountain,
		EHandlerForestRegions.TransbaikalianPermafrost,
	],
}
