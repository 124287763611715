import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Dropdown } from 'components'
import { IStorageUploadProps } from 'features/data/blocks/StorageUploadButton/types'
import { useUploadList } from 'features/data/blocks/UploadListGlobal/hooks'

export function StorageUploadButton(props: IStorageUploadProps) {
	const { t } = useTranslation('storage')
	const { path = [] } = props
	const { openUploadDialog } = useUploadList()

	const onOpenUploadCallback = useCallback(() => {
		openUploadDialog(path?.join('/'))
	}, [openUploadDialog, path])

	const onOpenUploadDirectoryCallback = useCallback(() => {
		openUploadDialog(path?.join('/'), 'directory')
	}, [openUploadDialog, path])

	const uploadMenu = useMemo(() => ({
		items: [
			{
				label: t('Upload file'),
				key: 'files',
				onClick: onOpenUploadCallback,
			},
			{
				label: t('Upload directory'),
				key: 'directories',
				onClick: onOpenUploadDirectoryCallback,
			},
		]
	}), [onOpenUploadCallback, onOpenUploadDirectoryCallback, t])

	return (
		<Dropdown.Button type='primary' menu={uploadMenu} onClick={onOpenUploadCallback}>
			{t('Upload')}
		</Dropdown.Button>
	)
}
