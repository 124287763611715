import classNames from 'classnames'
import React, { ReactNode, useMemo, useState } from 'react'

import { AppRoutes } from 'app/router'
import { MENU_WIGHT } from 'assets/theme/variables/sizes'
import { CONTENT_CONTAINER_NAME } from 'common/constants/client'
import { Layout } from 'components'
import { GlobalModals } from 'features/layouts/blocks/GlobalInterfaces'
import Header from 'features/layouts/blocks/Header'
import Navigation from 'features/layouts/blocks/Navigaton'

import { MAIN_LAYOUT_DEFAULT_OPTIONS } from './constants'
import { MainLayoutContext } from './context'
import styles from './styles.module.scss'
import { IMainLayoutOptions } from './types'

export const MainLayout = () => {
	const [
		{
			menuClassName,
			contentClassName,
			className,
			showHeader,
			showMenu,
			headerClassName,
		},
		setOptions,
	] = useState<IMainLayoutOptions>(MAIN_LAYOUT_DEFAULT_OPTIONS)
	const [headerContent, setHeaderContent] = useState<ReactNode>(null)

	const contextValue = useMemo(
		() => ({
			initialization: (values = {}) => setOptions({
				...MAIN_LAYOUT_DEFAULT_OPTIONS,
				...values,
			}),
			setHeaderContent,
		}),
		[]
	)

	return (
		<Layout className={classNames(styles.root, className)}>
			<MainLayoutContext.Provider value={contextValue}>
				{showHeader && (
					<Layout.Header className={classNames(styles.header, headerClassName)}>
						<Header>{headerContent}</Header>
					</Layout.Header>
				)}
				<Layout>
					{showMenu && (
						<Layout.Sider
							className={classNames(styles.menu, menuClassName)}
							width={MENU_WIGHT}
						>
							<Navigation />
						</Layout.Sider>
					)}
					<Layout.Content id={CONTENT_CONTAINER_NAME} className={classNames(styles.content, contentClassName)}>
						<AppRoutes />
					</Layout.Content>
				</Layout>
				<GlobalModals />
			</MainLayoutContext.Provider>
		</Layout>
	)
}
