import { Cartesian3, Color, SceneMode, WebMercatorProjection } from 'cesium'
import React, { useMemo } from 'react'
import { CameraFlyTo, Globe, Viewer } from 'resium'

import { BaseLayer } from 'features/map/blocks/BaseLayer'
import { Instruments } from 'features/map/blocks/Instruments'
import { Layers } from 'features/map/blocks/Layers'
import { MAP_SETTINGS } from 'features/map/blocks/Map/constants'
import { IMapProps } from 'features/map/blocks/Map/types'
import { MapContext } from 'features/map/context'
import { useMapMode } from 'features/map/store/hooks'

import styles from './styles.module.scss'

export function Map({ map }: IMapProps) {
	const mapMode = useMapMode()
	const sceneMode = useMemo(() => mapMode === '2d' ? SceneMode.SCENE2D : SceneMode.SCENE3D, [mapMode])
	const mapData = useMemo(() => {
		return {
			mapId: map?.id,
			map: map
		}
	}, [map])

	return (
		<MapContext.Provider value={mapData}>
			<Viewer
				{...MAP_SETTINGS}
				sceneMode={sceneMode}
				className={styles.root}
			>
				{
					map && (
						<Layers />
					)
				}
				<CameraFlyTo duration={0} destination={Cartesian3.fromDegrees(map?.center?.x || 49.5962, map?.center?.y || 62.0496, map?.zoom || 235000)} />
				<Globe baseColor={Color.GRAY} />
				<BaseLayer />
				<Instruments />
			</Viewer>
		</MapContext.Provider>
	)
}
