import React, { useCallback, useEffect, useMemo } from 'react'

import { useForm } from 'common/hooks/form'
import { normalizeObject } from 'common/utils/normalization'
import { FormGenerator } from 'components/FormGenerator'
import { FormHintField } from 'components/FormHintField'
import { Form } from 'components/index'

import { IFormLayoutProps } from './types'

export function FormLayout<FormTypes = unknown>(props: IFormLayoutProps<FormTypes>) {
	const {
		form: propsForm, state: rawState, onReset, formName, values, errorMessage, scheme, isSelfContent, generatorProps, children,
		onFinish, disabled, onValuesChange,
	} = props

	const state = useMemo(() => rawState || [], [rawState])
	const [localForm] = useForm(state, {
		defaultMessage: errorMessage as string,
	})

	const form = useMemo(() => propsForm || localForm, [propsForm, localForm])

	const initForm = useCallback(() => {
		form?.resetFields()
		form?.setFieldsValue({
			...values,
			...normalizeObject(form.getFieldsValue()),
		})
	}, [form, values])

	useEffect(() => {
		return () => {
			form?.resetFields()
		}
	}, [form])

	useEffect(() => {
		if (values) {
			initForm()
		}
	}, [initForm, form, values])

	const onResetCallback = useCallback(() => {
		form?.resetFields()

		if (values) {
			initForm()
		}

		onReset?.()
	}, [form, initForm, values, onReset])

	return (
		<Form
			form={form}
			name={formName}
			onReset={onResetCallback}
			onFinish={onFinish}
			onValuesChange={onValuesChange}
			layout='vertical'
			disabled={disabled}
		>
			{ isSelfContent || !scheme
				? children : (
					<>
						<FormGenerator scheme={scheme} {...generatorProps} />
						{children}
					</>
				)}
			<FormHintField name='detail' />
		</Form>
	)
}
