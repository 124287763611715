import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

import { i18n } from 'app/localization'
import { COMMON_SERVICE_ERRORS, EHttpCodes, EServiceErrors } from 'common/types/services'

const { t } = i18n

export const getErrorText = (
	error: FetchBaseQueryError | SerializedError,
	defaultMessage = t('projects:Failed to make changes, please try again later')
) => {
	let message = defaultMessage

	if ('data' in error) {
		if (typeof error?.data === 'object') {
			const values = Object.values(error.data as Record<never, unknown>)

			if (values.length) {
				const [firstMessage] = values as string[]

				if (typeof firstMessage === 'string') {
					message = firstMessage
				}
			}
		} else {
			message = error?.data as string
		}
	}

	if ('message' in error) {
		message = (error.message || error.code) as string
	}

	return message
}

export const isCommonServiceError = (statusError: string | number) => COMMON_SERVICE_ERRORS.includes(statusError as EHttpCodes | EServiceErrors)
