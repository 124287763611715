import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { ROUTES_ENTITIES } from 'app/router/constants'
import { useBooleanFlag } from 'common/hooks/boolean'
import { useMutationNotifications } from 'common/hooks/notifications'
import { stopEventPropagation } from 'common/utils/event'
import { formattingDate, formattingLabels2Options } from 'common/utils/formatting'
import { ActionIndicator, Content, Table, Tag, Text, confirmDelete } from 'components'
import { EFormTypes, IFormScheme } from 'components/FormGenerator/types'
import { useTableFilters } from 'components/Table/hooks'
import { ITableColumn } from 'components/Table/types'
import { TableContainer } from 'components/Table/ui/TableContainer'
import { CreateHandlerModal } from 'features/handlers/blocks/CreateHandlerModal'
import { HANDLER_EVENT_STATUS_LABELS, HANDLER_STEPS_LABELS, HANDLER_STEPS_ORDER } from 'features/handlers/constants'
import { HandlerEventStatusTag } from 'features/handlers/ui/HandlerEventStatusTag'
import { HandlerStepFooters } from 'features/handlers/ui/HandlerStepFooter'
import { IHandlerStepFooterProps } from 'features/handlers/ui/HandlerStepFooter/types'
import { downloadHandlerData, getHandlerActionType, getHandlerStepNumber } from 'features/handlers/utils'
import { useMainLayoutContext } from 'features/layouts/blocks/MainLayout/hooks'
import { useProject, useProjectPageContext, useSetProjectHeader } from 'features/projects/store/hooks'
import { handlersApi } from 'services/handlers'
import { EHandlerActionTypes, EHandlerSteps, IGetHandlersByProjectParams, IHandler } from 'services/handlers/types'

import styles from './styles.module.scss'
import { IHandlerFiltersPage } from './types'

const {
	useGetHandlersByProjectQuery,
	useDeleteHandlerMutation,
	useStartHandlerMutation,
} = handlersApi

export default function AI() {
	const { setHeaderContent } = useMainLayoutContext()
	const { projectId } = useProjectPageContext()
	const { t } = useTranslation('handlers')
	const [isOpenModal, setOpenModal] = useBooleanFlag(false)
	const navigate = useNavigate()
	const { data: project } = useProject(projectId)
	const [loading, setLoading] = useBooleanFlag()

	useSetProjectHeader(projectId, setHeaderContent)

	const { filters, pagination, onFiltersChange, onTableChange } = useTableFilters<IHandler>()

	const params = useMemo(() => {
		const { date, ...rest } = filters as IHandlerFiltersPage

		const body: IGetHandlersByProjectParams = {
			...rest,
			projectId: projectId as string,
		}

		if (date) {
			const [start, end] = date

			if (start) {
				body.startDate = formattingDate(start)
			}

			if (end) {
				body.endDate = formattingDate(end)
			}
		}

		return body
	}, [filters, projectId])

	const { data, isFetching } = useGetHandlersByProjectQuery(params, {
		skip: !params?.projectId,
	})

	const [deleteHandler] = useMutationNotifications(useDeleteHandlerMutation, {
		errorText: t('An error occurred while deleting the handler'),
		successText: t('Handler deleted successfully'),
	})

	const [startHandler] = useMutationNotifications(useStartHandlerMutation, {
		errorText: t('An error occurred while starting the handler'),
		successText: t('Handler successfully started'),
	})

	const tableLoading = useMemo(() => isFetching || loading, [isFetching, loading])

	const handlerActions = useCallback((handler: IHandler): IHandlerStepFooterProps['actions'] => ({
		onDelete: (e) => {
			stopEventPropagation(e)
			confirmDelete({
				title: t('Are you sure you want to delete the handler?', { name: handler.title }),
				onOk: async () => {
					await deleteHandler({
						id: handler.id,
					})
				},
			})
		},
		onStart: (e) => {
			stopEventPropagation(e)
			startHandler(handler)
		},
		onChange: (e) => {
			stopEventPropagation(e)
			navigate(ROUTES_ENTITIES.getHandlerPage(handler.id, {
				pageType: EHandlerActionTypes.Change,
			}))
		},
		onNextStep: (e) => {
			stopEventPropagation(e)

			const currentStep = getHandlerStepNumber(handler.step)
			const nextStep = HANDLER_STEPS_ORDER[currentStep + 1]

			navigate(ROUTES_ENTITIES.getHandlerPage(handler.id, {
				pageType: EHandlerActionTypes.NextStepChange,
				step: nextStep,
			}))
		},
		onSendToMap: (e) => {
			stopEventPropagation(e)
			navigate(ROUTES_ENTITIES.getHandlerPage(handler.id, {
				pageType: EHandlerActionTypes.SendToMap,
			}))
		},
		onDownload: async (e) => {
			stopEventPropagation(e)

			if (project?.name) {
				setLoading(true)

				try {
					await downloadHandlerData(handler.title, project.name)
				} finally {
					setLoading(false)
				}
			}
		},
	}), [
		navigate,
		deleteHandler,
		t,
		startHandler,
		project?.name,
		setLoading,
	])

	const columns = useMemo<ITableColumn<IHandler>[]>(() => [
		{
			key: 'title',
			dataIndex: 'title',
			sorter: true,
			title: t('Handler title'),
			render: (title: string, row: IHandler) => (
				<ActionIndicator isActive={row.hasUpdates}>
					<Text>{title}</Text>
				</ActionIndicator>
			),
		},
		{
			key: 'step',
			dataIndex: 'step',
			title: t('Step'),
			width: 100,
			render: (step) => {
				const stepIndex = HANDLER_STEPS_ORDER.indexOf(step)

				return (
					<Tag width={26}>
						{stepIndex > -1 ? stepIndex + 1 : ''}
					</Tag>
				)
			}
		},
		{
			key: 'type',
			dataIndex: 'step',
			title: t('Type'),
			render: (step: EHandlerSteps) => (
				<Text>{HANDLER_STEPS_LABELS[step]}</Text>
			),
			width: 300,
		},
		{
			key: 'createAt',
			dataIndex: 'createAt',
			title: t('Create date'),
			render: formattingDate,
			width: 150,
		},
		{
			key: 'status',
			dataIndex: 'status',
			title: t('Status'),
			width: 150,
			render: (status) => (<HandlerEventStatusTag status={status} />),
		},
		{
			key: 'actions',
			title: t('Actions'),
			width: 300,
			render: (row: IHandler) => (
				<HandlerStepFooters
					btnType='icon'
					btnSize='small'
					type={getHandlerActionType(row)}
					actions={handlerActions(row)}
				/>
			)
		},
	], [t, handlerActions])

	const onAddAiCallback = useCallback(() => {
		setOpenModal(true)
	}, [setOpenModal])

	const filterScheme = useMemo<IFormScheme[]>(() => (
		[
			{
				name: 'search',
				label: t('Handler title'),
				inputProps: {
					size: 'small',
					placeholder: t('Enter handler title'),
				}
			},
			{
				name: 'step',
				type: EFormTypes.Select,
				label: t('Handler type'),
				inputProps: {
					size: 'small',
					placeholder: t('Choose handler type'),
					options: formattingLabels2Options(HANDLER_STEPS_LABELS)
				}
			},
			{
				name: 'date',
				type: EFormTypes.DateRangePicker,
				label: t('Create date'),
				inputProps: {
					size: 'small',
					allowEmpty: [true, true],
				}
			},
			{
				name: 'status',
				type: EFormTypes.Select,
				label: t('Status'),
				inputProps: {
					size: 'small',
					placeholder: t('Choose handler status'),
					options: formattingLabels2Options(HANDLER_EVENT_STATUS_LABELS)
				}
			},
		]
	), [
		t
	])

	const onCloseCallback = useCallback(() => {
		setOpenModal(false)
	}, [setOpenModal])

	const paginationConfig = useMemo(() => ({
		total: data?.length,
		showTotal: (total: number) => t('total-handlers', { count: total }),
		...pagination
	}), [pagination, t, data?.length])

	const onRowCallback = useCallback((record: IHandler) => ({
		onClick: () => {
			navigate(ROUTES_ENTITIES.getHandlerPage(record.id))
		},
	}), [navigate])

	return (
		<Content
			isFullWidth
		>
			<TableContainer<typeof filters>
				addButtonTitle={t('Add handler')}
				onAdd={onAddAiCallback}
				scheme={filterScheme}
				onFiltersChange={onFiltersChange}
				filters={filters}
			>
				<Table<IHandler>
					onChange={onTableChange}
					rowKey='id'
					dataSource={data}
					rowClassName={styles.row}
					columns={columns}
					loading={tableLoading}
					pagination={paginationConfig}
					onRow={onRowCallback}
				/>
			</TableContainer>
			<CreateHandlerModal
				open={isOpenModal}
				onCancel={onCloseCallback}
			/>
		</Content>
	)
}
