import classNames from 'classnames'
import React from 'react'

import { AntdBadge } from 'components/index'

import styles from './styles.module.scss'
import { IBadgeProps } from './types'

export function Badge(props: IBadgeProps) {
	const { dotSize = 12, className, borderless, ...rest } = props

	return (
		<AntdBadge
			offset={[-4, 4]}
			{...rest}
			style={{
				width: dotSize,
				height: dotSize,
			}}
			className={classNames(styles.root, className, {
				[styles.borderless]: borderless,
			})}
		/>
	)
}
