import { IDefaultCollectionRequestParams } from 'services/types'

export enum EHandlerStatus {
  New = 'NEW',
  Pending = 'PENDING',
  Paused = 'PAUSED',
  Running = 'RUNNING',
  Done = 'DONE',
  Error = 'ERROR'
}

export enum EHandlerSteps {
  Preprocessing = 'PREPROCESSING',
  Classification = 'CLASSIFICATION',
  Heights = 'HEIGHTS',
  LasSegmentation ='LAS_SEGMENTATION',
  SemanticClassification = 'SEMANTIC_CLASSIFICATION',
}

export enum EHandlerPointsClasses {
  Created = 0, // Создана, никогда не классифицировалась
  NotClassified = 1, // Не классифицировано
  Ground = 2, // Земля
  TallVegetation = 5, // Высокая растительность
  Building = 6, // Здание
  LowerPoint = 7, // Низкая точка
  HigherPoint = 18, // Высокая точка
}

export enum EHandlerOtClassificationModels {
  FabricSimulation = 'csf', // Симуляция ткани
  MorphologicalFilter = 'smrf', // Морфологический фильтр
  NeuralNetworkModel = 'nn' // Нейросетевая модель
}

export enum EHandlerClassificationDefaultParams {
  FlatRelief = 'plain', // Равнинный рельеф
  HillsRelief = 'hills', // Холмистый рельеф
  RuggedRelief = 'rugged', // Пересеченный рельеф
  UrbanRelief = 'urban' // Городской рельеф
}

export enum EHandlerActionTypes {
  Create = 'create',
  Change = 'change',
  NextStepChange = 'next-step-change',
  Start = 'start',
  Stop = 'stop',
  Restart = 'restart',
  NextStep = 'next-step',
  Done = 'done',
  SendToMap = 'send-to-map',
}

export enum EHandlerSmoothingAlgorithm {
  Median = 'median', // Медианная
  Gaussian = 'gaussian', // Gauss
  PitFree = 'pit_free', // PitFree
  Average = 'average' // Средняя
}

export enum EHandlerScales {
  FiveHundred = '0.002',
  OneThousand = '0.001',
  TwoThousands = '0.0005',
  FiveThousands = '0.0002',
  TenThousands = '0.0001'
}

export enum EHandlerSegmentationAlgorithms {
  Li2012 = 'li2012',
  Dalponte2016 = 'dalponte2016',
  Silva2016 = 'silva2016',
  Watershed = 'watershed',
}

export enum EHandlerSegmentationSeasons {
  Summer = 'summer',
  Winter = 'winter',
}

export enum EHandlerSegmentationSources {
  TIF = 'tif',
  LAS = 'las',
}

// todo чистая фантазия
export interface IHandlerPointClass {
  class: EHandlerPointsClasses
  value: number
  locked: boolean
}

export enum EHandlerSettingModes {
  Auto = 'auto',
  Manual = 'manual',
}

export enum EHandlerForestZones {
  Tundra = 'tundra',
  Taiga = 'taiga',
  BroadLeaved = 'broad_leaved',
  Forest = 'forest',
  Steppe = 'steppe',
  Desert = 'desert',
  Caucasus = 'caucasus',
  Siberia = 'siberia'
}

export enum EHandlerForestRegions {
  Urals = 'urals',
  WestSiberia = 'west_siberia',
  MidSiberia = 'mid_siberia',
  FarEast = 'far_east',

  NorthTaiga = 'north_taiga',
  MidTaiga = 'mid_taiga',
  SouthTaiga = 'south_taiga',
  NorthUral = 'north_ural',
  MidUral = 'mid_ural',

  ConiferousDeciduous = 'coniferous-deciduous',
  PriamurskoPrimorsky = 'priamursko_primorsky',

  EuropeanForest = 'european_forest',
  SouthUral = 'south_ural',
  WestSiberian = 'west_siberian',
  CentralSiberian = 'central_siberian',

  EuropeanSteppes = 'european_steppes',

  EuropeanDesert = 'european_desert',

  NorthCaucasus = 'north_caucasus',

  AltaiSayanTaiga = 'altai_sayan_taiga',
  AltaiSayanForest = 'altai_sayan_forest',
  BaikalMountain = 'baikal_mountain',
  TransbaikalianPermafrost = 'transbaikalian_permafrost'
}

export interface IHandleConfig {
  preprocessing: IHandlePreprocessing
  classification: IHandleClassification
  heights: IHandleHeights
  lasSegmentation: IHandleLasSegmentation
  semanticClassification: IHandleSemanticClassification
}

export interface IHandlePreprocessing {
  format: string
  meanArea: number
  meanDensity: number
  approxScale: number
  heightScaleMin: number
  heightScaleMax: number
  primaryCrs: string
  goalCrs: string
  manualSetting?: boolean
  autoSetting?: boolean
  generateReviewVls?: boolean
  filtrationFlag: boolean
  filtration: string
  absHeightFiltration?: boolean
  minAbsHeight: number
  maxAbsHeight: number
  stdFiltration?: boolean
  std: number
  percentFiltration?: boolean
  minPercent: number
  maxPercent: number
  kMeansFiltration?: boolean
  pointNum: number
}

export interface IHandleClassification {
  classificationModel?: EHandlerOtClassificationModels
  defaultParams?: EHandlerClassificationDefaultParams
  spatialResolution?: number
  iterationNum?: number
  limitHeight?: number
  blockedClasses?: string[]
  aoi?: string
  window?: number
  slope?: number
  settingsMode: EHandlerSettingModes
}
export interface IHandleHeights {
  targetScale: EHandlerScales
  settingsMode: EHandlerSettingModes
  reliefResolution: number // Разрешение цифровой модели рельефа (м/пикс)
  canopyResolution: number // Разрешение цифровой модели насаждений
  interpolationRange: number // Интерполяция отсутствующих значений
  reliefSmoothWindow: number // Размер окна сглаживания модели рельефа
  aoi: string // AOI
  canopySmoothAlgorithm: EHandlerSmoothingAlgorithm // Алгоритм сглаживания median/gaussian/pit_free/''
  canopySmoothMedianWindow: number // Размер окна сглаживания модели насаждений
  slopeFlag: boolean // Уклон склонов (градусы)
  slopeResolution: number // Разрешение Уклон склонов
  expositionFlag: boolean // Экспозиция
  expositionResolution: number // Разрешение Экспозиция
  itpFlag: boolean // Индекс топографического положения
  itpResolution: number // Разрешение Индекс топографического положения
  heightMarksFlag: boolean // Отметки высот
  heightMarksNum: number // Количество отметок на дм карты
  // Горизонтали
  height05Flag: boolean
  height1Flag: boolean
  height2Flag: boolean
  height5Flag: boolean
  height10Flag: boolean
}
export interface IHandleLasSegmentation {
  segmentationAlgorithm: EHandlerSegmentationAlgorithms // Алгоритм сегментации
  defaultParams: string // ? 'sparse_trees', 'thickened_young', 'ripe_forest'
  season: EHandlerSegmentationSeasons // Формат съемки // 'summer', 'winter'
  source: EHandlerSegmentationSources // Метод съемки // 'las', 'tif'
  localMaxWindow: number // Окно поиска
  localMaxMinHeight: number // Минимальная высота деревьев
  localMaxAxDiameter: number // Максимальный диаметр крон
  lasModellingRadius: number // Радиус поиска
  minHeight: number // Минимальная высота
  maxDiameter: number // Максимальный диаметр крон
  settingsMode: EHandlerSettingModes
}
export interface IHandleSemanticClassification {
  forestZone: EHandlerForestZones // Лесорастительная зоны
  forestRegion: EHandlerForestRegions // Лесорастительный район
  step?: number
  xMin: number
  xMax: number
  yMin: number
  yMax: number

  windfallFlag?: boolean // not used
  deadwoodFlag?: boolean // not used
  fireFlag?: boolean // not used
  roadsFlag?: boolean // not used
  buildingsFlag?: boolean // not used
  waterFlag?: boolean // not used
  swampsFlag?: boolean // not used
}

export interface IHandler {
  title: string
  createAt: string
  status: EHandlerStatus
  step: EHandlerSteps
  config: IHandleConfig
  projectId: number
  id: number | string
  hasUpdates: boolean
}

export interface IGetHandlersByProjectParams extends IDefaultCollectionRequestParams {
  projectId: string
  search?: string
  startDate?: string
  endDate?: string
  status?: EHandlerStatus
}

export interface ICreateHandlerParams extends Partial<IHandler> {
  projectId: number
}

export interface IUpdateHandlerParams extends Omit<ICreateHandlerParams, 'projectId'> {
  id: number | string
}

export type IStartHandlerParams = Partial<IHandler> & {
  id: number | string
}

export interface IDeleteHandlerParams {
  id: number | string
}

export interface IGetHandlerParams {
  id: number | string
}

export interface IGetLasFilesHeadersParams {
  projectName: string
  handlerName: string
}

export interface ILasFilesHeaders {
  format?: string[]
  area?: number
  density?: number
  scale?: string
  heightLimits?: number[]
  xMin: number
  xMax: number
  yMin: number
  yMax: number
}
