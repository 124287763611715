import { PlusCircleIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Empty, Text } from 'components'

import styles from './styles.module.scss'
import { IProjectsEmptyProps } from './types'

export function ProjectsEmpty(props: IProjectsEmptyProps) {
	const { t } = useTranslation('projects')
	const { onCreate } = props

	return (
		<Empty
			className={styles.root}
			image='/images/project.svg'
			imageStyle={{ height: 120 }}
			description={(
				<Text type='body16' style={{ whiteSpace: 'pre-line' }}>
					{t('There will be new projects that you will create here')}
				</Text>
			)}
		>
			<Button
				onClick={onCreate}
				icon={<PlusCircleIcon width={20} />}
				size='large'
			>
				{t('Create project')}
			</Button>
		</Empty>
	)
}
