import {
	BoundingSphere,
	Cartesian3,
	Entity,
} from 'cesium'
import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react'
import { useCesium } from 'resium'

import { IconButton, Space } from 'components'
import { IMeasureState } from 'features/map/blocks/Instruments/Measure/type'
import {
	copyEntity,
	formattingLabel,
	getLabelByPosition, getLeftRightLabelData, getLeftRightPositionsByGeometry
} from 'features/map/blocks/Instruments/Measure/utils'
import { useCreateFeature } from 'features/map/hooks'
import { EFeatureTypes } from 'features/map/types'
import { getAreaByPoints, getPositionsByGeometry } from 'features/map/utils'

export function Measure() {
	const { entityCollection } = useCesium()
	const labels = useRef<Entity[]>([])
	const tempLabel = useRef<Entity>()
	const tempFeatures = useRef<Entity[]>([])
	const tempAreaFirstLabel = useRef<Entity>()
	const tempAreaSizeLabel = useRef<Entity>()
	const [activeState, setActiveState] = useState<IMeasureState>()

	const onTrigger = useCallback((type?: IMeasureState) => () => {
		setActiveState((prevState?: IMeasureState) => {
			if (typeof type === 'boolean' || type === undefined) {
				return !prevState
			}
			if (type === prevState) {
				return true
			}
			return type
		})
	}, [setActiveState])

	const onClearCallback = useCallback(() => {
		labels.current?.forEach((item) => entityCollection?.remove(item))
		labels.current = []

		tempLabel.current && entityCollection?.remove(tempLabel.current)
		tempLabel.current = undefined

		tempFeatures.current?.forEach((item) => entityCollection?.remove(item))
		tempFeatures.current = []

		tempAreaFirstLabel.current && entityCollection?.remove(tempAreaFirstLabel.current)
		tempAreaFirstLabel.current = undefined

		tempAreaSizeLabel.current && entityCollection?.remove(tempAreaSizeLabel.current)
		tempAreaSizeLabel.current = undefined
	}, [entityCollection])

	const saveTempLabels = useCallback(() => {
		[tempLabel, tempAreaSizeLabel, tempAreaFirstLabel].forEach((ref) => {
			if (ref.current) {
				labels.current.push(ref.current)
			}
			ref.current = undefined
		})
	}, [])

	const setPointToPosition = useCallback((featureRef: MutableRefObject<Entity | undefined>, position: Cartesian3, size: number) => {
		if (!featureRef.current) {
			featureRef.current = entityCollection?.add(getLabelByPosition(position, formattingLabel(size)))
		}
		if (featureRef.current) {
			//@ts-ignore
			featureRef.current.position = position
			//@ts-ignore
			featureRef.current.label.text = formattingLabel(size)
		}
	}, [entityCollection])

	const setPointToPositionByLeftRight = useCallback((featureRef: MutableRefObject<Entity | undefined>, left: Cartesian3, right: Cartesian3) => {
		const [position, label] = getLeftRightLabelData(left, right)
		setPointToPosition(featureRef, position, label)
	}, [setPointToPosition])

	const onCreateFeatureCallback = useCallback((geometry: Entity, type: EFeatureTypes) => {
		setActiveState(true)

		const tempFeature = entityCollection?.add(copyEntity(geometry, type))
		tempFeatures.current.push(tempFeature as Entity)
		saveTempLabels()
	}, [saveTempLabels, entityCollection])

	const onMoveFeatureCallback = useCallback((geometry: Entity, type: EFeatureTypes) => {
		const [_left, right, temp, first] = getLeftRightPositionsByGeometry(geometry, type)
		if (temp && right) {
			setPointToPositionByLeftRight(tempLabel, right, temp)

			if (type === EFeatureTypes.Polygon) {
				setPointToPositionByLeftRight(tempAreaSizeLabel, temp, first)
				const areaPositions = getPositionsByGeometry(geometry, type)
				const labelArea = getAreaByPoints(areaPositions)
				const areaCenter = BoundingSphere.fromPoints(areaPositions).center

				setPointToPosition(tempAreaFirstLabel, areaCenter, labelArea)
			}
		}
	}, [setPointToPosition, setPointToPositionByLeftRight])

	const onChangeFeatureCallback = useCallback((geometry: Entity, type: EFeatureTypes) => {
		const [left, right] = getLeftRightPositionsByGeometry(geometry, type)
		if (left && right) {
			const [positions, size] = getLeftRightLabelData(left, right)
			const labelFeature = entityCollection?.add(getLabelByPosition(positions, formattingLabel(size)))
			labels.current?.push(labelFeature as Entity)
		}
	}, [entityCollection])

	const { activate, deactivate } = useCreateFeature({
		onCreate: onCreateFeatureCallback,
		onChange: onChangeFeatureCallback,
		onMove: onMoveFeatureCallback
	})

	useEffect(() => {
		if (typeof activeState === 'string' ) {
			activate(activeState)
		}
		return deactivate
	}, [deactivate, activate, activeState])

	return (
		<Space direction='vertical'>
			<IconButton
				iconSize={32}
				icon="measure"
				type={activeState ? 'primary' : undefined}
				onClick={onTrigger()}
			/>
			{activeState && (
				<>
					<IconButton iconSize={32} type={activeState === EFeatureTypes.Polyline ? 'primary' : undefined} onClick={onTrigger(EFeatureTypes.Polyline)} icon="line" />
					<IconButton iconSize={32} type={activeState === EFeatureTypes.Polygon ? 'primary' : undefined} onClick={onTrigger(EFeatureTypes.Polygon)} icon="area" />
					<IconButton iconSize={32} danger onClick={onClearCallback} icon="remove" />
				</>
			)}
		</Space>
	)
}
