import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Col, Row, Space } from 'components'
import { StorageBreadcrumbs } from 'features/data/blocks/StorageBreadcrumbs'
import { StorageFileList } from 'features/data/blocks/StorageFileList'
import { StorageUploadButton } from 'features/data/blocks/StorageUploadButton'
import { TBucketFile } from 'features/data/libs/BucketConnector/types'
import { useDataFileList } from 'features/data/store/hooks'
import { GlobalDrawer } from 'features/layouts/blocks/GlobalDrawer'
import { useModalState } from 'features/layouts/store/hooks'
import { ELayoutsModalEntities } from 'features/layouts/store/types'

import styles from './styles.module.scss'
import { IFileListDrawerGlobalData } from './types'

/**
 * Usage example

 const onSelectFilesCallback = useCallback((files: TBucketFile[]) => {
		console.log('files', files)
	}, [])

 const { openFileList } = useFileListDrawer(onSelectFilesCallback)

 */

export function FileListDrawerGlobal() {
	const { t } = useTranslation(['storage', 'actions'])
	const { data, isOpen, close, setData } = useModalState<IFileListDrawerGlobalData>(ELayoutsModalEntities.FileList)
	const isMultiple = data?.isMultiply
	const { fileList, path, setPath, addToPath } = useDataFileList({
		rootPath: data?.rootPath,
	})
	const [selectedFiles, setSelectedFiles] = useState<TBucketFile[]>([])

	const onSelectCallback = useCallback(() => {
		setData({ files: selectedFiles })
		close()
	}, [close, selectedFiles, setData])

	useEffect(() => {
		if (!isOpen) {
			setSelectedFiles([])
			setPath([])
		}
	}, [isOpen, setPath])

	return (
		<GlobalDrawer
			entity={ELayoutsModalEntities.FileList}
			width={700}
			title={t('Storage')}
			destroyOnClose
			extra={<StorageUploadButton path={path} />}
		>
			<Space
				isFullWidth
				direction='vertical'
				size='large'
				className={styles.space}
			>
				<Row wrap={false}>
					<Col flex={1}>
						<StorageBreadcrumbs
							fullPath={path}
							onNewPath={setPath}
						/>
					</Col>
					<Col>
						<Button
							type='primary'
							disabled={!selectedFiles?.length}
							onClick={onSelectCallback}
						>
							{t('Select')}
						</Button>
					</Col>
				</Row>
				<StorageFileList
					fileList={fileList}
					path={path}
					onOpenDirectory={addToPath}
					isMultipleSelect={isMultiple}
					onSelectFiles={setSelectedFiles}
					selectedFiles={selectedFiles}
				/>
			</Space>
		</GlobalDrawer>
	)
}
