import ruRu from 'antd/locale/ru_RU'
import i18next from 'i18next'
// import enGB from 'antd/locale/en_GB'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import 'dayjs/locale/ru'
import ru from 'assets/localization/ru.json'


i18next.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		resources: {
			ru,
		},
		fallbackLng: 'ru',
		fallbackNS: ['common', 'errors', 'actions', 'labels', 'placeholders', 'storage', 'map', 'calendar', 'units'],
		interpolation: {
			escapeValue: false,
		},
		react: {
			useSuspense: true,
		},
	})

// todo открыть когда подтянем en
// export const changeLanguage = () => {
// 	if (i18n.resolvedLanguage === 'ru') {
// 		i18n.changeLanguage('en')
// 	} else {
// 		i18n.changeLanguage('ru')
// 	}
// 	location.reload()
// }

export const i18n = i18next
export const getAntdLocale = (language: string) => {
	switch (language) {
	case 'ru':
	case 'ru-RU': {
		return ruRu
	}
	default: {
		return ruRu
	}
	}
}
