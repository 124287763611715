import classNames from 'classnames'
import React from 'react'

import { Badge } from 'components/Badge'

import styles from './styles.module.scss'
import { IActionIndicatorProps } from './types'

export function ActionIndicator(props: IActionIndicatorProps){
	const { isActive, className, children = null } = props

	if (isActive) {
		return (
			<>
				<Badge
					color="warning"
					borderless
					offset={[0, 0]}
					dot
					status='warning'
					dotSize={8}
					className={classNames(
						{
							[className ?? '']: !!className,
							[styles.hasChildren]: !!children
						}
					)} />
				{children}
			</>
		)
	} else {
		return (
			<>
				{children}
			</>
		)
	}
}
