import classNames from 'classnames'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ESpacer } from 'assets/theme/variables/spacers'
import { Col, IconButton, Row, Text } from 'components'
import { PopoverDialog } from 'components/PopoverDialog'
import { BASE_LAYERS } from 'features/map/blocks/Instruments/BaseLayerSwitcher/constants'
import { useMutableBaseLayer } from 'features/map/store/hooks'


import styles from './styles.module.scss'

export function BaseLayerSwitcher() {
	const { t } = useTranslation('map')
	const [baseLayer, setBaseLayer] = useMutableBaseLayer()

	const layerList = useMemo(() => {
		return (
			<>
				{BASE_LAYERS.map(({ image, key, title }) => (
					<Row
						className={classNames(styles.item, {
							[styles.selected]: baseLayer === key,
						})}
						gutter={ESpacer.SPACER3}
						key={key}
						onClick={() => setBaseLayer(key)}
					>
						<Col>
							<div
								className={classNames(styles.itemIconImage, 'item-icon-image')}
								style={{ backgroundImage: `url(${image})` }}
							/>
						</Col>
						<Col>
							<Text type='body16'>
								{title}
							</Text>
						</Col>
					</Row>
				))}
			</>
		)
	}, [baseLayer, setBaseLayer])

	return (
		<>
			<PopoverDialog title={t('Base map')} content={layerList}>
				<IconButton icon="base-map" />
			</PopoverDialog>
		</>
	)
}