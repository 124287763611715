import { combinePathSearch } from 'common/utils/path'
import { IHandlerPageUrlParams } from 'pages/Handler/types'

export const ROUTES = {
	Index: '/',
	Root: '/',
	HandlerPage: '/handlers/:id?',
	Projects: '/projects',
	Project: '/projects/:id',
	Storage: '/storage',
}

export enum EProjectResources {
	Map = 'map',
	Handlers = 'handlers',
}

export const ROUTES_ENTITIES = {
	getHandlerPage: (id: string | number = '', params?: IHandlerPageUrlParams) => combinePathSearch(
		ROUTES.HandlerPage.replace(':id?', id?.toString()),
		params
	),
	getProjectPage: (id: string, resource: EProjectResources = EProjectResources.Handlers) => `${ROUTES.Project.replace(':id', id)}/${resource}`
}
