import {
	CallbackProperty,
	Cartesian3,
	Entity,
	PolygonHierarchy,
	ScreenSpaceEventHandler,
	ScreenSpaceEventType,
	defined,
} from 'cesium'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useCesium } from 'resium'

import { POINT_PROPS } from 'features/map/constants'
import {
	EFeatureTypes,
	ICreateFeatureOptions,
	ICreateFeatureReturn,
} from 'features/map/types'
import { getFeatureByType } from 'features/map/utils'

export const useCreateFeature = (
	options: ICreateFeatureOptions
): ICreateFeatureReturn => {
	const { viewer, camera, scene, entityCollection } = useCesium()
	const { onCreate, onChange, onMove } = options
	const cursorPoint = useRef<Entity>()
	const tempCoordinates = useRef<Cartesian3[]>([])
	const rawFeature = useRef<Entity>()
	const rawPoints = useRef<Entity[]>([])
	const [selectedType, setSelectedType] = useState<EFeatureTypes | null>(null)
	const trottlingClickState = useRef(false)

	const moveRawFeature = useCallback((position: Cartesian3) => {
		tempCoordinates.current.pop()
		tempCoordinates.current.push(position)
	}, [])

	const movePoint = useCallback((position: Cartesian3) => {
		if (cursorPoint.current) {
			// @ts-ignore
			cursorPoint.current.position = position
		}
	}, [])

	const appRawPoint = useCallback(
		(position: Cartesian3) => {
			const entity = entityCollection?.add({
				position,
				point: POINT_PROPS,
			})

			if (entity instanceof Entity) {
				rawPoints.current?.push(entity)
			}
		},
		[entityCollection]
	)

	const removeFeatureCallback = useCallback(() => {
		rawFeature.current && entityCollection?.remove(rawFeature.current)
		cursorPoint.current && entityCollection?.remove(cursorPoint.current)
		rawPoints.current?.forEach((point) => entityCollection?.remove(point))

		rawFeature.current = undefined
		rawPoints.current = []
		tempCoordinates.current = []
		cursorPoint.current = undefined
	}, [entityCollection])

	const onDeactivateCallback = useCallback(() => {
		removeFeatureCallback()
		setSelectedType(null)
	}, [removeFeatureCallback])

	const onActivateCallback = useCallback(
		(type: EFeatureTypes) => {
			onDeactivateCallback()
			setSelectedType(type)

			const dynamicPositions = new CallbackProperty(function () {
				if (type === EFeatureTypes.Polygon) {
					return new PolygonHierarchy(tempCoordinates.current)
				}
				return tempCoordinates.current
			}, false)

			rawFeature.current = entityCollection?.add(
				getFeatureByType(type, dynamicPositions)
			)
			cursorPoint.current = entityCollection?.add({
				point: POINT_PROPS,
			})
		},
		[entityCollection, onDeactivateCallback]
	)
	const onDrawStopCallback = useCallback(() => {
		if (rawFeature.current && selectedType) {
			const feature = new Entity()
			feature.merge(rawFeature.current)
			onCreate?.(feature, selectedType)
		}
		onDeactivateCallback()
	}, [selectedType, onCreate, onDeactivateCallback])

	const onDrawCallback = useCallback(
		(e: ScreenSpaceEventHandler.PositionedEvent) => {
			if (trottlingClickState.current) {
				onDrawStopCallback()
				return
			}

			trottlingClickState.current = true
			setTimeout(() => {
				trottlingClickState.current = false
			}, 300)

			const earthPosition = camera?.pickEllipsoid(e.position)
			if (
				earthPosition &&
                defined(earthPosition) &&
                tempCoordinates.current
			) {
				if (selectedType === EFeatureTypes.Point) {
					if (rawFeature.current) {
						// @ts-ignore
						rawFeature.current.position = earthPosition
					}
				} else {
					tempCoordinates.current?.push(earthPosition)
					appRawPoint(earthPosition)
				}
				rawFeature.current && onChange?.(rawFeature.current, selectedType!, earthPosition)
			}
		},
		[appRawPoint, camera, onChange, onDrawStopCallback, selectedType]
	)


	const onMouseMoveCallback = useCallback(
		(e: ScreenSpaceEventHandler.MotionEvent) => {
			const earthPosition = camera?.pickEllipsoid(e.endPosition)
			if (earthPosition && defined(earthPosition) && selectedType) {
				moveRawFeature(earthPosition)
				movePoint(earthPosition)
				rawFeature?.current && onMove?.(rawFeature?.current, selectedType!, earthPosition)
			}
		},
		[camera, selectedType, moveRawFeature, movePoint, onMove]
	)

	useEffect(() => {
		const handler = new ScreenSpaceEventHandler(scene?.canvas)
		if (selectedType) {
			handler.setInputAction(
				onDrawCallback,
				ScreenSpaceEventType.LEFT_CLICK
			)
			handler.setInputAction(
				onDrawStopCallback,
				ScreenSpaceEventType.RIGHT_CLICK
			)
			handler.setInputAction(
				onMouseMoveCallback,
				ScreenSpaceEventType.MOUSE_MOVE
			)
		}
		return () => {
			handler?.removeInputAction(ScreenSpaceEventType.LEFT_CLICK)
			handler?.removeInputAction(ScreenSpaceEventType.RIGHT_CLICK)
			handler?.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE)
		}
	}, [
		selectedType,
		camera,
		removeFeatureCallback,
		scene,
		viewer,
		onDrawCallback,
		onDrawStopCallback,
		onMouseMoveCallback,
	])

	return useMemo<ICreateFeatureReturn>(
		() => ({
			activeType: selectedType,
			activate: onActivateCallback,
			deactivate: onDeactivateCallback,
		}),
		[selectedType, onActivateCallback, onDeactivateCallback]
	)
}
