import { api } from 'services'
import { cacheByIdArgProperty, providesList } from 'services/rtkQueryCacheUtils'
import { EServiceTags, IDefaultCollection } from 'services/types'
import { transformRequest, transformResponse } from 'services/utils'

import {
	ICreateProjectParams,
	IDeleteProjectParams,
	IGetProjectParams,
	IGetProjectsParams,
	IProject,
	IUpdateProjectParams
} from './types'

export const projectsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getProjects: builder.query<IDefaultCollection<IProject>, IGetProjectsParams>({
			query: (params) => ({
				url: '/api/v1/projects/',
				params: transformRequest(params),
			}),
			transformResponse,
			providesTags: (result: IDefaultCollection<IProject> | undefined, error) => providesList(EServiceTags.Projects)(
				result,
				error
			),
		}),
		getProject: builder.query<IProject, IGetProjectParams>({
			query: ({ id }) => ({
				url: `/api/v1/projects/${id}`,
			}),
			transformResponse,
			providesTags: (result, error, args: IGetProjectParams) => cacheByIdArgProperty(EServiceTags.Projects)(result, error, {
				id: args.id,
			}),
		}),
		createProject: builder.mutation<IProject, ICreateProjectParams>({
			query: (body) => ({
				url: '/api/v1/projects/',
				method: 'POST',
				body: transformRequest(body),
			}),
			invalidatesTags: [EServiceTags.Projects],
		}),
		updateProject: builder.mutation<IProject, IUpdateProjectParams>({
			query: ({ id, ...body }) => ({
				url: `/api/v1/projects/${id}`,
				method: 'PUT',
				body: transformRequest(body),
			}),
			invalidatesTags: [EServiceTags.Projects],
		}),
		deleteProject: builder.mutation<void, IDeleteProjectParams>({
			query: ({ id }) => ({
				url: `/api/v1/projects/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: [EServiceTags.Projects],
		}),
	}),
	overrideExisting: false,
})
