import React from 'react'

import { Drawer } from 'components'
import { useModalState } from 'features/layouts/store/hooks'

import { IGlobalDrawerProps } from './types'

export function GlobalDrawer(props: IGlobalDrawerProps) {
	const { entity, ...drawerProps } = props
	const { isOpen, close } = useModalState(entity)

	return (
		<Drawer
			open={isOpen}
			onClose={close}
			destroyOnClose
			width={500}
			{...drawerProps}
		/>
	)
}
