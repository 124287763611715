import React from 'react'

import { InfoDescriptionField } from './InfoDescriptionField'
import { InfoDescriptionFieldTitle } from './InfoDescriptionFieldTitle'
import { InfoDescriptionFieldValue } from './InfoDescriptionFieldValue'
import { IInfoDescriptionFormFieldProps } from './types'

export function InfoDescriptionFormField(props: IInfoDescriptionFormFieldProps) {
	const { label, value, id, formatViewValue } = props

	return (
		<InfoDescriptionField id={id}>
			<InfoDescriptionFieldTitle label={label} />
			<InfoDescriptionFieldValue
				formatViewValue={formatViewValue}
				value={value}
			/>
		</InfoDescriptionField>
	)
}
