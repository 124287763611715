import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { EHandlerSteps } from 'services/handlers/types'

import { IHandlersStore, ISetPrimaryDataActionPayload } from './types'

const initialState: IHandlersStore = {
	currentStep: undefined,
	primaryData: undefined,
}

export const handlersSlice = createSlice({
	name: 'handlersSlice',
	initialState: {
		...initialState,
	},
	reducers: {
		setPrimaryData: (state, action: PayloadAction<ISetPrimaryDataActionPayload>) => {
			const { step, ...rest } = action.payload

			state.primaryData = rest
			state.currentStep = step
		},
		setStep: (state, action: PayloadAction<EHandlerSteps | undefined>) => {
			state.currentStep = action.payload
		}
	},
})
