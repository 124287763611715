import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { IFormErrorState, IFormErrorStateArray, IFormHookOptions } from 'common/types/form'
import { EHttpCodes, EServiceErrors } from 'common/types/services'
import { normalizeFormError } from 'common/utils/form'
import { normalizeObject } from 'common/utils/normalization'
import { Form, message } from 'components'

export function useForm(rawState: unknown | unknown[], options?: IFormHookOptions) {
	const { t } = useTranslation('errors')
	const [form] = Form.useForm()
	const { defaultMessage = t('notFound.The service is temporarily unavailable, please try again later'), values } = options || {}

	const state = useMemo(() => {
		const stateArray = Array.isArray(rawState) ? rawState : [rawState]

		return stateArray.reduce<IFormErrorStateArray>(
			(acc: IFormErrorStateArray, stateUnit: IFormErrorState) => ({
				isError: Boolean(acc.isError || stateUnit.isError),
				errors: stateUnit.error ? [...acc?.errors || [], stateUnit.error] : acc.errors,
			}),
			{
				errors: [],
			}
		)
	}, [rawState])

	useEffect(() => {
		const { isError, errors } = state

		if (isError) {
			if (errors?.length) {
				errors?.forEach((error) => {
					switch (true) {
					case error?.status === EHttpCodes.NotAuthorized:

						// eslint-disable-next-line no-fallthrough
					case error?.originalStatus === EHttpCodes.InternalServerError: {
						void message.error(t('notFound.The service is temporarily unavailable, please try again later'))
						break
					}

					case 'data' in error: {
						const { data } = error

						if (typeof data === 'string') {
							void message.error(data)
						}

						if (data) {
							const content = normalizeFormError(data)

							form.setFields(content)
						}

						break
					}

					case error?.status === EServiceErrors.FetchError: {
						void message.error(t('notFound.The service is unavailable, check for an internet connection or try again later'))
						break
					}

					default: {
						void message.error(defaultMessage)
					}
					}
				})
			}
		}
	}, [
		defaultMessage,
		form,
		state,
		t
	])

	const initForm = useCallback(() => {
		form?.resetFields()
		form?.setFieldsValue({
			...values,
			...normalizeObject(form.getFieldsValue()),
		})
	}, [form, values])

	useEffect(() => {
		return () => {
			form?.resetFields()
		}
	}, [form])

	useEffect(() => {
		if (values) {
			initForm()
		}
	}, [values, initForm, form])

	return [form]
}
