import { QuestionCircleOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import React from 'react'

import { Popover, Space } from 'components'

import styles from './styles.module.scss'
import { IHintProps } from './types'

export function Hint(props: IHintProps) {
	const { children, hint, size = 'default', spaceSize } = props

	return (
		<Space
			size={spaceSize}
			align='center'
		>
			{children}
			{hint && (
				<Popover
					content={hint}
					trigger={['click', 'hover']}
					placement='right'
					overlayClassName={styles.popover}
				>
					<QuestionCircleOutlined
						rev='hint'
						className={classNames(styles.hint, {
							[styles[size]]: Boolean(size),
						})}
					/>
				</Popover>
			)}
		</Space>
	)
}
