import React from 'react'

import { Text } from 'components'

import styles from './styles.module.scss'
import { IInfoDescriptionFieldTitleProps } from './types'

export function InfoDescriptionFieldTitle(props: IInfoDescriptionFieldTitleProps) {
	const { label } = props

	return (
		<Text
			typographyType='secondary'
			className={styles.fieldTitle}
		>
			{label}
		</Text>
	)
}
