import { Cartographic, Math as CesiumMath, ScreenSpaceEventHandler, ScreenSpaceEventType, defined } from 'cesium'
import React, { useCallback, useDeferredValue, useEffect, useState } from 'react'
import { useCesium } from 'resium'

import { Text } from 'components'


import styles from './styles.module.scss'

export function PositionDisplay() {
	const { scene, camera } = useCesium()
	const [position, setPosition] = useState<Cartographic>()
	const deferredPosition = useDeferredValue(position)
	const onMouseMoveCallback = useCallback((e: ScreenSpaceEventHandler.MotionEvent) => {
		const earthPosition = camera?.pickEllipsoid(e.endPosition)
		if (earthPosition && defined(earthPosition)) {
			setPosition(Cartographic.fromCartesian(earthPosition))
		}
	}, [camera])

	useEffect(() => {
		const handler = new ScreenSpaceEventHandler(scene?.canvas)

		handler.setInputAction(onMouseMoveCallback, ScreenSpaceEventType.MOUSE_MOVE)
		return () => {
			handler?.removeInputAction(ScreenSpaceEventType.MOUSE_MOVE)
		}
	}, [onMouseMoveCallback, scene?.canvas])

	if (!deferredPosition) {
		return null
	}

	return (
		<Text
			type='body10'
			className={styles.root}
		>
			{CesiumMath.toDegrees(Number(deferredPosition.longitude)).toFixed(4)}
			{' '}
			{CesiumMath.toDegrees(Number(deferredPosition.latitude)).toFixed(4)}
		</Text>
	)
}
