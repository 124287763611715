import React from 'react'

import { useBooleanFlag } from 'common/hooks/boolean'
import { IconButton } from 'components'
import { LayerSwitcherContent } from 'features/map/blocks/Instruments/LayerSwitcher/LayerSwitcherContent'

export function LayerSwitcher() {
	const [isActive, setIsActive] = useBooleanFlag()

	if(isActive) {
		return <LayerSwitcherContent onClose={setIsActive} />
	}

	return (
		<IconButton onClick={setIsActive} icon='layers' />
	)
}
