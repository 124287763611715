import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline'
import React, { JSX } from 'react'
import { useTranslation } from 'react-i18next'

import { IPaginationItemProps } from 'components/Table/types'
import { Text } from 'components/Text'

export function PaginationItem(props: IPaginationItemProps): JSX.Element {
	const { t } = useTranslation('actions')
	const { type, originalElement } = props

	switch (type) {
	case 'prev': {
		return (
			<Text.Link>
				<ArrowLeftIcon width={16}/>&emsp;Назад
			</Text.Link>
		)
	}
	case 'next': {
		return (
			<Text.Link>
				{t('Forward')}&emsp;<ArrowRightIcon width={16}/>
			</Text.Link>
		)
	}

	default: {
		return originalElement as JSX.Element
	}
	}
}
