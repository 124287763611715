import React from 'react'
import InputMaskComponent from 'react-input-mask'

import { Input } from 'components'
import { IInputProps } from 'components/Input/types'

import { IInputMaskProps } from './types'

export function InputMask({ mask, disabled, ...props }: IInputMaskProps) {
	return (
		<InputMaskComponent
			mask={mask}
			disabled={disabled}
			maskChar={null} // Это позволяет использовать autofill браузера
			{...props}
		>
			{(inputProps: IInputProps) => (
				<Input
					disabled={disabled}
					{...inputProps}
				/>
			)}
		</InputMaskComponent>
	)
}
