import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FormGenerator } from 'components'
import { EFormTypes, IFormScheme } from 'components/FormGenerator/types'
import { HandlerStepBlock } from 'features/handlers/blocks/HandlerStepBlock'

export function ReliefModel() {
	const { t } = useTranslation('handlers')

	const scheme = useMemo<IFormScheme[]>(() => [
		{
			isVertical: true,
			name: 'reliefResolution',
			type: EFormTypes.Number,
			label: <span>{t('Digital elevation model resolution')}</span>,
			required: true,
			inputProps: {
				size: 'small',
			},
		},
		{
			isVertical: true,
			name: 'reliefSmoothWindow',
			type: EFormTypes.Number,
			label: <span>{t('Smoothing window size')}</span>,
			required: true,
			inputProps: {
				size: 'small',
			},
		},
		{
			isVertical: true,
			name: 'interpolationRange',
			type: EFormTypes.Number,
			label: <span>{t('Interpolation of missing values')}</span>,
			required: true,
			inputProps: {
				size: 'small',
			},
		},
	], [t])

	return (
		<HandlerStepBlock title={t('Digital elevation model')}>
			<FormGenerator scheme={scheme} />
		</HandlerStepBlock>
	)
}
