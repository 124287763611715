import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { IUseDataFileListOptions } from 'features/data/store/types'

import { getDataFileList } from './selectors'

export const useDataFileList = (options?: IUseDataFileListOptions) => {
	const fileList = useSelector(getDataFileList)
	const [path, setPath] = useState<string[]>(options?.rootPath || [])

	useEffect(() => {
		if (options?.rootPath?.length) {
			setPath(options.rootPath)
		}
	}, [options?.rootPath])

	const addToPathCallback = useCallback((name: string) => {
		setPath((oldPath) => [...oldPath, name])
	}, [setPath])

	const onSetPathCallback = useCallback((newPath: string[]) => {
		if (!options?.rootPath?.length || newPath.length >= options.rootPath.length) {
			setPath(newPath)
		}
	}, [setPath, options?.rootPath?.length])

	return useMemo(() => ({
		fileList,
		path,
		setPath: onSetPathCallback,
		addToPath: addToPathCallback,
	}), [fileList, path, addToPathCallback, onSetPathCallback])
}
