import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { EFormTypes, IFormScheme } from 'components/FormGenerator/types'
import { HandlerStepBase } from 'features/handlers/blocks/HandlerStepBase'
import { HANDLER_STEPS_ORDER } from 'features/handlers/constants'
import { useHandlerContext } from 'features/handlers/hooks'
import {
	EHandlerSegmentationAlgorithms,
	EHandlerSettingModes,
	EHandlerSteps,
	IHandleLasSegmentation
} from 'services/handlers/types'

import { LAS_SEGMENTATION_STEP_INITIAL_VALUES } from './constants'
import { LocalMax } from './LocalMax'
import { Settings } from './Settings'
import { Simulation } from './Simulation'
import { ILasSegmentationStepFormValues } from './types'

const FORM_NAME = 'handlerSegmentationStep'
const shouldShowConfig = (values: IHandleLasSegmentation) => values.settingsMode === EHandlerSettingModes.Manual
const shouldShowDependencies = ['settingsMode', 'segmentationAlgorithm']

export function HandlerLasSegmentationStep() {
	const { t } = useTranslation('handlers')
	const { onFinish, handlerData } = useHandlerContext()

	const values = useMemo(() => ({
		...LAS_SEGMENTATION_STEP_INITIAL_VALUES,
		...handlerData?.config?.lasSegmentation,
	}), [handlerData])

	const scheme = useMemo<IFormScheme[]>(() => [
		{
			name: '',
			type: EFormTypes.Custom,
			component: <Settings />,
		},
		{
			name: '',
			type: EFormTypes.Custom,
			component: <LocalMax />,
			shouldShow: (values: IHandleLasSegmentation) =>
				shouldShowConfig(values) && !values.segmentationAlgorithm && values.segmentationAlgorithm !== EHandlerSegmentationAlgorithms.Li2012,
			dependencies: shouldShowDependencies,
		},
		{
			name: '',
			type: EFormTypes.Custom,
			component: <Simulation />,
			shouldShow: (values: IHandleLasSegmentation) => shouldShowConfig(values) && values.segmentationAlgorithm === EHandlerSegmentationAlgorithms.Li2012,
			dependencies: shouldShowDependencies,
		},
	], [])

	const onFinishCallback = useCallback((values: ILasSegmentationStepFormValues) => {
		onFinish?.({
			lasSegmentation: {
				...LAS_SEGMENTATION_STEP_INITIAL_VALUES,
				...values
			},
		})
	}, [onFinish])

	return (
		<HandlerStepBase<ILasSegmentationStepFormValues>
			stepTitle={t(`stepsTitles.${EHandlerSteps.LasSegmentation}`)}
			hint={t(`hints.${EHandlerSteps.LasSegmentation}`)}
			step={HANDLER_STEPS_ORDER.indexOf(EHandlerSteps.LasSegmentation) + 1}
			scheme={scheme}
			formName={FORM_NAME}
			onFinish={onFinishCallback}
			values={values}
		/>
	)
}
