import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ROUTES } from 'app/router/constants'
import { ESpacer } from 'assets/theme/variables/spacers'
import { logout } from 'common/utils/keycloak'
import { Avatar, Col, Dropdown, Row } from 'components'
import { StorageIcon } from 'features/data/ui/StorageIcon'

import styles from './styles.module.scss'
import { IHeaderProps } from './types'

export default function Header(props: IHeaderProps) {
	const { t } = useTranslation(['actions', 'labels'])
	const { children } = props

	const onLogoutCallback = useCallback(() => {
		void logout()
	}, [])

	const menu = useMemo(() => ({
		items: [
			{
				key: 'logout',
				label: t('Exit'),
				onClick: onLogoutCallback,
			}
		]
	}), [onLogoutCallback, t])

	return (
		<Row
			wrap={false}
			gutter={ESpacer.SPACER5}
			className={styles.root}
			align='middle'
		>
			<Row
				wrap={false}
				gutter={80}
				align='middle'
			>
				<Col className={styles.menu}>
					<Link to={ROUTES.Root}>
						<img alt={t('Logo')} src='/images/logo_light.svg' />
					</Link>
				</Col>
				<Col hidden={!children}>
					{children}
				</Col>
			</Row>
			<Col flex={1}>
				<Row
					align='middle'
					justify='end'
					gutter={ESpacer.SPACER6}
				>
					<Col>
						<StorageIcon />
					</Col>
					<Col>
						<Dropdown
							trigger={['click']}
							menu={menu}
						>
							<Avatar className={styles.avatar}>
								{t('User name', {name: 'Иван Иванов'})}
							</Avatar>
						</Dropdown>
					</Col>
				</Row>
			</Col>
		</Row>
	)
}
