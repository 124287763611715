import dayjs, { ConfigType } from 'dayjs'

import { i18n } from 'app/localization'
import {
	DATE_FORMAT_WITH_TIME,
	DATE_WITHOUT_YEAR_FORMAT,
	FULL_DATE_FORMAT,
	SIMPLE_DATE_FORMAT,
} from 'common/constants/date'
import { DefaultOptionType } from 'components'

const { t } = i18n

export const formattingDate = (value: ConfigType) => (value ? dayjs(value).format(SIMPLE_DATE_FORMAT) : undefined)
export const formattingWithoutYearDate = (value: ConfigType) => (value ? dayjs(value).format(DATE_WITHOUT_YEAR_FORMAT) : undefined)
export const formattingFullDate = (value: ConfigType) => (value ? dayjs(value).format(FULL_DATE_FORMAT) : undefined)
export const formattingDateWithTime = (value: ConfigType) => (value ? dayjs(value).format(DATE_FORMAT_WITH_TIME) : undefined)

export const formattingDateCalendar = (value: ConfigType) => dayjs(value).calendar(null, {
	sameDay: t('calendar:sameDay', { time: 'H:mm' }),
	nextDay: t('calendar:nextDay', { time: 'H:mm' }),
	nextWeek: t('calendar:nextWeek', { weekday: 'dddd', time: 'H:mm' }),
	lastDay: t('calendar:lastDay', { time: 'H:mm' }),
	lastWeek: t('calendar:lastWeek', { weekday: 'dddd', time: 'H:mm' }),
	sameElse: t('calendar:sameElse', { date: 'DD.MM.YYYY' }),
})

export const formattingCountString = (
	value: number,
	templates: [string, string, string],
	displayValue?: string
): string => {
	const [
		first,
		second,
		third,
	] = templates

	const DIVIDER = 10
	const DOZENS_LOW = 10
	const DOZENS_HIGH = 20
	const BASIC_LOW = 1
	const BASIC_HIGH = 5
	const count = Math.abs(value) % 100
	const num = count % DIVIDER
	let template = ''

	switch (true) {
	case count > DOZENS_LOW && count < DOZENS_HIGH: {
		template = third
		break
	}

	case num > BASIC_LOW && num < BASIC_HIGH: {
		template = second
		break
	}

	case num === BASIC_LOW: {
		template = first
		break
	}

	default: {
		template = third
		break
	}
	}

	return template.replace('{count}', displayValue ?? value?.toString()).toString()
}

export function formattingFileSize(bytes: number, fraction = 2): string {
	const THRESH = 1024
	const UNITS = [t('units:KB'), t('units:MB'), t('units:GB'), t('units:TB')]

	if (Math.abs(bytes) < THRESH) {
		return `${bytes} ${t('units:B')}`
	}

	let unitIndex = -1
	const r = 10 ** fraction

	do {
		bytes /= THRESH
		++unitIndex
	} while (Math.round(Math.abs(bytes) * r) / r >= THRESH && unitIndex < UNITS.length - 1)

	return `${bytes.toFixed(fraction)} ${UNITS[unitIndex]}`
}

export const formattingLabels2Options = (labelMap: Record<string, string | DefaultOptionType>): DefaultOptionType[] => {
	const items = Object.entries(labelMap)

	return items.map(([key, value]) => {
		if (typeof value === 'string') {
			return ({
				label: value,
				value: key,
			})
		}

		return ({
			...value,
			value: key,
		})
	})
}
