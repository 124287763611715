import { api } from 'services'
import { getLayerTagId } from 'services/maps/utils'
import { cacheByIdArgProperty, providesList } from 'services/rtkQueryCacheUtils'
import { EServiceTags, IDefaultCollection } from 'services/types'
import { transformRequest, transformResponse } from 'services/utils'

import {
	IChangeMapLayerRequestParams,
	IChangeMapRequestParams,
	IMap,
	IMapLayer,
} from './types'

export const mapsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getMap: builder.query<IMap, number>({
			query: (mapId: number) => ({
				url: `/api/v1/maps/${mapId}`,
			}),
			transformResponse,
			providesTags: (result, error, arg: number) => cacheByIdArgProperty(EServiceTags.Maps)(result, error, {
				id: arg,
			}),
		}),
		changeMap: builder.mutation<IMap, IChangeMapRequestParams>({
			query: ({ id, ...body }) => ({
				url: `/api/v1/maps/${id}`,
				method: 'PUT',
				body: transformRequest(body),
			}),
			invalidatesTags: (result, error, arg) => [{ type: EServiceTags.Maps, id: arg.id }]
		}),
		getLayers: builder.query<IMapLayer[], number>({
			query: (mapId: number) => ({
				url: `/api/v1/maps/${mapId}/layers/`,
			}),
			transformResponse,
			providesTags: (result, error, arg: number) => result?.map((layer) => (
				{ type: EServiceTags.Layers, id: getLayerTagId(arg, layer.id) }
			)) ?? [],
		}),
		changeLayer: builder.mutation<IMapLayer, IChangeMapLayerRequestParams>({
			query: ({ layerId, mapId, ...body }) => ({
				url: `/api/v1/maps/${mapId}/${layerId}`,
				method: 'PUT',
				body: transformRequest(body),
			}),
			invalidatesTags: (result, error, arg) => [{ type: EServiceTags.Layers, id: getLayerTagId(arg.mapId, arg.layerId) }]
		}),
	}),
	overrideExisting: false,
})
