import { useEffect, useRef, useState } from 'react'

import { useBooleanFlag } from 'common/hooks/boolean'
import { getImageContent } from 'common/utils/image'

export const useLazyImage = (
	src?: string | ArrayBuffer
): {
  image: string | ArrayBuffer | undefined
  isLoading: boolean
  isSuccess: boolean
  isError: boolean
} => {
	const isMounted = useRef<boolean>(true)
	const [isLoading, setLoading] = useBooleanFlag(true)
	const [isSuccess, setSuccess] = useBooleanFlag(false)
	const [isError, setError] = useBooleanFlag(false)
	const [image, setImage] = useState<string | ArrayBuffer>()

	useEffect(
		() => () => {
			isMounted.current = false
		},
		[]
	)

	useEffect(() => {
		const getImage = async () => {
			setLoading(true)
			setImage(undefined)
			setError(undefined)
			setSuccess(undefined)

			try {
				if (typeof src === 'string') {
					const data = await getImageContent(src)

					if (isMounted.current) {
						setImage(data)
					}
				} else if (isMounted.current) {
					setImage(src)
				}

				setSuccess(true)
			} catch (e) {
				setImage(src)
				console.error(e)
				setError(true)
			} finally {
				setLoading(false)
			}
		}

		void getImage()
	}, [
		setError,
		setLoading,
		setSuccess,
		src,
	])

	return {
		image,
		isError,
		isLoading,
		isSuccess,
	}
}
