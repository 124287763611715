import { useCallback, useMemo } from 'react'

import { IUploadListGlobalData } from 'features/data/blocks/UploadListGlobal/types'
import { useModalState } from 'features/layouts/store/hooks'
import { ELayoutsModalEntities } from 'features/layouts/store/types'

export const useUploadList = () => {
	const { open } = useModalState<IUploadListGlobalData>(ELayoutsModalEntities.UploadList)

	const onRequestUploadCallback = useCallback((path: string, uploadType: 'files' | 'directory' = 'files') => {
		open({
			isUploadRequested: true,
			uploadType,
			path,
		})
	}, [open])

	return useMemo(() => ({
		openUploadDialog: onRequestUploadCallback,
	}), [onRequestUploadCallback])
}
