import React, { ErrorInfo, ReactNode, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { ROUTES } from 'app/router/constants'
import { Button, Result } from 'components'


import errorBoundaryStyles from './styles.module.scss'
import { IErrorBoundaryEvent } from './types'

function ErrorBoundaryComponent({ resetError, error, componentStack }: IErrorBoundaryEvent) {
	const { t } = useTranslation(['actions', 'errors'])
	const navigate = useNavigate()

	const onHomeCallback = useCallback(() => {
		navigate(ROUTES.Index)
		resetError()
	}, [navigate, resetError])
	return (
		<Result
			status='error'
			title={error?.message ?? t('notFound.An error occurred')}
			subTitle={componentStack}
			className={errorBoundaryStyles.root}
			extra={(
				<div className={errorBoundaryStyles.extra}>
					<Button
						block
						type='primary'
						onClick={resetError}
					>
						{t('Close')}
					</Button>
					<Button
						block
						onClick={onHomeCallback}
					>
						{t('To the main page')}
					</Button>
				</div>
			)}
		/>
	)
}

interface Props {
	children: ReactNode;
}
interface State {
	hasError: boolean;
	error?: Error;
	info?: ErrorInfo;
}

export default class ErrorBoundary extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = { hasError: false }
	}

	static getDerivedStateFromError() {
		return { hasError: true }
	}

	componentDidCatch(error: Error, info: ErrorInfo) {
		this.setState({
			error,
			info
		})
	}

	onResetError() {
		this.setState({})
	}

	render() {
		const { hasError, error, info } = this.state
		const { children } = this.props
		if (hasError && error && info) {
			return(
				<ErrorBoundaryComponent
					error={error}
					resetError={this.onResetError}
					componentStack={info?.componentStack}
				/>
			)
		}

		return children
	}
}
