import { EHandlerScales, EHandlerSettingModes, EHandlerSmoothingAlgorithm } from 'services/handlers/types'

export const HEIGHTS_STEP_INITIAL_VALUES = {
	settingsMode: EHandlerSettingModes.Auto,
	targetScale: EHandlerScales.TwoThousands,
	reliefResolution: 0.5,
	reliefSmoothWindow: 2,
	interpolationRange: 10,
	canopyResolution: 1,
	canopySmoothMedianWindow: 2,
	canopySmoothAlgorithm: EHandlerSmoothingAlgorithm.PitFree,
	slopeFlag: true,
	expositionFlag: true,
	itpFlag: true,
	slopeResolution: 2,
	expositionResolution: 2,
	itpResolution: 5,
	heightMarksFlag: true,
	heightMarksNum: 5,
	height05Flag: false,
	height1Flag: true,
	height2Flag: false,
	height5Flag: false,
	height10Flag: false,
}
