import React from 'react'

import { Space } from 'components'

import { InfoDescriptionField } from './InfoDescriptionField'
import { InfoDescriptionFieldTitle } from './InfoDescriptionFieldTitle'
import { InfoDescriptionFieldValue } from './InfoDescriptionFieldValue'
import { InfoDescriptionFormField } from './InfoDescriptionFormField'
import { IInfoDescriptionProps } from './types'

function InfoDescriptionComponent(props: IInfoDescriptionProps) {
	const { dataset, ...rest } = props

	return (
		<Space
			size='small'
			direction='vertical'
			{...rest}
		>
			{dataset.map(
				({ label, value, hidden }) => !hidden && (
					<InfoDescriptionField key={(label as string)?.toString()}>
						<InfoDescriptionFieldTitle label={label} />
						<InfoDescriptionFieldValue value={value} />
					</InfoDescriptionField>
				)
			)}
		</Space>
	)
}

InfoDescriptionComponent.Field = InfoDescriptionField
InfoDescriptionComponent.FormField = InfoDescriptionFormField
InfoDescriptionComponent.FieldTitle = InfoDescriptionFieldTitle
InfoDescriptionComponent.FieldValue = InfoDescriptionFieldValue

export const InfoDescription = InfoDescriptionComponent
