import { buildModuleUrl } from 'cesium'
import React, { useEffect } from 'react'
import { useCesium } from 'resium'



export function InfoBox() {
	const { viewer } = useCesium()

	useEffect(() => {
		const frame = viewer?.infoBox?.frame

		const listener = () => {
			const cssLink = frame?.contentDocument?.createElement('link')
			if (cssLink) {
				cssLink.href = buildModuleUrl('/cesium-frame.css')
				cssLink.rel = 'stylesheet'
				cssLink.type = 'text/css'
				frame?.contentDocument?.head.appendChild(cssLink)
			}
		}

		if (frame) {
			frame.addEventListener('load', listener, false)
		}

		return () => {
			viewer?.infoBox?.frame?.removeEventListener('load', listener)
		}
	}, [viewer])

	return null
}
