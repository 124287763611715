import React from 'react'

import styles from './styles.module.scss'
import { IInfoDescriptionFieldProps } from './types'

export function InfoDescriptionField(props: IInfoDescriptionFieldProps) {
	const { children, id } = props

	return (
		<div
			className={styles.field}
			id={id ?? ''}
		>
			{children}
		</div>
	)
}
