import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import React from 'react'

import { ESpacer } from 'assets/theme/variables/spacers'
import { Button, Divider, Text } from 'components'
import { LayerSwitcherList } from 'features/map/blocks/Instruments/LayerSwitcher/LayerSwitcherList'
import { ILayerSwitcherContentProps } from 'features/map/blocks/Instruments/LayerSwitcher/types'

import styles from './styles.module.scss'

export function LayerSwitcherContent({ onClose }: ILayerSwitcherContentProps) {
	return (
		<div className={styles.content}>
			<div className={styles.header}>
				<Button className={styles.closeButton} size='small' onClick={onClose} icon={<ChevronLeftIcon width={16} />} type='tertiary' shape="circle" />
				<Text type='header4'>Список слоев</Text>
				<Divider className={styles.contentDivider}/>
			</div>
			<LayerSwitcherList />
		</div>
	)
}
