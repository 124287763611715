import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline'
import React from 'react'

import { stopEventPropagation } from 'common/utils/event'
import { Badge, Popover, Text } from 'components'
import { NotificationsList } from 'features/notifications/blocks/NotificationsList'

import styles from './styles.module.scss'
import { INotificationsPopoverProps } from './types'

export function NotificationsPopover(props: INotificationsPopoverProps) {
	const { notificationsIds } = props

	return (
		<div onClick={stopEventPropagation}>
			<Popover
				arrow={false}
				placement='bottomLeft'
				content={<NotificationsList notificationsIds={notificationsIds} />}
				trigger={['click']}
				overlayClassName={styles.overlay}
			>
				<div className={styles.root}>
					<Badge borderless offset={[-2, 2]} dot status='warning' dotSize={8}>
						<ChatBubbleBottomCenterTextIcon className={styles.eventsIcon} width={20} />
					</Badge>
					<Text
						typographyType='secondary'
						ellipsis
						type='body12'
					>
						{notificationsIds.length}
					</Text>
				</div>
			</Popover>
		</div>
	)
}
