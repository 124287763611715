import classNames from 'classnames'
import React from 'react'

import { Col, Hint, Row, Text } from 'components'

import styles from './styles.module.scss'
import { IBlockTitleProps } from './types'

export function BlockTitle(props: IBlockTitleProps) {
	const { title, children, className, indent, type = 'header1', hint, afterContent, bold } = props

	return (
		<Row
			className={classNames(className, {
				[styles.indent]: indent,
			})}
			justify='space-between'
			align='middle'
		>
			<Col className={styles.titleCol}>
				<Hint hint={hint}>
					<Text
						className={classNames(styles.title)}
						type={type}
						bold={bold}
					>
						{title}
					</Text>
				</Hint>
				{afterContent && (
					<span className={styles.content}>
						{afterContent}
					</span>
				)}
			</Col>
			{children && (
				<Col>
					{children}
				</Col>
			)}
		</Row>
	)
}
