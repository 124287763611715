import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { message } from 'components'
import { EFormTypes, IFormScheme } from 'components/FormGenerator/types'
import { IPrimaryFormValues } from 'features/handlers/blocks/HandlerPrimaryStep/types'
import { HandlerStepBase } from 'features/handlers/blocks/HandlerStepBase'
import { HANDLER_STEPS_ORDER } from 'features/handlers/constants'
import { useHandlerContext } from 'features/handlers/hooks'
import { useHandlerStoreData } from 'features/handlers/store/hooks'
import { handlersApi } from 'services/handlers'
import { EHandlerSteps, IHandler } from 'services/handlers/types'

import { PRIMARY_STEP_INITIAL_VALUES } from './constants'
import { Filtration } from './Filtration'
import { Srs } from './Srs'

const { useLazyGetLasFilesHeadersQuery } = handlersApi
const FORM_NAME = 'handlerPrimaryStep'

export function HandlerPrimaryStep() {
	const { t } = useTranslation('handlers')
	const { onFinish, handlerData, project } = useHandlerContext()
	const { primaryData } = useHandlerStoreData()
	const projectName = project?.name
	const handlerName = handlerData?.title || primaryData?.title

	const [getLasHeaders] = useLazyGetLasFilesHeadersQuery()

	const getLasHeadersCallback = useCallback(async () => {
		const result = await getLasHeaders({
			handlerName: handlerName as string,
			projectName: projectName as string,
		})

		if (result.data) {
			return result.data
		} else {
			void message.error(t('Failed to get photo parameters'))
		}

		return
	}, [getLasHeaders, handlerName, projectName, t])

	const values = useMemo(() => ({
		preprocessing: {
			...PRIMARY_STEP_INITIAL_VALUES,
			...handlerData?.config?.preprocessing,
		}
	}), [handlerData])

	const scheme = useMemo<IFormScheme[]>(() => [
		{
			name: 'primaryCrs',
			type: EFormTypes.Custom,
			component: <Srs getHeaders={getLasHeadersCallback} />,
		},
		{
			name: 'preprocessing',
			type: EFormTypes.Custom,
			component: <Filtration />
		}
	], [getLasHeadersCallback])

	const onFinishCallback = useCallback((values: IPrimaryFormValues) => {
		const { preprocessing } = values
		const {
			absHeightFiltration, stdFiltration, percentFiltration, kMeansFiltration, minAbsHeight, maxAbsHeight,
			std, minPercent, maxPercent, pointNum, autoSetting, meanArea, meanDensity, approxScale, heightScaleMin, heightScaleMax,
		} = preprocessing

		const body = {} as IHandler['config']

		switch (true) {
		case autoSetting: {
			body.preprocessing = {
				autoSetting: true,
				generateReviewVls: preprocessing?.generateReviewVls,
			} as IHandler['config']['preprocessing']

			break
		}

		default: {
			body.preprocessing = {
				...preprocessing,
				meanArea: meanArea || 0,
				meanDensity: meanDensity || 0,
				approxScale: approxScale || 0,
				heightScaleMin: heightScaleMin || 0,
				heightScaleMax: heightScaleMax || 0,
				minAbsHeight: absHeightFiltration ? minAbsHeight ?? 0 : 0,
				maxAbsHeight: absHeightFiltration ? maxAbsHeight ?? 0 : 0,
				std: stdFiltration || kMeansFiltration ? std ?? 0 : 0,
				minPercent: percentFiltration ? minPercent ?? 0 : 0,
				maxPercent: percentFiltration ? maxPercent ?? 0 : 0,
				pointNum: kMeansFiltration ? pointNum ?? 0 : 0,
			} as IHandler['config']['preprocessing']
		}
		}

		onFinish?.(body)
	}, [onFinish])

	return (
		<HandlerStepBase<IPrimaryFormValues>
			stepTitle={t(`stepsTitles.${EHandlerSteps.Preprocessing}`)}
			hint={t(`hints.${EHandlerSteps.Preprocessing}`)}
			step={HANDLER_STEPS_ORDER.indexOf(EHandlerSteps.Preprocessing) + 1}
			scheme={scheme}
			formName={FORM_NAME}
			onFinish={onFinishCallback}
			values={values}
		/>
	)
}
