import { Table as AntdTable } from 'antd'
import React, { ReactElement, Ref, forwardRef, useMemo, useState } from 'react'

import { ITableProps } from 'components/Table/types'
import { PaginationItem } from 'components/Table/ui/PaginationItem'

import styles from './styles.module.scss'
import { ITableColumn } from './types'
import { configurationFiltersForColumns, getTableColumnKey } from './utils'

export const Table = forwardRef(function Table<DataSource extends object>({
	pagination,
	columns: propsColumns,
	filters,
	...props
}: ITableProps<DataSource>, ref: Ref<HTMLDivElement>) {
	const [filterDropdownsState, setFilterDropdownsState] = useState<{
		[key: string | number]: boolean
	}>({})

	const columns = useMemo<ITableColumn<DataSource>[]>(() => {
		const normalizeColumns = configurationFiltersForColumns<DataSource>(propsColumns, filters)

		const defaultFilterDropdownsState = normalizeColumns.reduce<{
			[key: string | number]: boolean
		}>(
			(acc, column) => ({
				...acc,
				[getTableColumnKey<DataSource>(column)]: false,
			}),
			{}
		)

		normalizeColumns.forEach((column) => {
			const columnKey = getTableColumnKey<DataSource>(column)

			column.onFilterDropdownOpenChange = (visible: boolean) => {
				if (visible && column.focusInputCallback) {
					column.focusInputCallback()
				}

				setFilterDropdownsState({
					...defaultFilterDropdownsState,
					[columnKey]: visible,
				})
			}
		})

		setFilterDropdownsState(defaultFilterDropdownsState)

		return normalizeColumns
	}, [propsColumns, filters])

	const tableColumns = useMemo(
		() => columns
			.map((column) => ({
				...column,
				filterDropdownOpen: filterDropdownsState[getTableColumnKey(column)],
			})),
		[columns, filterDropdownsState]
	)

	return (
		<AntdTable<DataSource>
			pagination={
				pagination && {
					showSizeChanger: false,
					...pagination,
					className: styles.pagination,
					itemRender: (_, type, originalElement) => (
						<PaginationItem originalElement={originalElement} type={type} />
					)
				}
			}
			{...props}
			ref={ref}
			columns={tableColumns}
		/>
	)
}) as <DataSource extends object>(p: ITableProps<DataSource> & { ref?: Ref<HTMLDivElement> }) => ReactElement
