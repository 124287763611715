import classNames from 'classnames'
import React from 'react'

import { AntdSwitch, Space, Text } from 'components/index'

import styles from './styles.module.scss'
import { ISwitchProps } from './types'

export function Switch(props: ISwitchProps) {
	const { children, disabled, ...switchProps } = props

	return (
		<Space size='small'>
			<AntdSwitch
				{...switchProps}
				disabled={disabled}
			/>
			{children && (
				<Text
					className={classNames({
						[styles.disabledText]: disabled,
					})}
				>
					{children}
				</Text>
			)}
		</Space>
	)
}
