import { FieldData } from 'rc-field-form/es/interface'
import toCamelCase from 'to-camel-case'

import { getObjectEntries } from 'common/utils/object'

export const normalizeFormError = (errors: Record<never, unknown>, parentNames: (string | number)[] = []): FieldData[] => getObjectEntries(errors).reduce<FieldData[]>((reducer, item: [string, string[]]) => {
	const [elementName, errors] = item
	const name = isNaN(Number(elementName)) ? toCamelCase(elementName) : Number(elementName)

	if (typeof errors?.[0] === 'object') {
		const value = normalizeFormError(errors, parentNames.length ? [...parentNames, name] : [name])

		reducer.push(...value)
	} else {
		reducer.push({
			name: parentNames.length ? [...parentNames, name] : name,
			errors: Array.isArray(errors) ? errors : [errors],
		})
	}

	return reducer
}, [])
