import { MutationDefinition } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { BaseQueryFn, EndpointDefinition } from '@reduxjs/toolkit/query'

export enum EServiceTags {
	Unauthorized = 'unauthorized',
	UnknownError = 'unknownError',
	Projects = 'Projects',
	Handlers = 'Handlers',
	HandlersLasFilesHeaders = 'HandlersLasFilesHeaders',
	Notifications = 'Notifications',
	Maps = 'Maps',
	Layers = 'Layers',
}

export type ISerializeQueryArgs<T> = {queryArgs: T, endpointDefinition: EndpointDefinition<any, any, any, any, string>, endpointName: string}

export interface IDefaultCollectionRequestParams {
	skip?: number
	limit?: number
}

export type IDefaultCollection<T> = T[]

export type ICacheItem<T> = T & { id: string }

export interface IUseMutationStateResult<T = unknown> {
	isError: boolean
	isLoading: boolean
	isSuccess: boolean
	isUninitialized: boolean
	originalArgs: unknown
	reset(): void
	status: string
	data?: T
	endpointName?: string
	fulfilledTimeStamp?: number
	requestId?: string
	startedTimeStamp?: number
}

export type TDefaultMutation = UseMutation<
	// @ts-ignore
	MutationDefinition<unknown, BaseQueryFn | undefined, string, unknown>
>
