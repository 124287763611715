import React from 'react'

import { List } from 'components'
import { ProjectItem } from 'features/projects/blocks/ProjectItem'

import { IProjectsListProps } from './types'

export function ProjectsList(props: IProjectsListProps) {
	const { items, loading, onEdit, onDelete } = props

	return (
		<List
			grid={{
				gutter: 16,
				xxl: 5,
				column: 4,
			}}
			dataSource={items}
			renderItem={(item) => (
				<List.Item>
					<ProjectItem
						loading={loading}
						item={item}
						onEdit={onEdit}
						onDelete={onDelete}
					/>
				</List.Item>
			)}
		/>
	)
}
