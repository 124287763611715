import qs, { ParsedQs } from 'qs'
import { useMemo } from 'react'
import {
	NavigateOptions,
	URLSearchParamsInit,
	useSearchParams,
} from 'react-router-dom'

export const useParamsByUrl = <T>(
	defaultObject?: URLSearchParamsInit,
	normalizeFunction?: (values: ParsedQs) => T
): [T, (nextInit: URLSearchParamsInit, navigateOpts?: NavigateOptions) => void] => {
	const [params, onChange] = useSearchParams(defaultObject)
	const result = useMemo(() => {
		const values = qs.parse(params.toString())

		if (normalizeFunction) {
			return normalizeFunction(values)
		}

		return values as unknown as T
	}, [normalizeFunction, params])

	return [result, onChange]
}
