import { WebMercatorProjection } from 'cesium'

export const MAP_SETTINGS = {
	homeButton: false,
	timeline: false,
	fullscreenButton: false,
	vrButton: false,
	navigationHelpButton: false,
	sceneModePicker: false,
	projectionPicker: false,
	baseLayerPicker: false,
	selectionIndicator: false,
	geocoder: false,
	animation: false,
	imageryProvider: false as const,
	mapProjection: new WebMercatorProjection()
}
