import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { EFormTypes, IFormScheme } from 'components/FormGenerator/types'
import { HandlerStepBase } from 'features/handlers/blocks/HandlerStepBase'
import { HANDLER_STEPS_ORDER } from 'features/handlers/constants'
import { useHandlerContext } from 'features/handlers/hooks'
import { EHandlerSettingModes, EHandlerSteps, IHandleHeights } from 'services/handlers/types'

import { HEIGHTS_STEP_INITIAL_VALUES } from './constants'
import { PlantingModel } from './PlantingModel'
import { RasterLayers } from './RasterLayers'
import { ReliefModel } from './ReliefModel'
import { Settings } from './Settings'
import { IHeightsStepFormValues } from './types'
import { VectorLayers } from './VectorLayers'

const FORM_NAME = 'handlerHeightsStep'
const shouldShowConfig = (values: IHandleHeights) => values.settingsMode === EHandlerSettingModes.Manual
const shouldShowDependencies = ['settingsMode']

export function HandlerHeightsStep() {
	const { t } = useTranslation('handlers')
	const { onFinish, handlerData } = useHandlerContext()

	const values = useMemo(() => ({
		...HEIGHTS_STEP_INITIAL_VALUES,
		...handlerData?.config?.heights,
		targetScale: handlerData?.config?.heights?.targetScale
			? handlerData.config.heights.targetScale.toString()
			: HEIGHTS_STEP_INITIAL_VALUES.targetScale,
	}), [handlerData])

	const scheme = useMemo<IFormScheme[]>(() => [
		{
			name: '',
			type: EFormTypes.Custom,
			component: <Settings />,
		},
		{
			name: '',
			type: EFormTypes.Custom,
			component: <ReliefModel />,
			shouldShow: shouldShowConfig,
			dependencies: shouldShowDependencies,
		},
		{
			name: '',
			type: EFormTypes.Custom,
			component: <PlantingModel />,
			shouldShow: shouldShowConfig,
			dependencies: shouldShowDependencies,
		},
		{
			name: '',
			type: EFormTypes.Custom,
			component: <RasterLayers />,
		},
		{
			name: '',
			type: EFormTypes.Custom,
			component: <VectorLayers />,
		}
	], [])

	const onFinishCallback = useCallback((values: IHeightsStepFormValues) => {
		const { height1Flag, height2Flag, height5Flag, height05Flag, height10Flag, targetScale, ...rest } = values
		const {
			height1Flag: initHeight1Flag,
			height2Flag: initHeight2Flag,
			height5Flag: initHeight5Flag,
			height05Flag: initHeight05Flag,
			height10Flag: initHeight10Flag,
			targetScale: initTargetScale,
			...restInitial
		} = HEIGHTS_STEP_INITIAL_VALUES

		onFinish?.({
			heights: {
				...restInitial,
				...rest,
				targetScale: Number(targetScale || initTargetScale),
				'height_05_flag': height05Flag || initHeight05Flag,
				'height_1_flag': height1Flag || initHeight1Flag,
				'height_2_flag': height2Flag || initHeight2Flag,
				'height_5_flag': height5Flag || initHeight5Flag,
				'height_10_flag': height10Flag || initHeight10Flag,
			},
		})
	}, [onFinish])

	return (
		<HandlerStepBase<IHeightsStepFormValues>
			stepTitle={t(`stepsTitles.${EHandlerSteps.Heights}`)}
			hint={t(`hints.${EHandlerSteps.Heights}`)}
			step={HANDLER_STEPS_ORDER.indexOf(EHandlerSteps.Heights) + 1}
			scheme={scheme}
			formName={FORM_NAME}
			onFinish={onFinishCallback}
			values={values}
		/>
	)
}
