const downloadFile = (file: { url: string, fileName: string }) => {
	const link = document.createElement('a')

	link.href = file.url
	link.setAttribute('download', file.fileName)
	document.body.appendChild(link)
	link.click()
	link.remove()
}

export const downloadFileByBlob = (blob: Blob, fileName: string) => {
	const urlObject = window.URL.createObjectURL(blob)

	downloadFile({
		url: urlObject,
		fileName,
	})
}
