import { i18n } from 'app/localization'
import { message } from 'components'
import { BucketConnector } from 'features/data/libs/BucketConnector'
import { HANDLER_STEPS_ORDER } from 'features/handlers/constants'
import { EHandlerActionTypes, EHandlerStatus, EHandlerSteps, IHandler } from 'services/handlers/types'

export const getHandlerActionType = (handler?: IHandler): EHandlerActionTypes => {
	switch (handler?.status) {
	case EHandlerStatus.New: {
		if (!handler?.config?.preprocessing) {
			return EHandlerActionTypes.Change
		}

		return EHandlerActionTypes.Start
	}
	case EHandlerStatus.Error: {
		return EHandlerActionTypes.Restart
	}
	case EHandlerStatus.Running: {
		return EHandlerActionTypes.Stop
	}
	case EHandlerStatus.Pending: {
		return EHandlerActionTypes.NextStep
	}
	case EHandlerStatus.Done: {
		return EHandlerActionTypes.Done
	}
	default:
		return EHandlerActionTypes.Create
	}
}

export const getHandlerStepNumber = (step: EHandlerSteps) => HANDLER_STEPS_ORDER.indexOf(step)

export const downloadHandlerData = async (handlerName: string, projectName: string) => {
	try {
		const handlerFolder = `${projectName}/${handlerName}/`

		await BucketConnector?.downloadFolder({
			name: handlerName,
			key: handlerFolder,
		})
	} catch (error) {
		void message.error(i18n.t('Handler data folder not found', { ns: 'handlers' }))
	}
}
