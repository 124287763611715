import { SearchOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import React, { ChangeEvent, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ESpacer } from 'assets/theme/variables/spacers'
import { stopEventPropagation } from 'common/utils/event'
import { Button, Col, Row, Space } from 'components'

import styles from '../styles.module.scss'
import { ITableFilterChildrenProps, ITableFilterProps } from '../types'

export function TableFilter(props: ITableFilterProps) {
	const { t } = useTranslation('actions')
	const { selectedKeys, confirm, clearFilters, children, setSelectedKeys, className } =
    props

	const handleReset = useCallback((clearFilters: () => void) => {
		clearFilters()
	}, [])

	const onSearchCallback = useCallback(() => {
		confirm?.()
	}, [confirm])

	const onResetCallback = useCallback(() => {
		if (clearFilters) {
			handleReset(clearFilters)
			confirm?.()
		}
	}, [
		clearFilters,
		confirm,
		handleReset,
	])

	const onChangeCallback = useCallback(
		(e: ChangeEvent<HTMLInputElement> | string) => {
			if (typeof e === 'object') {
				setSelectedKeys?.(e?.target?.value ? [e?.target?.value] : [])
			} else {
				setSelectedKeys?.(e ? [e] : [])
			}
		},
		[setSelectedKeys]
	)

	const childrenProps = useMemo<ITableFilterChildrenProps>(
		() => ({
			onSearch: onSearchCallback,
			onChange: onChangeCallback,
			value: selectedKeys?.[1] as string,
		}),
		[
			onChangeCallback,
			onSearchCallback,
			selectedKeys,
		]
	)

	return (
		<div
			className={classNames(styles.filterRoot, className)}
			onKeyDown={stopEventPropagation}
		>
			<Row
				className={styles.filterInputGroup}
				wrap={false}
				gutter={ESpacer.SPACER3}
			>
				<Col
					className={styles.filterInput}
					flex={1}
				>
					{children(childrenProps)}
				</Col>
			</Row>
			<Space className={styles.filterButtons}>
				<Button
					onClick={onResetCallback}
					size='small'
				>
					{t('Reset')}
				</Button>
				<Button
					type='primary'
					onClick={onSearchCallback}
					icon={<SearchOutlined rev={t('Search')} />}
					size='small'
				>
					{t('Search')}
				</Button>
			</Space>
		</div>
	)
}
