import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { formattingLabels2Options } from 'common/utils/formatting'
import { FormGenerator } from 'components'
import { EFormTypes, IFormScheme } from 'components/FormGenerator/types'
import { HandlerStepBlock } from 'features/handlers/blocks/HandlerStepBlock'
import { HANDLER_SMOOTHING_ALGORITHM_LABELS } from 'features/handlers/constants'

export function PlantingModel() {
	const { t } = useTranslation('handlers')

	const scheme = useMemo<IFormScheme[]>(() => [
		{
			isVertical: true,
			name: 'canopyResolution',
			type: EFormTypes.Number,
			label: <span>{t('Resolution of digital planting model')}</span>,
			required: true,
			inputProps: {
				size: 'small',
			},
		},
		{
			isVertical: true,
			name: 'canopySmoothMedianWindow',
			type: EFormTypes.Number,
			label: <span>{t('Smoothing window size')}</span>,
			required: true,
			inputProps: {
				size: 'small',
			},
		},
		{
			isVertical: true,
			name: 'canopySmoothAlgorithm',
			type: EFormTypes.Select,
			label: <span>{t('Smoothing algorithm')}</span>,
			required: true,
			inputProps: {
				size: 'small',
				options: formattingLabels2Options(HANDLER_SMOOTHING_ALGORITHM_LABELS),
			},
		},
	], [t])

	return (
		<HandlerStepBlock title={t('Digital model of plantings')}>
			<FormGenerator scheme={scheme} />
		</HandlerStepBlock>
	)
}
