import { TEntries } from 'common/types/services'

export const deepObjectCopy = <T extends Record<never, never> | unknown[]>(object: T) => {
	if (!object || typeof object !== 'object') {
		return object
	}

	return JSON.parse(JSON.stringify(object)) as T
}

export const getObjectEntries = <T extends Record<never, unknown>>(obj: T): TEntries<T> => Object.entries(obj) as TEntries<T>

export const getObjectValueByPath = <T = unknown>(propObject: Record<never, unknown>, path: string[]): T | undefined => {
	const rawObject = deepObjectCopy<Record<never, unknown>>(propObject)

	return path.reduce((childObject, key) => childObject?.[key as keyof object], rawObject) as T
}

export const setObjectValueByPath = <ObjectType extends Record<never, unknown> = Record<never, unknown>, ValueType = unknown>(propObject: ObjectType, path: string[], value: ValueType): ObjectType => {
	const rawObject = deepObjectCopy<ObjectType>(propObject)

	path.reduce((object: Record<never, unknown>, key, index) => {
		if (index === (path.length - 1)) {
			// @ts-ignore
			object[key] = value as ValueType

			return object
		} else {
			if (!(key in object)) {
				// @ts-ignore
				object[key] = {}
			}

			return object?.[key as keyof object]
		}
	}, rawObject ?? {})

	return rawObject
}
