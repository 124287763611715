import { i18n } from 'app/localization'
import * as COLORS from 'assets/theme/variables/colors'
import { DefaultOptionType } from 'components'
import {
	EHandlerClassificationDefaultParams,
	EHandlerOtClassificationModels,
	EHandlerPointsClasses,
	EHandlerScales,
	EHandlerSegmentationAlgorithms,
	EHandlerSegmentationSeasons,
	EHandlerSegmentationSources,
	EHandlerSettingModes,
	EHandlerSmoothingAlgorithm,
	EHandlerStatus,
	EHandlerSteps
} from 'services/handlers/types'

const { t } = i18n

export const HANDLER_EVENT_STATUS_LABELS: { [key in EHandlerStatus]: string } = {
	[EHandlerStatus.New]: t('handlers:eventStatuses.New'),
	[EHandlerStatus.Pending]: t('handlers:eventStatuses.Pending'),
	[EHandlerStatus.Paused]: t('handlers:eventStatuses.Stopped'),
	[EHandlerStatus.Running]: t('handlers:eventStatuses.Running'),
	[EHandlerStatus.Done]: t('handlers:eventStatuses.Completed'),
	[EHandlerStatus.Error]: t('handlers:eventStatuses.Error'),
}

export const HANDLER_STEPS_LABELS: { [key in EHandlerSteps]: string } = {
	[EHandlerSteps.Preprocessing]: t('handlers:steps.Primary'),
	[EHandlerSteps.Classification]: t('handlers:steps.Classification'),
	[EHandlerSteps.Heights]: t('handlers:steps.Height'),
	[EHandlerSteps.LasSegmentation]: t('handlers:steps.SegmentationOT'),
	[EHandlerSteps.SemanticClassification]: t('handlers:steps.SemanticClass'),
}

export const HANDLER_STEPS_ORDER: EHandlerSteps[] = [
	EHandlerSteps.Preprocessing,
	EHandlerSteps.Classification,
	EHandlerSteps.Heights,
	EHandlerSteps.LasSegmentation,
	EHandlerSteps.SemanticClassification,
]

export const HANDLER_POINTS_CLASSES_LABELS: { [key in EHandlerPointsClasses]: string } = {
	[EHandlerPointsClasses.Created]: t('handlers:pointsClasses.Created'),
	[EHandlerPointsClasses.NotClassified]: t('handlers:pointsClasses.Not classified'),
	[EHandlerPointsClasses.Ground]: t('handlers:pointsClasses.Ground'),
	[EHandlerPointsClasses.TallVegetation]: t('handlers:pointsClasses.Tall vegetation'),
	[EHandlerPointsClasses.Building]: t('handlers:pointsClasses.Building'),
	[EHandlerPointsClasses.LowerPoint]: t('handlers:pointsClasses.Lower point'),
	[EHandlerPointsClasses.HigherPoint]: t('handlers:pointsClasses.Higher point'),
}

export const HANDLER_POINTS_CLASSES_COLORS: { [key in EHandlerPointsClasses]: string } = {
	[EHandlerPointsClasses.Created]: COLORS.HANDLER_POINTS_CLASSES_CREATED_COLOR,
	[EHandlerPointsClasses.NotClassified]: COLORS.HANDLER_POINTS_CLASSES_NOT_CLASSIFIED_COLOR,
	[EHandlerPointsClasses.Ground]: COLORS.HANDLER_POINTS_CLASSES_GROUND_COLOR,
	[EHandlerPointsClasses.TallVegetation]: COLORS.HANDLER_POINTS_CLASSES_TALL_VEGETATION_COLOR,
	[EHandlerPointsClasses.Building]: COLORS.HANDLER_POINTS_CLASSES_BUILDING_COLOR,
	[EHandlerPointsClasses.LowerPoint]: COLORS.HANDLER_POINTS_CLASSES_LOWER_POINT_COLOR,
	[EHandlerPointsClasses.HigherPoint]: COLORS.HANDLER_POINTS_CLASSES_HIGHER_POINT_COLOR,
}

export const HANDLER_OT_CLASSIFICATION_MODELS_LABELS: { [key in EHandlerOtClassificationModels]: string } = {
	[EHandlerOtClassificationModels.FabricSimulation]: t('handlers:otClassificationModels.Fabric simulation'),
	[EHandlerOtClassificationModels.MorphologicalFilter]: t('handlers:otClassificationModels.Morphological filter'),
	[EHandlerOtClassificationModels.NeuralNetworkModel]: t('handlers:otClassificationModels.Neural network model'),
}

export const HANDLER_CLASSIFICATION_DEFAULT_PARAMS_LABELS: { [key in EHandlerClassificationDefaultParams]: string } = {
	[EHandlerClassificationDefaultParams.FlatRelief]: t('handlers:classificationDefaultParams.Flat relief'),
	[EHandlerClassificationDefaultParams.HillsRelief]: t('handlers:classificationDefaultParams.Hills relief'),
	[EHandlerClassificationDefaultParams.RuggedRelief]: t('handlers:classificationDefaultParams.Rugged relief'),
	[EHandlerClassificationDefaultParams.UrbanRelief]: t('handlers:classificationDefaultParams.Urban relief'),
}

export const HANDLER_SETTING_MODES_LABELS: { [key in EHandlerSettingModes]: string } = {
	[EHandlerSettingModes.Auto]: t('handlers:otClassificationTypes.Automatic'),
	[EHandlerSettingModes.Manual]: t('handlers:otClassificationTypes.Manual'),
}

export const HANDLER_SMOOTHING_ALGORITHM_LABELS: { [key in EHandlerSmoothingAlgorithm]: string } = {
	[EHandlerSmoothingAlgorithm.Median]: t('handlers:smoothingAlgorithms.Median'),
	[EHandlerSmoothingAlgorithm.Gaussian]: t('handlers:smoothingAlgorithms.Gaussian'),
	[EHandlerSmoothingAlgorithm.PitFree]: t('handlers:smoothingAlgorithms.PitFree'),
	[EHandlerSmoothingAlgorithm.Average]: t('handlers:smoothingAlgorithms.Average'),
}

export const HANDLER_SCALE_LABELS: { [key in EHandlerScales]: string } = {
	[EHandlerScales.FiveHundred]: '1:500',
	[EHandlerScales.OneThousand]: '1:1000',
	[EHandlerScales.TwoThousands]: '1:2000',
	[EHandlerScales.FiveThousands]: '1:5000',
	[EHandlerScales.TenThousands]: '1:10000',
}

export const HANDLER_SEGMENTATION_ALGORITHM_LABELS: { [key in EHandlerSegmentationAlgorithms]: string | DefaultOptionType } = {
	[EHandlerSegmentationAlgorithms.Li2012]: {
		label: t('handlers:segmentationAlgorithms.Li2012'),
		disabled: true,
	},
	[EHandlerSegmentationAlgorithms.Dalponte2016]: {
		label: t('handlers:segmentationAlgorithms.Dalponte2016'),
		disabled: true,
	},
	[EHandlerSegmentationAlgorithms.Silva2016]: {
		label: t('handlers:segmentationAlgorithms.Silva2016'),
		disabled: true,
	},
	[EHandlerSegmentationAlgorithms.Watershed]: t('handlers:segmentationAlgorithms.Watershed'),
}

export const HANDLER_SEGMENTATION_SEASONS_LABELS: { [key in EHandlerSegmentationSeasons]: string } = {
	[EHandlerSegmentationSeasons.Summer]: t('handlers:segmentationSeasons.Summer'),
	[EHandlerSegmentationSeasons.Winter]: t('handlers:segmentationSeasons.Winter'),
}

export const HANDLER_SEGMENTATION_SOURCES_LABELS: { [key in EHandlerSegmentationSources]: string } = {
	[EHandlerSegmentationSources.LAS]: t('handlers:segmentationSources.LAS'),
	[EHandlerSegmentationSources.TIF]: t('handlers:segmentationSources.TIF'),
}
