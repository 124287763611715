import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { IState } from 'app/store/types'
import { useMutableSelector } from 'common/hooks/slice'
import { mapSlice } from 'features/map/store/index'
import { getBaseLayer, getLayerState, getMode } from 'features/map/store/selectors'
import { ILayerState } from 'features/map/store/types'
import { mapsApi } from 'services/maps'
import { IMapLayer } from 'services/maps/types'

const { useGetMapQuery, useGetLayersQuery } = mapsApi

export const useBaseLayer = () => useSelector(getBaseLayer)
export const useMutableBaseLayer = () => useMutableSelector<typeof getBaseLayer, typeof mapSlice.actions.selectBaseLayer>(
	getBaseLayer,
	mapSlice.actions.selectBaseLayer
)
export const useMapMode = () => useSelector(getMode)
export const useMutableMapMode = () => useMutableSelector<typeof getMode, typeof mapSlice.actions.selectMode>(
	getMode,
	mapSlice.actions.selectMode
)

export const useMap = (mapId: number) => useGetMapQuery(mapId)
// export const useLayers = (mapId: number) => useGetLayersQuery(mapId)

export const useLayerState = (layer: {mapId: number, layerId: number}): ILayerState => useSelector((state: IState) => getLayerState(state, layer))
export const useLayerStateControl = (mapId: number, layerId: number): {
	state: ILayerState,
	onShow: () => void,
	onHide: () => void
} => {
	const state = useSelector((state: IState) => getLayerState(state, { mapId, layerId }))
	const dispatch = useDispatch()
	const onShow = useCallback(() => {
		dispatch(mapSlice.actions.showLayer({ mapId, layerId }))
	}, [dispatch, mapId, layerId])

	const onHide = useCallback(() => {
		dispatch(mapSlice.actions.hideLayer({ mapId, layerId }))
	}, [dispatch, mapId, layerId])

	return useMemo(() => ({
		state,
		onShow,
		onHide
	}), [onHide, onShow, state])
}
