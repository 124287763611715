import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { getStorageValue, setStorageValue } from 'common/utils/storage'
import { LAYERS_STATE_STORAGE } from 'features/map/store/constants'
import { IMapMode, IMapStore } from 'features/map/store/types'
import { getLayerKey } from 'features/map/store/utils'
import { IMapBaseLayerNames } from 'features/map/types'

const initialState: IMapStore = {
	baseLayer: 'osm',
	mode: '2d',
	// todo перенести получение состояние на бек, или хотя бы в бд
	layers: getStorageValue(LAYERS_STATE_STORAGE, JSON.parse) ?? {}
}

export const mapSlice = createSlice({
	name: 'mapSlice',
	initialState: {
		...initialState,
	},
	reducers: {
		selectMode: (state, action: PayloadAction<IMapMode>) => {
			state.mode = action.payload
		},
		selectBaseLayer: (state, action: PayloadAction<IMapBaseLayerNames>) => {
			state.baseLayer = action.payload
		},
		reset: () => {
			return initialState
		},
		showLayer: (state, action: PayloadAction<{mapId: number, layerId: number}>) => {
			const key = getLayerKey(action.payload)
			if (state.layers[key]) {
				state.layers[key].visible = true
			} else {
				state.layers[key] = {
					visible: true
				}
			}
			setStorageValue(LAYERS_STATE_STORAGE, JSON.stringify(state.layers))
		},
		hideLayer: (state, action: PayloadAction<{mapId: number, layerId: number}>) => {
			const key = getLayerKey(action.payload)
			if (state.layers[key]) {
				state.layers[key].visible = false
			} else {
				state.layers[key] = {
					visible: false
				}
			}
			setStorageValue(LAYERS_STATE_STORAGE, JSON.stringify(state.layers))
		},
	},
})
