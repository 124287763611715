import React from 'react'

import { Form } from 'components'

import styles from './styles.module.scss'
import { IFormHintFieldProps } from './types'

export function FormHintField({ name }: IFormHintFieldProps) {
	return (
		<Form.Item
			name={name}
			className={styles.root}
		>
			<span />
		</Form.Item>
	)
}
