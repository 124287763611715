import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { Cog8ToothIcon } from '@heroicons/react/24/solid'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import { EProjectResources, ROUTES, ROUTES_ENTITIES } from 'app/router/constants'
import { Divider, Icon, MenuButton, Space, Text } from 'components'
import { handlersApi } from 'services/handlers'

import styles from './styles.module.scss'

const { useGetHandlerQuery } = handlersApi

export default function Navigation() {
	const { t } = useTranslation('labels')
	const { pathname } = useLocation()
	const urlProjectId = useMemo(() => pathname?.match(new RegExp(`^${ROUTES.Projects}/([0-9]*)*`))?.[1], [pathname])
	const urlHandlerId = useMemo(() => pathname?.match(new RegExp(`^${ROUTES.HandlerPage.replace(/:id?/, '')}/([0-9]*)*`))?.[1], [pathname])

	const handlerState = useGetHandlerQuery({ id: urlHandlerId as string }, {
		skip: !urlHandlerId,
	})

	const projectId = useMemo(() => handlerState?.data?.projectId?.toString() || urlProjectId, [handlerState?.data?.projectId, urlProjectId])

	return (
		<Space
			direction='vertical'
			size={28}
		>
			<div>
				<Link to={ROUTES.Projects}>
					<MenuButton
						ghost
						icon={<ArrowLeftIcon width={24} />}>
						<Text type='body12'>{t('Projects')}</Text>
					</MenuButton>
				</Link>
				<Divider className={styles.divider} />
			</div>
			{projectId && (
				<>
					<Link to={ROUTES_ENTITIES.getProjectPage(projectId, EProjectResources.Handlers)}>
						<MenuButton
							icon={<Cog8ToothIcon width={24} />}
							isActive={pathname.includes(ROUTES_ENTITIES.getProjectPage(projectId, EProjectResources.Handlers))}>
							{t('User name', {name: 'ИИ'})}
						</MenuButton>
					</Link>
					<Link to={ROUTES_ENTITIES.getProjectPage(projectId, EProjectResources.Map)}>
						<MenuButton
							isActive={pathname.includes(ROUTES_ENTITIES.getProjectPage(projectId, EProjectResources.Map))}
							icon={<Icon size={24} icon='map' />}>
							{t('Map')}
						</MenuButton>
					</Link>
				</>
			)}
		</Space>
	)
}
