import { ArrowUturnLeftIcon, CloudIcon } from '@heroicons/react/24/outline'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ESpacer } from 'assets/theme/variables/spacers'
import { Breadcrumb, Button, Col, Row, Space } from 'components'
import { IBreadcrumbProps } from 'components/Breadcrumb/types'

import styles from './styles.module.scss'
import { IStorageBreadcrumbs } from './types'

export function StorageBreadcrumbs(props: IStorageBreadcrumbs) {
	const { t } = useTranslation('storage')
	const { onNewPath, fullPath } = props

	const onClickCallback = useCallback((newPath: string[]) => () => onNewPath(newPath), [onNewPath])

	const onClickBackCallback = useCallback(() => {
		if (fullPath.length) {
			onNewPath(fullPath.slice(0, fullPath.length - 1))
		}
	}, [onNewPath, fullPath])

	const items = useMemo(() => {
		const path: IBreadcrumbProps['items'] = fullPath.map((item, index) => ({
			key: item,
			title: item,
			onClick: onClickCallback(fullPath.slice(0, index + 1)),
		}))

		path.unshift({
			key: '/',
			title: (
				<Space
					size={ESpacer.SPACER2}
					align='center'
					className={styles.firstElement}
				>
					<CloudIcon width={14} />
					{t('Storage')}
				</Space>
			),
			onClick: onClickCallback([]),
		})

		return path
	}, [fullPath, onClickCallback, t])

	return (
		<Row
			gutter={ESpacer.SPACER3}
			wrap={false}
			align='middle'
			className={styles.root}
		>
			<Col>
				<Button
					size='small'
					type='tertiary'
					title={t('Click to go back')}
					onClick={onClickBackCallback}
					icon={<ArrowUturnLeftIcon width={20} />}
				/>
			</Col>
			<Col flex={1}>
				<Breadcrumb
					className={styles.breadcrumbs}
					items={items}
				/>
			</Col>
		</Row>
	)
}
