import React, { useLayoutEffect } from 'react'

import { Checkbox, Radio, Space, Switch, Text } from 'components'
import { useMainLayoutContext } from 'features/layouts/blocks/MainLayout/hooks'

export default function Design() {
	const { setHeaderContent } = useMainLayoutContext({
		showMenu: true,
	})

	useLayoutEffect(() => {
		setHeaderContent(
			<div style={{
				display: 'flex',
				flexFlow: 'column',
			}}>
				<Text type='header3'>
					Лесной квартал 12 (Республика Татарстан)
				</Text>
				<Text type='body12'>
					Площадь 100 га
				</Text>
			</div>
		)
	}, [setHeaderContent])

	return (
		<Space direction='vertical' style={{ backgroundColor: 'white', padding: 8 }}>
			<Radio checked disabled>Test</Radio>
			<Checkbox checked disabled>Test</Checkbox>
			<Checkbox checked bordered disabled>Test</Checkbox>
			<Switch checked disabled>Test</Switch>
		</Space>
	)
}
