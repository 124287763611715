import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useAppDispatch } from 'app/store/hooks'
import { layoutsSlice } from 'features/layouts/store'
import { getModalState } from 'features/layouts/store/selectors'
import { ELayoutsModalEntities } from 'features/layouts/store/types'

export const useModalState = <DataType>(entity: ELayoutsModalEntities) => {
	const dispatch = useAppDispatch()
	const stateData = useSelector((state) => getModalState(state, entity))

	const onOpenCallback = useCallback((data?: DataType) => {
		dispatch(
			layoutsSlice.actions.openModal({
				entity,
				data,
			})
		)
	}, [dispatch, entity])

	const onSetDataCallback = useCallback((data: DataType) => {
		dispatch(
			layoutsSlice.actions.setModalData({
				entity,
				data,
			})
		)
	}, [dispatch, entity])

	const onCloseCallback = useCallback(() => {
		dispatch(
			layoutsSlice.actions.closeModal({
				entity,
			})
		)
	}, [dispatch, entity])

	const onClearCallback = useCallback(() => {
		dispatch(
			layoutsSlice.actions.clearModalData({
				entity,
			})
		)
	}, [dispatch, entity])

	return {
		isOpen: stateData?.open,
		data: stateData?.data as DataType,
		open: onOpenCallback,
		close: onCloseCallback,
		setData: onSetDataCallback,
		clearData: onClearCallback,
	}
}
