import qs from 'qs'
import toSnakeCaseKeys from 'snakecase-keys'

import { getAccessToken } from 'common/utils/keycloak'
import { toCamelcaseKeys } from 'common/utils/string'
import { ICacheItem } from 'services/types'

export const injectTokenHeader = (headers: Headers) => {
	const token = getAccessToken()

	if (token) {
		headers.set('authorization', `Bearer ${token}`)
	}

	return headers
}

export function paramsSerializer(params: Record<string, unknown>) {
	return qs.stringify(toSnakeCaseKeys(params), {
		skipNulls: true,
		arrayFormat: 'brackets',
	})
}

export function transformRequest<T>(data: unknown): T {
	if (!data) {
		return data as T
	}

	return toSnakeCaseKeys(data, {
		deep: true,
	}) as T
}

export function transformResponse<T>(data: any): T {
	if (!data) {
		return data
	}

	return (
        Array.isArray(data) ? data.map(toCamelcaseKeys) : toCamelcaseKeys(data)
    ) as T
}

export function transformCacheCollection<T>(
	key: keyof T,
	data: T[]
): ICacheItem<T>[] {
	return data?.map<ICacheItem<T>>((item) => ({
		...item,
		id: item[key] as unknown as string,
	}))
}
