import React from 'react'

import { Space } from 'components'
import { ILegendPropd } from 'features/map/blocks/Legend/types'

import styles from './styles.module.scss'

export function Legend({ legend }: ILegendPropd) {
	if (!legend) {
		return <div>Нет данных о легенде слоя</div>
	}
	return (
		<Space direction="vertical">
			{legend.map((item, index) => {
				return (
					<Space size="small" className={styles.item} key={index}>
						{item.img ? (
							<img
								className={styles.icon}
								src={item.img}
								alt={item.title}
								style={item.style}
							/>
						) : (
							<div className={styles.icon} style={item.style} />
						)}
						{item.title}
					</Space>
				)
			})}
		</Space>
	)
}
