import { useEffect } from 'react'

import { useBooleanFlag } from 'common/hooks/boolean'
import { login } from 'common/utils/keycloak'

import { ICredentialsProviderProps } from './types'

export function CredentialsProvider(props: ICredentialsProviderProps) {
	const { children, onLogin } = props
	const [isAuthenticated, setIsAuthenticated] = useBooleanFlag(false)

	useEffect(() => {
		login().then((keycloak) => {
			setIsAuthenticated(true)
			onLogin?.(keycloak)
		})
	}, [onLogin, setIsAuthenticated])

	if (!isAuthenticated) {
		return null
	}

	return children
}
