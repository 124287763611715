import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import React, { SyntheticEvent, useCallback, useContext, useMemo } from 'react'

import { Collapse, IconButton, Space, Text } from 'components'
import {
	ILayerSwitcherListItemProps
} from 'features/map/blocks/Instruments/LayerSwitcher/types'
import { Legend } from 'features/map/blocks/Legend'
import { MapContext } from 'features/map/context'
import { useLayerStateControl } from 'features/map/store/hooks'

import styles from './styles.module.scss'

export function LayerSwitcherListItem({ layer, level = 0 }: ILayerSwitcherListItemProps) {
	const { mapId } = useContext(MapContext)
	const { onHide, state, onShow } = useLayerStateControl(mapId!, layer.id)
	const { id, title, layers } = layer

	const hideItem = useCallback((e: SyntheticEvent) => {
		e.stopPropagation()
		onHide()

	}, [onHide])
	const showItem = useCallback((e: SyntheticEvent) => {
		e.stopPropagation()
		onShow()
	}, [onShow])

	// const moreMenu = useMemo<ItemType[]>(() => (
	// 	[
	// 		{
	// 			key: '1',
	// 			label: (
	// 				<a>
	// 					Меню работы со слоем
	// 				</a>
	// 			),
	// 		}
	// 	]
	// ), [])

	const item = useMemo(() => ([
		{
			label: (
				<Text type='header4'>
					{title || id || 'Неизвестный слой'}
				</Text>
			),
			children: (
				<>
					{Number(layers?.length) > 0 && layers?.map((layer) => (
						<LayerSwitcherListItem layer={layer} key={layer.id} level={level + 1} />
					))}
					<div className={styles.legend}>
						{layer.type !== 'Group' && (
							<Legend legend={layer.legend} />
						)}
					</div>
				</>
			),
			extra: (
				id && (
					<Space
						direction='horizontal'
						size='small'
					>
						{
							state.visible ? (
								<IconButton
									isText
									isActive
									icon={<EyeOutlined />}
									onClick={hideItem}
								/>
							) : (
								<IconButton
									isText
									icon={<EyeInvisibleOutlined />}
									onClick={showItem}
								/>
							)
						}
						{/*<Dropdown menu={{ items: moreMenu }} placement="bottomLeft">*/}
						{/*	<IconButton*/}
						{/*		isText*/}
						{/*		icon={<MoreOutlined />}*/}
						{/*	/>*/}
						{/*</Dropdown>*/}
					</Space>
				)
			),
		}
	]), [title, id, layers, layer, state.visible, hideItem, showItem, level])


	return (
		<Collapse
			className={styles.item}
			ghost
			size='small'
			items={item}
		/>
	)
}
