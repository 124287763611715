import { api } from 'services'
import { cacheByIdArgProperty, providesList } from 'services/rtkQueryCacheUtils'
import { EServiceTags, IDefaultCollection } from 'services/types'
import { transformRequest, transformResponse } from 'services/utils'

import {
	ICreateNotificationParams,
	IGetNotificationParams,
	IGetNotificationsParams,
	INotification,
} from './types'

export const notificationsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getNotifications: builder.query<IDefaultCollection<INotification>, IGetNotificationsParams>({
			query: (params) => ({
				url: '/api/v1/notifications/',
				params: transformRequest(params),
			}),
			transformResponse,
			providesTags: (result: IDefaultCollection<INotification> | undefined, error) => providesList(EServiceTags.Notifications)(
				result,
				error
			),
		}),
		getNotification: builder.query<INotification, IGetNotificationParams>({
			query: ({ id }) => ({
				url: `/api/v1/notifications/${id}`,
			}),
			transformResponse,
			providesTags: (result, error, args: IGetNotificationParams) => cacheByIdArgProperty(EServiceTags.Notifications)(result, error, {
				id: args.id,
			}),
		}),
		createNotification: builder.mutation<INotification, ICreateNotificationParams>({
			query: (body) => ({
				url: '/api/v1/notifications/',
				method: 'POST',
				body: transformRequest(body),
			}),
			invalidatesTags: [EServiceTags.Notifications],
		}),
	}),
	overrideExisting: false,
})
