import classNames from 'classnames'
import React, { Fragment, useMemo } from 'react'

import { AntdButton, AntdButtonProps, Popconfirm, PopconfirmProps, Tooltip } from '../index'

import styles from './styles.module.scss'
import { IButtonProps } from './types'

export function Button(props: IButtonProps) {
	const {
		type = 'secondary', isActive, suffixIcon, children, className, confirmed, confirmTitle, confirmDescription, onClick, ...rest
	} = props

	const typeProps = useMemo<AntdButtonProps>(() => {
		switch (type) {
		case 'secondary': {
			return {
				type: 'default',
			}
		}

		case 'icon': {
			return {
				type: 'text',
			}
		}

		case 'tertiary': {
			return {
				type: 'text',
			}
		}

		default: {
			return {
				type,
			}
		}
		}
	}, [type])

	const iconOnly = useMemo(() => !children && Boolean(rest.icon), [rest.icon, children])
	const Wrapper = useMemo(() => iconOnly ? Tooltip : Fragment, [iconOnly])
	const wrapperProps = useMemo(() => iconOnly ? { title: rest.title } : undefined, [iconOnly, rest.title])

	if (confirmed && confirmTitle) {
		return (
			<Popconfirm
				title={confirmTitle}
				description={confirmDescription}
				disabled={rest.disabled}
				onConfirm={onClick as PopconfirmProps['onConfirm']}
			>
				<Wrapper {...wrapperProps}>
					<AntdButton
						{...rest}
						{...typeProps}
						className={classNames(styles.root, {
							[styles.onlyIcon]: !children && Boolean(rest.icon),
							[styles.iconButton]: type === 'icon',
							[styles.activeButton]: isActive
						}, className)}
					>
						{children}
						{Boolean(suffixIcon) && (
							<span className={classNames('ant-btn-icon', styles.suffixIcon)}>
								{suffixIcon}
							</span>
						)}
					</AntdButton>
				</Wrapper>
			</Popconfirm>
		)
	}

	return (
		<Wrapper {...wrapperProps}>
			<AntdButton
				{...rest}
				{...typeProps}
				onClick={onClick as AntdButtonProps['onClick']}
				className={classNames(styles.root, {
					[styles.onlyIcon]: !children && Boolean(rest.icon),
					[styles.iconButton]: type === 'icon',
					[styles.activeButton]: isActive
				}, className)}
			>
				{children}
				{Boolean(suffixIcon) && (
					<span className={classNames('ant-btn-icon', styles.suffixIcon)}>
						{suffixIcon}
					</span>
				)}
			</AntdButton>
		</Wrapper>
	)
}

Button.Group = AntdButton.Group
