import classNames from 'classnames'
import React, { forwardRef, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useBooleanFlag } from 'common/hooks/boolean'
import { useLazyImage } from 'common/hooks/image'
import { AntdAvatar } from 'components/index'

import styles from './styles.module.scss'
import { IAvatarProps } from './types'

export const Avatar = forwardRef<HTMLElement, IAvatarProps>(
	function AvatarComponent(props, ref) {
		const { t } = useTranslation('errors')
		const { children = t('notFound.Unknown User'), style = {}, className, forceRender, src, size: propsSize = 'BASE', ...restProps } = props
		const isStringChildren = useMemo(() => typeof children === 'string', [children])
		const { image } = useLazyImage(src)
		const [_, setIsAntdError] = useBooleanFlag(false)

		const onErrorCallback = useCallback(() => {
			setIsAntdError(true)

			return true
		}, [setIsAntdError])

		const key = useMemo(() => forceRender ? new Date().getTime() : undefined, [forceRender])
		const size = useMemo(() => {
			switch (propsSize) {
			case 'SM':
				return 32
			case 'BASE':
				return 44
			case 'L':
				return 56
			case 'XL':
				return 144
			}
		}, [propsSize])

		const child = useMemo(() => {
			if (isStringChildren) {
				const initials = ((children)  as string).split(' ')


				return `${initials?.[0]?.[0]}${initials?.[1] ? initials[1][0] : ''}`
			}

			return children
		}, [children, isStringChildren])

		return (
			<AntdAvatar
				key={key}
				ref={ref}
				{...restProps}
				src={(image as unknown) as React.ReactNode}
				style={style}
				alt={isStringChildren ? (children as string) : undefined}
				className={classNames(className, styles[propsSize])}
				onError={onErrorCallback}
				shape='square'
				size={size}
			>
				{child}
			</AntdAvatar>
		)
	}
)
