import dayjs, { Dayjs } from 'dayjs'

import { SIMPLE_DATE_FORMAT_SERVER } from 'common/constants/date'
import { getObjectEntries } from 'common/utils/object'

export const normalizeDate = (
	value?: string | Dayjs,
	format: string = SIMPLE_DATE_FORMAT_SERVER
) => {
	if (!value) {
		return undefined
	}

	let date = value

	if (typeof date === 'string') {
		date = dayjs(date)
	}

	return date.format(format)
}

export const normalizeObject = <T = { [key: string]: unknown | undefined | null }>(
	value: T,
	isRemoveNull?: boolean
) => getObjectEntries<any>(value).reduce<Partial<T>>((reducer, [key, value]) => {
	if (
		value !== undefined
		&& !(typeof value === 'string' && (value === '[]' || value === ''))
		&& !(value === null && isRemoveNull)
	) {
		// @ts-ignore
		reducer[key] = value
	}

	return reducer
}, {})

export const normalizeNumber = (value: number) => value.toLocaleString()
