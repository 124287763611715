import { i18n } from 'app/localization'
import { IBaseLayerObject } from 'features/map/types'

const { t } = i18n

export const BASE_LAYERS: IBaseLayerObject[] = [
	{
		key: 'empty',
		title: t('map:Empty-layer'),
		image: '/icons/layer.svg'
	},
	{
		key: 'osm',
		title: 'OpenStreetMap',
		image: '/icons/osm-preview.png'
	},
	{
		key: 'esri',
		title: t('map:Esri-Satellite'),
		image: '/icons/google-preview.jpeg'
	},
	{
		key: 'opentopomap',
		title: 'OpenTopoMap',
		image: '/icons/topo-preview.jpeg'
	},
]